import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import PercentIcon from "@mui/icons-material/Percent";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import {
  Container,
  TextField,
  Button,
  Paper,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Divider,
  Box,
  Checkbox,
  IconButton,
  FormControlLabel,
  InputAdornment,
  Modal,
  CardMedia,
  Card,
  CardContent,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import { db } from "./firebase";
import { getStorage, ref, deleteObject } from "firebase/storage";
import GerenciarEstoque from './Gerenciarprodutos';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  setDoc,
  getFirestore,
  updateDoc,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import { Timestamp } from "firebase/firestore";
import { ThemeProvider, StyledEngineProvider, useTheme } from "@mui/material/styles";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

import EstoqueModal from "./EstoqueModal";

const PDVVendas = () => {
  const theme = useTheme();
  const { userDetails } = useContext(AuthContext);
  const empresaId = userDetails.empresaId;
  const [codigoBarras, setCodigoBarras] = useState("");
  const [produtoSelecionado, setProdutoSelecionado] = useState(null);
  const [carrinho, setCarrinho] = useState([]);
  const [desconto, setDesconto] = useState(0);
  const [descontoPercentual, setDescontoPercentual] = useState("");
  const [descontoMonetario, setDescontoMonetario] = useState("");
  const [openComissaoModal, setOpenComissaoModal] = useState(false);
  const [comissaoManual, setComissaoManual] = useState(50);
  const [openEstoqueModal, setOpenEstoqueModal] = useState(false);

  const handleOpenEstoqueModal = () => {
    setOpenEstoqueModal(true);
  };

  const handleProdutoSelecionado = (codigo) => {
    setCodigoBarras(codigo); // Define o código diretamente no campo
    setOpenEstoqueModal(false); // Fecha o modal automaticamente
  };

  useEffect(() => {
      carregarQZTray();
  }, []);
  const carregarQZTray = async () => {
    if (!window.qz) {
        const script = document.createElement("script");
        script.src = "https://cdn.jsdelivr.net/npm/qz-tray/qz-tray.js";
        script.async = true;
        script.onload = () => console.log("✅ QZ Tray carregado com sucesso!");
        script.onerror = () => console.error("❌ Erro ao carregar o QZ Tray");
        document.body.appendChild(script);
    }
};
  // Função para fechar o modal
  const handleCloseModalEstoque = () => {
    setOpenEstoqueModal(false);
  };

  const [fornecedorSemComissao, setFornecedorSemComissao] = useState(null); 
  const timestamp = Timestamp.fromDate(new Date());
  const [tipoPagamento, setTipoPagamento] = useState({
    dinheiro: false,
    pix: false,
    cartao: {
      ativo: false,
      debito: false,
      credito: false,
      parcelas: 1,
    },
    divisao: false,
    dinheiroDivisao: "",
    cartaoDivisao: "",
  });
  const [loadingVenda, setLoadingVenda] = useState(false);
  const [informacoesEmpresa, setInformacoesEmpresa] = useState({});
  const [quantidadeSelecionada, setQuantidadeSelecionada] = useState(1);
  const [showScanner, setShowScanner] = useState(false);
  const [vendaTipo, setVendaTipo] = useState("cliente");
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const handleClose = () => setShowScanner(false);

  

  const handleError = (error) => {
    console.error(error);
    setShowScanner(false);
  };

  const buscarProdutoPorCodigoPeca = async () => {
    if (!codigoBarras) {
      console.log("Digite um código da peça válido");
      return;
    }
    try {
      const estoqueAtivoRef = collection(
        db,
        "EMPRESAS",
        empresaId,
        "Cadastro de Peças",
        "dados",
        "Estoque Ativo"
      );
      const q = query(estoqueAtivoRef, where("codigoPeca", "==", codigoBarras));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const produtoData = querySnapshot.docs[0].data();
        setProdutoSelecionado(produtoData);
        adicionarAoCarrinho(produtoData); // Adicionar automaticamente ao carrinho
      } else {
        alert("Produto não cadastrado");
        setProdutoSelecionado(null);
      }
    } catch (error) {
      console.error("Erro ao buscar produto:", error);
      setProdutoSelecionado(null);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (codigoBarras.length > 0) { // Realiza a busca para qualquer código com mais de 0 caracteres
        buscarProdutoPorCodigoPeca();
      }
    }, 2500); // 1 segundos de atraso

    return () => clearTimeout(delayDebounceFn); // Limpa o timeout anterior se o código mudar antes de 1.5 segundos
  }, [codigoBarras]);

  const calcularPrecoComDesconto = (valorVenda, descontoPercentual, descontoMonetario) => {
    let precoComDesconto = valorVenda - (valorVenda * descontoPercentual / 100) - descontoMonetario;
    return parseFloat(precoComDesconto.toFixed(2));
  };

  const adicionarAoCarrinho = (produto) => {
    if (produto && quantidadeSelecionada > 0) {
      const precoComDesconto = calcularPrecoComDesconto(produto.valorVenda, descontoPercentual, descontoMonetario);

      const produtoComDesconto = {
        ...produto,
        quantidade: quantidadeSelecionada,
        descontoPercentual: parseFloat(descontoPercentual) || 0,
        descontoMonetario: parseFloat(descontoMonetario) || 0,
        precoFinal: precoComDesconto,
      };

      setCarrinho((prevCarrinho) => [...prevCarrinho, produtoComDesconto]);
      setDescontoPercentual("");
      setDescontoMonetario("");
      setCodigoBarras("");
      setQuantidadeSelecionada(1);
    }
  };

  const handleQuantidadeChange = (index, novaQuantidade) => {
    setCarrinho((prevCarrinho) =>
      prevCarrinho.map((item, idx) => {
        if (idx === index) {
          const precoComDesconto = calcularPrecoComDesconto(item.valorVenda, item.descontoPercentual, item.descontoMonetario);

          return {
            ...item,
            quantidade: parseInt(novaQuantidade, 10) || 1,
            precoFinal: precoComDesconto,
          };
        }
        return item;
      })
    );
  };

  const handleDescontoChange = (index, novoDesconto, tipoDesconto) => {
    setCarrinho((prevCarrinho) =>
      prevCarrinho.map((item, idx) => {
        if (idx === index) {
          const descontoPercentualNum = tipoDesconto === 'percentual' ? parseFloat(novoDesconto) || 0 : item.descontoPercentual;
          const descontoMonetarioNum = tipoDesconto === 'monetario' ? parseFloat(novoDesconto) || 0 : item.descontoMonetario;

          const precoComDesconto = calcularPrecoComDesconto(item.valorVenda, descontoPercentualNum, descontoMonetarioNum);

          return {
            ...item,
            descontoPercentual: descontoPercentualNum,
            descontoMonetario: descontoMonetarioNum,
            precoFinal: precoComDesconto,
          };
        }
        return item;
      })
    );
  };

  const setModoDesconto = (index, modo) => {
    setCarrinho((prevCarrinho) =>
      prevCarrinho.map((item, idx) => {
        if (idx === index) {
          if (modo === "percentual" && item.modoDesconto !== "percentual") {
            item.descontoMonetario = 0;
          } else if (modo === "monetario" && item.modoDesconto !== "monetario") {
            item.descontoPercentual = 0;
          }
          return {
            ...item,
            modoDesconto: modo,
          };
        }
        return item;
      })
    );
  };

  const excluirDoCarrinho = (index) => {
    const novoCarrinho = carrinho.filter((_, idx) => idx !== index);
    setCarrinho(novoCarrinho);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const calcularSubtotal = () => {
    return carrinho.reduce((total, item) => {
      return total + item.precoFinal * item.quantidade;
    }, 0);
  };

  const calcularTotalDescontos = () => {
    return carrinho.reduce((total, item) => {
      const precoOriginal = item.valorVenda;
      const descontoAplicado = precoOriginal - item.precoFinal;
      return total + descontoAplicado * item.quantidade;
    }, 0);
  };

  const calcularQuantidadeTotal = () => {
    return carrinho.reduce((total, item) => total + item.quantidade, 0);
  };

  const realizarVenda = async () => {
      try {
          setLoadingVenda(true);

          if (carrinho.length === 0) {
              throw new Error("O carrinho está vazio");
          }

          if (!tipoPagamento.dinheiro &&
              !tipoPagamento.pix &&
              !tipoPagamento.cartao.ativo &&
              !tipoPagamento.divisao &&
              valorUsarCredito === 0
          ) {
              throw new Error("Selecione um método de pagamento ou utilize o crédito do fornecedor.");
          }

          const db = getFirestore();
          const produtosPorFornecedor = {};
          let produtosParaExcluir = [];
          let totalCaixaEmpresa = 0;

          for (const produto of carrinho) {
              const produtoRef = doc(
                  db,
                  `EMPRESAS/${empresaId}/Cadastro de Peças/dados/Estoque Ativo/${produto.codigoPeca}`
              );
              const docSnap = await getDoc(produtoRef);

              if (!docSnap.exists() || docSnap.data().quantidade < produto.quantidade) {
                  throw new Error(`Estoque insuficiente para o produto: ${produto.descricao}`);
              }

              const quantidadeAtualizada = docSnap.data().quantidade - produto.quantidade;
              await updateDoc(produtoRef, { quantidade: quantidadeAtualizada });

              if (quantidadeAtualizada === 0) {
                  produtosParaExcluir.push({
                      codigoPeca: produto.codigoPeca,
                      imageUrl: produto.imageUrl,
                  });
              }

              const fornecedorId = produto.fornecedor
                  ? produto.fornecedor.trim().replace(/\s+/g, "-").toLowerCase()
                  : "semFornecedor";

              let porcentagemComissaoFornecedor = 50;

              if (fornecedorId !== "semFornecedor") {
                  const fornecedorRef = doc(
                      db,
                      `EMPRESAS/${empresaId}/Cadastro de fornecedores/${fornecedorId}`
                  );
                  const fornecedorSnap = await getDoc(fornecedorRef);
                  if (fornecedorSnap.exists()) {
                      const porcentagem = fornecedorSnap.data().porcentagem;
                      if (porcentagem !== undefined) {
                          porcentagemComissaoFornecedor = porcentagem;
                      } else {
                          setFornecedorSemComissao(fornecedorId);
                          setOpenComissaoModal(true);
                          return;
                      }
                  }
              }

              const repasseFornecedor = (produto.precoFinal * produto.quantidade) * (porcentagemComissaoFornecedor / 100);
              const valorRecebidoPelaVenda = (produto.precoFinal * produto.quantidade) - repasseFornecedor;
              totalCaixaEmpresa += valorRecebidoPelaVenda;

              if (!produtosPorFornecedor[fornecedorId]) {
                  produtosPorFornecedor[fornecedorId] = [];
              }
              produtosPorFornecedor[fornecedorId].push({
                  ...produto,
                  porcentagemComissao: porcentagemComissaoFornecedor,
                  valorRecebidoPelaVenda,
                  repasseFornecedor,
              });
          }

          const hoje = new Date();
          const idVenda = `${hoje.getTime()}`;

          for (const [fornecedorId, produtos] of Object.entries(produtosPorFornecedor)) {
              let totalCaixaFornecedor = produtos.reduce((acc, produto) => acc + produto.repasseFornecedor, 0);

              if (fornecedorId !== "semFornecedor") {
                  const contaRef = collection(
                      db,
                      `EMPRESAS/${empresaId}/Cadastro de fornecedores/${fornecedorId}/Conta`
                  );
                  const vendaFornecedorRef = doc(contaRef, idVenda);

                  await setDoc(vendaFornecedorRef, {
                      dataVenda: hoje,
                      produtos,
                      totalCaixaFornecedor,
                      statusPagamento: "Aguardando Pagamento",
                      metodoPagamento: tipoPagamento,
                      idVenda,
                  });
              }
          }

        // 🔹 Ajustando valores para a venda
        const pagoPorCredito = valorUsarCredito || 0;
        const pagamentoRestante = calcularSubtotal() - pagoPorCredito;
        const fornecedorCredito = fornecedorSelecionado ? fornecedorSelecionado.nome : "";

        // 🔹 Criando estrutura de pagamento
        const pagamento = {
            pagoporcredito: pagoPorCredito,
            pagamentoRestante,
            fornecedorCredito,
            tipoPagamento
        };

        // 🔹 Criando documento de venda no Firestore
        const vendaRef = doc(
            db,
            `EMPRESAS/${empresaId}/Vendas/${hoje.getFullYear()}/${hoje.getMonth() + 1}/${hoje.getDate()}/Vendas`,
            idVenda
        );

        await setDoc(vendaRef, {
            idVenda,
            produtosPorFornecedor,
            pagamento, // 🔹 Estrutura de pagamento armazenada corretamente
            dataVenda: hoje,
            totalCaixaEmpresa,
            statusPagamento: "Aguardando Pagamento",
        });

        // 🔹 Atualizando o crédito disponível do fornecedor no Firestore
        if (fornecedorSelecionado && pagoPorCredito > 0) {
            const fornecedorRef = doc(db, `EMPRESAS/${empresaId}/Cadastro de fornecedores/${fornecedorSelecionado.id}`);

            try {
                // 🔹 Obter o crédito atual antes de atualizar
                const fornecedorSnap = await getDoc(fornecedorRef);
                if (fornecedorSnap.exists()) {
                    let creditoAtual = fornecedorSnap.data().creditoEmLoja || 0;
                    let novoCredito = creditoAtual - pagoPorCredito;

                    // 🔹 Garantir que o crédito não fique negativo
                    if (novoCredito < 0) {
                        throw new Error("O valor utilizado excede o crédito disponível do fornecedor.");
                    }

                    // 🔹 Atualizar o Firestore com o novo valor do crédito
                    await updateDoc(fornecedorRef, { creditoEmLoja: novoCredito });
                    console.log(`Crédito atualizado: De ${creditoAtual} para ${novoCredito}`);
                } else {
                    console.error("Fornecedor não encontrado no Firestore.");
                }
            } catch (error) {
                console.error("Erro ao atualizar crédito do fornecedor:", error);
            }
        }
          // 🔹 Excluir produtos do estoque, se necessário
          const storage = getStorage();
          for (const { codigoPeca, imageUrl } of produtosParaExcluir) {
              const produtoRef = doc(
                  db,
                  `EMPRESAS/${empresaId}/Cadastro de Peças/dados/Estoque Ativo/${codigoPeca}`
              );
              await deleteDoc(produtoRef);

              if (imageUrl && typeof imageUrl === 'string' && imageUrl.includes("/o/")) {
                  try {
                      const decodedUrl = decodeURIComponent(imageUrl);
                      const imagePath = decodedUrl.split("/o/")[1].split("?")[0];

                      if (imagePath) {
                          const imageRef = ref(storage, imagePath);
                          await deleteObject(imageRef);
                      } else {
                          console.error(`Caminho da imagem inválido para o produto ${codigoPeca}: ${imageUrl}`);
                      }
                  } catch (urlError) {
                      console.error(`Erro ao processar a URL da imagem para o produto ${codigoPeca}:`, urlError);
                  }
              }
          }

          imprimirCupomFiscal(idVenda);

          setCarrinho([]);
          setTipoPagamento({
              dinheiro: false,
              pix: false,
              cartao: { ativo: false, debito: false, credito: false, parcelas: 1 },
              divisao: false,
              dinheiroDivisao: 0,
              cartaoDivisao: 0,
          });
          setValorUsarCredito(0);
          setFaltaPagar(0);
          setFornecedorSelecionado(null);
          alert("Venda realizada com sucesso!");
      } catch (error) {
          console.error("Erro na venda:", error);
          alert(`Erro na venda: ${error.message}`);
      } finally {
          setLoadingVenda(false);
      }
  };




  // Função para lidar com a confirmação da comissão manual e continuar a venda
  const handleConfirmarComissao = async () => {
    try {
      if (fornecedorSemComissao) {
        const fornecedorRef = doc(
          db,
          `EMPRESAS/${empresaId}/Cadastro de fornecedores/${fornecedorSemComissao}`
        );
        // Atualiza a comissão manualmente no Firestore
        await updateDoc(fornecedorRef, {
          porcentagem: comissaoManual,
        });
        setOpenComissaoModal(false);
        realizarVenda(); // Chama a função de venda novamente para continuar
      }
    } catch (error) {
      console.error("Erro ao salvar comissão manual:", error);
    }
  };

  const buscarInformacoesEmpresa = async () => {
    try {
      const empresaRef = doc(db, "EMPRESAS", empresaId);
      const docSnap = await getDoc(empresaRef);

      if (docSnap.exists()) {
        setInformacoesEmpresa(docSnap.data());
      } else {
        console.log("Informações da empresa não encontradas!");
      }
    } catch (error) {
      console.error("Erro ao buscar informações da empresa:", error);
    }
  };

  const imprimirCupomFiscal = (idVenda) => {
    let conteudoCupom = `
      <div style="font-family: monospace; font-size: 10px; line-height: 1.2; text-align: left; width: 100%;">
        <div style="text-align: center;">
          <strong>CUPOM FISCAL - ${informacoesEmpresa.nome || empresaId}</strong><br />
          CNPJ: ${informacoesEmpresa.CNPJ || "00.000.000/0000-00"}<br />
          Endereço: ${informacoesEmpresa.endereco || "Endereço não disponível"}<br />
          Telefone: ${informacoesEmpresa.telefone || "Telefone não disponível"}<br />
          ------------------------------------------<br />
          Código da Venda: ${idVenda}<br />
          Data: ${new Date().toLocaleString()}<br />
          ------------------------------------------<br />
        </div>
        <div>
          Qtde  Código     Descrição      Total<br />
          ------------------------------------------<br />
    `;

    carrinho.forEach((item) => {
      const descricaoCurta = item.descricao.substring(0, 10).padEnd(12, " "); // Máximo 10 caracteres para descrição
      const valorTotal = formatCurrency(
        parseFloat(item.precoFinal) * item.quantidade
      );

      conteudoCupom += `
        ${(item.quantidade || 1).toString().padEnd(4, " ")}  ${
        item.codigoPeca.padEnd(8, " ")
      }  ${descricaoCurta} ${valorTotal.padStart(10, " ")}<br />
      `;
    });

    conteudoCupom += `
          ------------------------------------------<br />
          QTDE TOTAL DE ITENS: ${calcularQuantidadeTotal()}<br />
          VALOR TOTAL: ${formatCurrency(calcularSubtotal())}<br />
          VALOR TOTAL EM DESCONTOS: ${formatCurrency(calcularTotalDescontos())}<br />
          ------------------------------------------<br />
          FORMA DE PAGAMENTO:<br />
    `;

    if (tipoPagamento.dinheiro) conteudoCupom += "- Dinheiro<br />";
    if (tipoPagamento.pix) conteudoCupom += "- PIX<br />";
    if (tipoPagamento.cartao.ativo) {
      conteudoCupom += tipoPagamento.cartao.debito
        ? "- Cartão Débito<br />"
        : tipoPagamento.cartao.credito
        ? `- Cartão Crédito - Parcelas: ${tipoPagamento.cartao.parcelas}<br />`
        : "- Cartão<br />";
    }
    if (tipoPagamento.divisao) {
      conteudoCupom += `- Dinheiro: ${formatCurrency(
        tipoPagamento.dinheiroDivisao
      )}<br />`;
      conteudoCupom += `- Cartão: ${formatCurrency(
        tipoPagamento.cartaoDivisao
      )}<br />`;
    }

    conteudoCupom += `
          ------------------------------------------<br />
          <div style="text-align: center;">
            Obrigado pela preferência!
          </div>
        </div>
      </div>
    `;

    const win = window.open("", "", "width=300,height=600");
    win.document.write(`<html><body>${conteudoCupom}</body></html>`);
    win.document.close();
    win.focus();
    win.print(); // Abre o diálogo de impressão
    win.close();
  };



  const handleTipoPagamentoChange = (event) => {
    const { name, checked } = event.target;
    if (name === "dinheiro" || name === "pix") {
      setTipoPagamento({
        ...tipoPagamento,
        [name]: checked,
      });
    } else if (name === "divisao") {
      setTipoPagamento({
        ...tipoPagamento,
        divisao: checked,
      });
    } else {
      setTipoPagamento({
        ...tipoPagamento,
        cartao: {
          ...tipoPagamento.cartao,
          ativo: checked,
          debito: false,
          credito: false,
        },
      });
    }
  };

  const handleTipoCartaoChange = (name, checked) => {
    setTipoPagamento({
      ...tipoPagamento,
      cartao: {
        ...tipoPagamento.cartao,
        [name]: checked,
        ativo: name === "ativo" ? checked : tipoPagamento.cartao.ativo,
        debito: name === "debito" ? checked : tipoPagamento.cartao.debito,
        credito: name === "credito" ? checked : tipoPagamento.cartao.credito,
        parcelas:
          name === "credito" && !checked ? 1 : tipoPagamento.cartao.parcelas,
      },
    });
  };

  const handleParcelasChange = (event) => {
    setTipoPagamento({
      ...tipoPagamento,
      cartao: {
        ...tipoPagamento.cartao,
        parcelas: parseInt(event.target.value, 10),
      },
    });
  };

  const handleDivisaoChange = (name, value) => {
    setTipoPagamento({
      ...tipoPagamento,
      [name]: parseFloat(value) || 0,
    });
  };

  const handleVendaTipoChange = (event) => {
    setVendaTipo(event.target.value);
  };
// funcao para tratar credito no carrinho de compras
  // Estados para os modais
  const [openFornecedorModal, setOpenFornecedorModal] = useState(false);
  const [openCreditoModal, setOpenCreditoModal] = useState(false);
  const [fornecedorSelecionado, setFornecedorSelecionado] = useState(null);
  const [creditoDisponivel, setCreditoDisponivel] = useState(0);
  const [valorUsarCredito, setValorUsarCredito] = useState(0);
  const [faltaPagar, setFaltaPagar] = useState(0);
  const [listaFornecedores, setListaFornecedores] = useState([]);

  // Função para buscar fornecedores ao carregar a página
  useEffect(() => {
      const buscarFornecedores = async () => {
          try {
              const fornecedoresRef = collection(db, `EMPRESAS/${empresaId}/Cadastro de fornecedores`);
              const snapshot = await getDocs(fornecedoresRef);
              const fornecedores = snapshot.docs.map(doc => ({
                  id: doc.id,
                  ...doc.data(),
              }));
              setListaFornecedores(fornecedores);
          } catch (error) {
              console.error("Erro ao buscar fornecedores:", error);
          }
      };

      buscarFornecedores();
  }, [empresaId]);


  const handleAbrirModalFornecedor = async () => {
      setOpenFornecedorModal(true);
  };

  const handleSelecionarFornecedor = async (fornecedor) => {
      setFornecedorSelecionado(fornecedor);
      setOpenFornecedorModal(false);

      // Buscar o crédito disponível no Firestore
      const fornecedorRef = doc(db, `EMPRESAS/${empresaId}/Cadastro de fornecedores/${fornecedor.id}`);
      const fornecedorSnap = await getDoc(fornecedorRef);

      if (fornecedorSnap.exists()) {
          setCreditoDisponivel(fornecedorSnap.data().creditoEmLoja || 0);
      } else {
          setCreditoDisponivel(0);
      }
  };

  
  return (
    (<Box
      style={{
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
        paddingBottom: "20px",
      }}
    >
      <Container
        maxWidth="xl"
        style={{
          marginTop: "20px",
          paddingLeft: isMdUp ? "220px" : "16px",
          paddingRight: "16px",
          maxWidth: isMdUp ? "calc(110% - 240px)" : "100%", // Ajustando o espaço restante da tela ao lado do sidebar
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Paper
              sx={{
                padding: "16px",
                boxShadow: 3,
                borderRadius: "8px",
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <TextField
                label="Código de Barras"
                fullWidth
                value={codigoBarras}
                onChange={(e) => setCodigoBarras(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      
                      <div>
                        {/* Botão de abrir o modal */}
                        <IconButton onClick={handleOpenEstoqueModal} size="large">
                          <SearchIcon />
                        </IconButton>

                        {/* O modal deve ficar fora do botão */}
                        <EstoqueModal
                          open={openEstoqueModal}
                          onClose={handleCloseModalEstoque}
                          onProdutoSelecionado={handleProdutoSelecionado} // Passa a função para definir o código
                        />
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>
          </Grid>








          <Grid item xs={12} md={8}>
            <Paper
              sx={{
                padding: "16px",
                marginBottom: "16px",
                boxShadow: 3,
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: "bold", color: "#1976d2" }}
              >
                Detalhes dos Produtos
              </Typography>
              {carrinho.map((item, index) => (
                <Card
                  key={index}
                  sx={{
                    display: "flex",
                    marginBottom: "8px",
                    alignItems: "center",
                    border: "1px solid #e0e0e0",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={item.imageUrl}
                    alt={item.descricao}
                    sx={{
                      width: 80,
                      height: 80,
                      borderRadius: "4px",
                      margin: "8px",
                    }}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          {item.descricao.length > 10
                            ? item.descricao.substring(0, 10) + "..."
                            : item.descricao}
                        </Typography>
                        <Typography variant="body2">
                          Fornecedor: {item.fornecedor}
                        </Typography>
                        <Typography variant="body2">
                          Tamanho: {item.tamanho}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: "#ff5722" }}
                        >
                          Preço: {formatCurrency(item.valorVenda)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            marginRight={2}
                          >
                            <TextField
                              label="Qtde"
                              type="number"
                              value={item.quantidade}
                              onChange={(e) =>
                                handleQuantidadeChange(index, e.target.value)
                              }
                              sx={{ minWidth: "60px" }}
                            />
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            width="auto"
                          >
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                              marginRight={2}
                            >
                              <IconButton
                                color={
                                  item.modoDesconto === "percentual"
                                    ? "primary"
                                    : "default"
                                }
                                onClick={() => setModoDesconto(index, "percentual")}
                                size="large">
                                <PercentIcon
                                  sx={{
                                    color:
                                      item.modoDesconto === "percentual"
                                        ? "blue"
                                        : "grey",
                                  }}
                                />
                              </IconButton>
                              <Typography variant="body2">%</Typography>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                              marginRight={2}
                            >
                              <IconButton
                                color={
                                  item.modoDesconto === "monetario"
                                    ? "primary"
                                    : "default"
                                }
                                onClick={() => setModoDesconto(index, "monetario")}
                                size="large">
                                <AttachMoneyIcon
                                  sx={{
                                    color:
                                      item.modoDesconto === "monetario"
                                        ? "green"
                                        : "grey",
                                  }}
                                />
                              </IconButton>
                              <Typography variant="body2">R$</Typography>
                            </Box>
                            {item.modoDesconto === "percentual" && (
                              <TextField
                                label="Desconto (%)"
                                type="number"
                                value={item.descontoPercentual}
                                onChange={(e) =>
                                  handleDescontoChange(
                                    index,
                                    e.target.value,
                                    "percentual"
                                  )
                                }
                                inputProps={{ min: "0" }}
                                sx={{ minWidth: "80px", marginLeft: "5px" }}
                              />
                            )}
                            {item.modoDesconto === "monetario" && (
                              <TextField
                                label="Desconto (R$)"
                                type="number"
                                value={item.descontoMonetario}
                                onChange={(e) =>
                                  handleDescontoChange(
                                    index,
                                    e.target.value,
                                    "monetario"
                                  )
                                }
                                inputProps={{ min: "0" }}
                                sx={{ minWidth: "80px", marginLeft: "5px" }}
                              />
                            )}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{ textAlign: "right" }}
                      >
                        <Box display="flex" flexDirection="column" alignItems="flex-end">
                          <Typography
                            variant="body2"
                            sx={{ marginTop: "10px", fontWeight: "bold", color: "#388e3c" }}
                          >
                            Total:{" "}
                            {formatCurrency(
                              parseFloat(item.precoFinal) * item.quantidade
                            )}
                          </Typography>
                          <Button onClick={() => excluirDoCarrinho(index)}>
                            <DeleteIcon sx={{ color: "#d32f2f" }} />
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ))}
            </Paper>
          </Grid>

          {loadingVenda && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                position: "fixed",
                width: "100%",
                top: 0,
                left: 0,
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                zIndex: 9999,
              }}
            >
              <CircularProgress />
            </Box>
          )}

          <Grid item xs={12} md={4}>
            <Paper
              sx={{
                padding: "16px",
                overflowX: "auto",
                boxShadow: 3,
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: "bold", color: "#1976d2" }}
              >
                <ReceiptIcon /> Carrinho de Compras
              </Typography>

              <Grid
                container
                sx={{
                  fontWeight: "bold",
                  marginBottom: "15px",
                  textAlign: "left",
                  borderBottom: "1px solid #e0e0e0",
                  paddingBottom: "10px",
                }}
              >
                <Grid item xs={2}>
                  Qtde
                </Grid>
                <Grid item xs={3}>
                  Código
                </Grid>
                <Grid item xs={4}>
                  Descrição
                </Grid>
                <Grid item xs={3}>
                  Total
                </Grid>
              </Grid>

              {carrinho.map((item, index) => (
                <Grid
                  container
                  key={index}
                  sx={{
                    marginBottom: "10px",
                    textAlign: "left",
                    borderBottom: "1px solid #f0f0f0",
                    paddingBottom: "10px",
                  }}
                >
                  <Grid item xs={2}>
                    <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
                      {item.quantidade || 1}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
                      {item.codigoPeca}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
                      {item.descricao.length > 10
                        ? item.descricao.substring(0, 10) + "..."
                        : item.descricao}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
                      {formatCurrency(
                        parseFloat(item.precoFinal) * item.quantidade
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              ))}

              <Box sx={{ marginTop: "20px", marginBottom: "10px" }}>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  QTDE TOTAL DE ITENS: {calcularQuantidadeTotal()}
                </Typography>
                <Typography variant="h6" sx={{ color: "#388e3c" }}>
                  VALOR TOTAL: {formatCurrency(calcularSubtotal())}
                </Typography>
                <Typography variant="body2" sx={{ color: "#d32f2f" }}>
                  VALOR TOTAL EM DESCONTOS:{" "}
                  {formatCurrency(calcularTotalDescontos())}
                </Typography>
              </Box>

              <Box sx={{ marginTop: "10px" }}>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tipoPagamento.dinheiro}
                        onChange={handleTipoPagamentoChange}
                        name="dinheiro"
                      />
                    }
                    label="Dinheiro"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tipoPagamento.pix}
                        onChange={handleTipoPagamentoChange}
                        name="pix"
                      />
                    }
                    label="PIX"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tipoPagamento.cartao.ativo}
                        onChange={(e) => handleTipoPagamentoChange(e)}
                        name="cartao"
                      />
                    }
                    label="Cartão"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tipoPagamento.divisao}
                        onChange={(e) => handleTipoPagamentoChange(e)}
                        name="divisao"
                      />
                    }
                    label="Divisão de Pagamento"
                  />
                  {tipoPagamento.divisao && (
                    <>
                      <TextField
                        label="Dinheiro"
                        type="number"
                        value={tipoPagamento.dinheiroDivisao}
                        onChange={(e) =>
                          handleDivisaoChange("dinheiroDivisao", e.target.value)
                        }
                        sx={{ marginTop: "10px", minWidth: "100px" }}
                      />
                      <TextField
                        label="Cartão"
                        type="number"
                        value={tipoPagamento.cartaoDivisao}
                        onChange={(e) =>
                          handleDivisaoChange("cartaoDivisao", e.target.value)
                        }
                        sx={{ marginTop: "10px", minWidth: "100px" }}
                      />
                    </>
                  )}
                  {tipoPagamento.cartao.ativo && (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={tipoPagamento.cartao.debito}
                            onChange={(e) =>
                              handleTipoCartaoChange("debito", e.target.checked)
                            }
                            name="debito"
                          />
                        }
                        label="Débito"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={tipoPagamento.cartao.credito}
                            onChange={(e) =>
                              handleTipoCartaoChange("credito", e.target.checked)
                            }
                            name="credito"
                          />
                        }
                        label="Crédito"
                      />
                      {tipoPagamento.cartao.credito && (
                        <TextField
                          label="Parcelas"
                          type="number"
                          value={tipoPagamento.cartao.parcelas}
                          onChange={(e) => handleParcelasChange(e)}
                          inputProps={{ min: 1 }}
                          sx={{ marginTop: "10px", minWidth: "100px" }}
                        />
                      )}
                    </>
                  )}
                </Typography>
              </Box>
             
              {/* 🔹 Modal 1: Selecionar Fornecedor */}
              <Modal open={openFornecedorModal} onClose={() => setOpenFornecedorModal(false)}>
                  <Paper sx={{ padding: 3, width: "400px", margin: "auto", marginTop: "10%" }}>
                      <Typography variant="h6">Selecionar Fornecedor</Typography>

                      <FormControl fullWidth sx={{ mt: 2 }}>
                          <TextField
                              select
                              label="Escolha um Fornecedor"
                              value={fornecedorSelecionado ? fornecedorSelecionado.id : ""}
                              onChange={(e) => {
                                  const fornecedor = listaFornecedores.find(f => f.id === e.target.value);
                                  if (fornecedor) {
                                      handleSelecionarFornecedor(fornecedor);
                                      setOpenFornecedorModal(false); // 🔹 Fecha o primeiro modal
                                      setOpenCreditoModal(true); // 🔹 Abre automaticamente o segundo modal
                                  }
                              }}
                              SelectProps={{ native: true }}
                          >
                              <option value="" disabled>Selecione</option>
                              {listaFornecedores.map((fornecedor) => (
                                  <option key={fornecedor.id} value={fornecedor.id}>
                                      {fornecedor.nome}
                                  </option>
                              ))}
                          </TextField>
                      </FormControl>
                  </Paper>
              </Modal>

              {/* 🔹 Modal 2: Uso do Crédito do Fornecedor */}
              <Modal open={openCreditoModal} onClose={() => setOpenCreditoModal(false)}>
                  <Paper sx={{ padding: 3, width: "400px", margin: "auto", marginTop: "10%" }}>
                      <Typography variant="h6">Uso do Crédito do Fornecedor</Typography>

                      <Typography sx={{ mt: 2 }}>
                          <strong>Fornecedor:</strong> {fornecedorSelecionado?.nome}
                      </Typography>
                      <Typography>
                          <strong>Crédito Disponível:</strong> {formatCurrency(creditoDisponivel)}
                      </Typography>
                      <Typography>
                          <strong>Total da Compra:</strong> {formatCurrency(calcularSubtotal())}
                      </Typography>

                      <TextField
                          label="Valor a utilizar do crédito"
                          type="number"
                          fullWidth
                          value={valorUsarCredito}
                          onChange={(e) => {
                              const valor = parseFloat(e.target.value) || 0;
                              if (valor > creditoDisponivel) {
                                  alert("O valor não pode ser maior que o crédito disponível!");
                                  return;
                              }
                              setValorUsarCredito(valor);
                          }}
                          sx={{ mt: 2 }}
                      />

                      {valorUsarCredito < calcularSubtotal() && (
                          <Typography sx={{ mt: 2, color: "red" }}>
                              <strong>Falta Pagar:</strong> {formatCurrency(calcularSubtotal() - valorUsarCredito)}
                          </Typography>
                      )}

                      <Button
                          variant="contained"
                          color="success"
                          fullWidth
                          onClick={() => {
                              setFaltaPagar(calcularSubtotal() - valorUsarCredito);
                              setOpenCreditoModal(false);
                              realizarVenda(); // 🔹 Chama a venda após confirmação
                          }}
                          sx={{ mt: 2 }}
                      >
                          Confirmar Pagamento
                      </Button>
                  </Paper>
              </Modal>



              <FormControl component="fieldset" sx={{ marginTop: "20px" }}>
                <FormLabel component="legend">Tipo de Venda</FormLabel>
                <RadioGroup
                  aria-label="vendaTipo"
                  name="vendaTipo"
                  value={vendaTipo}
                  onChange={handleVendaTipoChange}
                >
                  <FormControlLabel
                    value="cliente"
                    control={<Radio />}
                    label="Venda Cliente"
                  />
                  <FormControlLabel
                    value="fornecedor"
                    control={<Radio />}
                    label="Venda Fornecedor"
                  />
                </RadioGroup>
              </FormControl>
            

              <Button
                variant="contained"
                color="success"
                startIcon={<ShoppingCartIcon />}
                sx={{ marginTop: "20px" }}
                fullWidth
                onClick={() => {
                    if (vendaTipo === "fornecedor") {
                        // 🔹 Se for venda por fornecedor, abre o modal para escolher o fornecedor
                        setOpenFornecedorModal(true);
                    } else {
                        // 🔹 Caso contrário, segue direto para a venda normal
                        realizarVenda();
                    }
                }}
              >
                Pagar
              </Button>

            </Paper>
          </Grid>
        </Grid>
      </Container>


    </Box>)
  );
};

export default PDVVendas;
