import React, { useState, useEffect } from "react";
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { auth } from "./firebase";
import { useParams } from "react-router-dom";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Container,
  Paper,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  CircularProgress,
  Typography
} from "@mui/material";
import { Visibility, VisibilityOff, Email, Lock } from "@mui/icons-material";
import logo from "./assets/image.png"; // Import correto da logo

const ErrorModal = ({ isOpen, onClose, message }) => (
  <Dialog open={isOpen} onClose={onClose}>
    <DialogTitle>Erro</DialogTitle>
    <DialogContent>
      <DialogContentText>{message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Fechar</Button>
    </DialogActions>
  </Dialog>
);

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState("");
  const [logoUrl, setLogoUrl] = useState(logo); // Usa a logo local por padrão
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { empresaId } = useParams();

  useEffect(() => {
    if (empresaId) {
      localStorage.setItem("lastEmpresaId", empresaId);
    }
  }, [empresaId]);

  useEffect(() => {
    const fetchLogoUrl = async () => {
      if (empresaId) {
        setLoading(true);
        try {
          const storage = getStorage();
          const logoRef = ref(storage, `${empresaId}/logo/logo.png`);
          const url = await getDownloadURL(logoRef);
          setLogoUrl(url);
        } catch (error) {
          console.error("Erro ao carregar a logo da empresa, usando logo padrão.");
        } finally {
          setLoading(false);
        }
      }
    };
    fetchLogoUrl();
  }, [empresaId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      console.log("Tentando fazer login...");
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const uid = userCredential.user.uid;
      console.log("Login bem-sucedido:", uid);

      const db = getFirestore();
      const userDocRef = doc(db, "USUARIOS", uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const empresaId = userDoc.data().empresaId;
        console.log("Empresa ID:", empresaId);
        navigate(`/dashboard`);
      } else {
        setError("Usuário não encontrado ou não associado a uma empresa.");
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Erro ao fazer login:", error.message);
      setError("E-mail ou senha incorretos.");
      setIsModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async () => {
    if (!email) {
      alert("Por favor, insira seu e-mail para redefinição de senha.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      alert("E-mail de redefinição de senha enviado.");
    } catch (error) {
      setError("Falha ao enviar e-mail de redefinição de senha.");
      setIsModalOpen(true);
    }
  };

  return (
    <Container
      component="main"
      maxWidth="false"
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#1A1A1A",
        padding: 2
      }}
    >
      <Paper
        elevation={10}
        sx={{
          padding: 4,
          borderRadius: 3,
          width: 400,
          background: "#fff",
          textAlign: "center"
        }}
      >
        <Box
          component="img"
          src={logoUrl}
          alt="Logo da Empresa"
          sx={{
            height: 150, // Ajustado para melhor exibição
            mb: 3,
            transition: "transform 0.3s ease-in-out",
            "&:hover": { transform: "scale(1.05)" }
          }}
        />
        <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
          Bem-vindo(a)
        </Typography>
        <Typography sx={{ color: "#666", mb: 3 }}>Entre para continuar</Typography>

        <Box component="form" onSubmit={handleSubmit} sx={{ width: "100%" }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email sx={{ color: "#1976d2" }} />
                </InputAdornment>
              )
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock sx={{ color: "#1976d2" }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              py: 1.5,
              fontSize: "1.1rem",
              fontWeight: 600,
              background: "#1976d2",
              "&:hover": { background: "#1565c0" }
            }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Entrar"}
          </Button>
          <Button onClick={handleResetPassword} fullWidth variant="text" sx={{ color: "#1976d2" }}>
            Esqueceu a senha?
          </Button>
        </Box>
      </Paper>

      <ErrorModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} message={error} />
    </Container>
  );
};

export default LoginPage;
