import { useContext, useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import {
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Container,
  Card,
  CardContent,
  Typography,
  Grid,
  CardMedia,
  CircularProgress,
  IconButton,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Snackbar,
  List,
  ListItem,
  ListItemText,  
  Box,
  Divider,
  Autocomplete
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { ref, uploadBytes, getStorage } from "firebase/storage";
import Papa from "papaparse";  // Importação do PapaParse
import { writeBatch } from "firebase/firestore";
import {
  getFirestore,
  query,
  where,
  collection,
  getDocs,
  getDoc,
  setDoc,
  doc
} from "firebase/firestore";
import { getDownloadURL } from "firebase/storage"; // Importação adicional
import { db } from "./firebase";
import JsBarcode from "jsbarcode";
import QRCode from "qrcode";
import MuiAlert from "@mui/material/Alert";
import Modal from "@mui/material/Modal";


const Cadastrodepecas = () => {
  const { userDetails } = useContext(AuthContext);
  const empresaId = userDetails.empresaId;
  const [descricao, setDescricao] = useState("");
  const [tamanho, setTamanho] = useState("");
  const [marca, setMarca] = useState("");
  const [categoria, setCategoria] = useState("");
  const [fornecedor, setFornecedor] = useState("");
  const [dataEntrada, setDataEntrada] = useState("");
  const [fornecedores, setFornecedores] = useState([]);
  const [valorVenda, setValorVenda] = useState("");
  const [quantidade, setQuantidade] = useState("");
  const [isFornecedorCora, setIsFornecedorCora] = useState(false);
  const [valorCompra, setValorCompra] = useState("");
  const [codigoPeca, setCodigoPeca] = useState("");
  const [image, setImage] = useState(null); // Estado para o objeto File da imagem
  const [imagePreview, setImagePreview] = useState("/static/imagem_padrao.png"); // Estado para a URL da imagem para pré-visualização
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(false);
  const [categorias, setCategorias] = useState([]); // Novo estado para categorias
  const [marcas, setMarcas] = useState([]); // Novo estado para marcas
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  
  // Carregar fornecedores, categorias e marcas do Firebase
  useEffect(() => {
    const fetchFornecedores = async () => {
      try {
        const querySnapshot = await getDocs(
          collection(db, "EMPRESAS", empresaId, "Cadastro de fornecedores")
        );
        const fornecedoresData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          nome: doc.data().nome
        }));
        setFornecedores(fornecedoresData);
      } catch (error) {
        console.error("Erro ao buscar fornecedores:", error);
      }
    };

    const fetchCategorias = async () => {
      try {
        const empresaDocRef = doc(db, "EMPRESAS", empresaId);
        const empresaDoc = await getDoc(empresaDocRef);
        if (empresaDoc.exists()) {
          setCategorias(empresaDoc.data().Categorias || []);
          setMarcas(empresaDoc.data().Marcas || []);
        } else {
          console.error("Documento da empresa não encontrado.");
        }
      } catch (error) {
        console.error("Erro ao buscar categorias:", error);
      }
    };

    if (empresaId) {
      fetchFornecedores();
      fetchCategorias();
    }
  }, [empresaId]);

  const verificarCriarSubcolecoes = async (empresaId) => {
    try {
      // Criar apenas o documento 'dados' dentro de 'Cadastro de Peças' se não existir
      const dadosRef = doc(db, "EMPRESAS", empresaId, "Cadastro de Peças", "dados");
      const dadosSnap = await getDoc(dadosRef);

      if (!dadosSnap.exists()) {
        await setDoc(dadosRef, { inicializado: true }, { merge: true });
      }

      // Não criar nenhum documento "inicial" em Estoque Ativo e Vendidos
    } catch (error) {
      console.error("Erro ao verificar/criar subcoleções:", error);
    }
  };


  // Função para atualizar o total de itens no estoque
  const atualizarTotalEstoque = async quantidadeAdicionada => {
    const quantidade = Number(quantidadeAdicionada); // Certifique-se de que é um número
    const estoqueValorRef = doc(
      db,
      "EMPRESAS",
      empresaId,
      "Estoque valor",
      "numero"
    );

    try {
      const docSnap = await getDoc(estoqueValorRef);
      let novoTotal = quantidade;

      if (docSnap.exists()) {
        const totalAtual = docSnap.data().Total || 0;
        novoTotal += totalAtual;
      }

      await setDoc(estoqueValorRef, { Total: novoTotal }, { merge: true });
      console.log("Total de estoque atualizado com sucesso: ", novoTotal);
    } catch (error) {
      console.error("Erro ao atualizar o total de estoque:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!descricao || !tamanho || !marca || !categoria || !valorVenda || !quantidade) {
        setSnackbarMessage("Por favor, preencha todos os campos obrigatórios.");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        return;
      }

      await verificarCriarSubcolecoes(empresaId);

      let imageUrl = await uploadImage(); // Upload da imagem

      const codigo = gerarCodigoPeca(); // Gera um código único para a peça

      const novaPecaData = {
        descricao,
        tamanho,
        marca,
        categoria,
        fornecedor, // 🔥 Agora armazena diretamente o nome
        dataEntrada,
        valorVenda: parseFloat(valorVenda.replace(/\./g, "").replace(",", ".")),
        quantidade: parseInt(quantidade, 10),
        valorCompra: isFornecedorCora
          ? parseFloat(valorCompra.replace(/\./g, "").replace(",", "."))
          : null,
        codigoPeca: codigo,
        imageUrl
      };

      const novaPecaRef = doc(db, "EMPRESAS", empresaId, "Cadastro de Peças", "dados", "Estoque Ativo", codigo);
      await setDoc(novaPecaRef, novaPecaData);

      await atualizarTotalEstoque(quantidade);
      imprimirEtiqueta(codigo); // Imprime a etiqueta

      setSnackbarMessage("Peça cadastrada e etiqueta impressa com sucesso.");
      setSnackbarSeverity("success");
      resetFormFields(); // Limpa os campos do formulário
    } catch (error) {
      console.error("Erro ao cadastrar peça:", error);
      setSnackbarMessage(`Erro ao cadastrar peça: ${error.message}`);
      setSnackbarSeverity("error");
    } finally {
      setLoading(false);
      setOpenSnackbar(true);
    }
  };


  const uploadImage = async () => {
    if (image && image instanceof File) {
      try {
        const storage = getStorage();
        const imageName = `${descricao}-${Date.now()}`;
        const imageRef = ref(storage, `${empresaId}/imagens/${imageName}`);

        const uploadResult = await uploadBytes(imageRef, image);
        return await getDownloadURL(uploadResult.ref);
      } catch (error) {
        console.error("Erro ao fazer upload da imagem:", error);
        return "/static/imagem_padrao.png"; // Retorna um valor padrão ou lida com o erro de forma adequada
      }
    }
    return "/static/imagem_padrao.png";
  };

  const resetFormFields = () => {
    setDescricao("");
    setTamanho("");
    setMarca("");
    setCategoria("");
    setFornecedor("");
    setDataEntrada("");
    setValorVenda("");
    setQuantidade("");
    setIsFornecedorCora(false);
    setValorCompra("");
    setImage(null);
    setImagePreview("/static/imagem_padrao.png");
  };

  const salvarImagemNoStorage = async (imagem, empresaId, descricao) => {
    const storage = getStorage();
    const imageName = descricao.replace(/\s+/g, "-").toLowerCase();
    const storageRef = ref(storage, `${empresaId}/imagens/${imageName}`);

    if (imagem instanceof File || imagem instanceof Blob) {
      await uploadBytes(storageRef, imagem);
      // Adicional: obter URL da imagem após o upload, se necessário
    }
  };

  const handleImageUpload = event => {
    const file = event.target.files[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setImage(file);
      setImagePreview(previewUrl);

      // Sugestão: libere a URL anterior se necessário
      return () => {
        URL.revokeObjectURL(previewUrl);
      };
    }
  };


  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const gerarCodigoPeca = () => {
    const data = new Date();
    const codigo = data
      .getTime()
      .toString()
      .slice(-8); // Últimos 4 dígitos do timestamp
    return codigo;
  };

  const imprimirEtiqueta = (codigo) => {
    let canvas = document.createElement("canvas");

    JsBarcode(canvas, codigo, {
      format: "CODE128",
      width: 2,
      height: 40, // Altura do código de barras
      displayValue: false, // Sem número abaixo
    });

    let codigoBarras = canvas.toDataURL("image/png");

    QRCode.toDataURL(codigo, { width: 80, height: 80 }) // QR Code maior
      .then((qrCode) => {
        let etiquetaHTML = `
          <div style="
            width: 5cm;
            height: 3cm;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            text-align: center;
            font-family: Arial, sans-serif;
            font-size: 12px;
            padding: 5px;
            border: 1px solid black;
            box-sizing: border-box;
          ">
            <p style="font-weight: bold; margin: 0; font-size: 14px;">${descricao}</p>
            <p style="margin: 2px 0; font-size: 12px;">${marca} - ${tamanho}</p>
            <p style="margin: 2px 0; font-size: 12px;">R$ ${valorVenda}</p>
            <img src="${qrCode}" alt="QR Code" style="width: 50px; height: 50px;" />
            <img src="${codigoBarras}" alt="Código de Barras" style="width: 90%; height: auto; margin-top: 5px;" />
          </div>
        `;

        let style = `
          <style>
            @page {
              size: 5cm 3cm; /* Define o tamanho exato da etiqueta */
              margin: 0;
            }
            body {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
              margin: 0;
            }
          </style>
        `;

        const janelaImpressao = window.open("", "_blank");
        janelaImpressao.document.write("<html><head>" + style + "</head><body>" + etiquetaHTML + "</body></html>");
        janelaImpressao.document.close();

        janelaImpressao.onload = () => {
          janelaImpressao.focus();
          janelaImpressao.print();
          janelaImpressao.close();
        };
      })
      .catch((error) => {
        console.error("Erro ao gerar QR Code:", error);
      });
  };


  // 🔍 Função para normalizar os nomes (remove acentos, espaços e caracteres especiais)
  const normalizarNome = (nome) => {
    return nome
      .toLowerCase()
      .normalize("NFD") // Remove acentos
      .replace(/[\u0300-\u036f]/g, "") // Remove diacríticos
      .replace(/\s+/g, "-") // Substitui espaços por traços
      .replace(/[^\w-]/g, ""); // Remove caracteres especiais
  };

  const handleCSVUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        const rows = results.data;
        setLoading(true);
        try {
          let batch = writeBatch(db);
          let batchCount = 0;
          for (const row of rows) {
            if (!row["descricao"]) continue;

            const pecaId = row["codigoPeca"] ? row["codigoPeca"].trim() : "";
            console.log("📌 Codigo da peça importado:", pecaId);


            const novaPeca = {
              codigoPeca: pecaId,
              descricao: row["descricao"].trim(),
              tamanho: row["tamanho"] || "",
              marca: row["marca"] || "",
              categoria: row["categoria"] || "",
              fornecedor: row["fornecedor"] || "",
              valorVenda: row["valorVenda"] ? parseFloat(row["valorVenda"].replace(",", ".")) : 0,
              quantidade: row["quantidade"] ? parseInt(row["quantidade"], 10) : 0,
              valorCompra: row["valorCompra"] ? parseFloat(row["valorCompra"].replace(",", ".")) : 0,
              ncm: row["NCM"] || "",
              situacaoTributaria: row["situacao_tributaria"] || "",
              pisAntecipado: row["pis_antecipado"] || "",
            };

            const pecaRef = doc(db, "EMPRESAS", empresaId, "Cadastro de Peças", "dados", "Estoque Ativo", pecaId);
            batch.set(pecaRef, novaPeca, { merge: true });
            batchCount++;
            if (batchCount >= 100) {
              await batch.commit();
              batch = writeBatch(db);
              batchCount = 0;
            }
          }
          if (batchCount > 0) {
            await batch.commit();
          }
          setSnackbarMessage("Peças importadas com sucesso!");
          setSnackbarSeverity("success");
        } catch (error) {
          console.error("Erro ao importar peças:", error);
          setSnackbarMessage("Erro ao importar peças: " + error.message);
          setSnackbarSeverity("error");
        } finally {
          setLoading(false);
          setOpenSnackbar(true);
        }
      },
      error: (error) => {
        console.error("Erro ao processar CSV:", error);
        setSnackbarMessage("Erro ao processar CSV: " + error.message);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      },
    });
  };


  const handleCurrencyChange = (event, setFunction) => {
    // Lógica para formatação de moeda
    let valor = event.target.value.replace(/\D/g, ""); // Remove tudo que não é dígito
    valor = (valor / 100).toFixed(2) + ""; // Converte para formato monetário
    valor = valor.replace(".", ",");
    valor = valor.replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
    setFunction(valor);
  };

  const handleFornecedorCoraChange = event => {
    setIsFornecedorCora(event.target.checked);
  };

return (
      <Container
        maxWidth="xl"
        style={{
          marginTop: "20px",
          paddingLeft: isMdUp ? "220px" : "16px",
          paddingRight: "16px",
          maxWidth: isMdUp ? "calc(110% - 240px)" : "100%", // Ajustando o espaço restante da tela ao lado do sidebar
        }}
      >
    <Card
      sx={{
        flex: 1,
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        padding: { xs: '10px', sm: '15px', md: '20px' },
        borderRadius: '12px',
      }}
    >
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 2 }}>
          <Typography
            variant="h5"
            component="h2"
            sx={{
              marginBottom: { xs: '10px', sm: '15px', md: '20px' },
              fontWeight: 'bold',
              fontSize: { xs: '1.2rem', sm: '1.4rem', md: '1.5rem' },
            }}
          >
            Cadastro de Peças
          </Typography>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start", gap: 2, mt: 2 }}>
            <Button variant="contained" onClick={() => setOpenModal(true)}>Informações CSV</Button>
            <Button variant="contained" component="label">
              Upload CSV
              <input type="file" hidden accept=".csv" onChange={handleCSVUpload} />
            </Button>
          </Grid>
         

        </Box>
         <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)} message={snackbarMessage} />
        <Divider sx={{ marginBottom: { xs: '10px', sm: '15px', md: '20px' } }} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <CardMedia
              component="img"
              sx={{
                maxWidth: '100%',
                height: 'auto',
                borderRadius: '8px',
                marginBottom: '10px',
                height: { xs: '150px', sm: '180px', md: '200px' },
              }}
              image={imagePreview}
              alt="Imagem da Peça"
            />
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="upload-image"
              type="file"
              onChange={handleImageUpload}
            />
            <label htmlFor="upload-image">
              <Button
                variant="contained"
                color="primary"
                component="span"
                sx={{ width: '100%', fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' } }}
                startIcon={<PhotoCamera />}
              >
                Upload
              </Button>
            </label>
          </Grid>
          <Grid item xs={12} md={8}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Descrição do Produto"
                    value={descricao}
                    onChange={(e) => setDescricao(e.target.value.toUpperCase())}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' } }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Tamanho"
                    value={tamanho}
                    onChange={(e) => setTamanho(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' } }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    freeSolo
                    options={marcas}
                    inputValue={marca}
                    onInputChange={(event, newInputValue) => {
                      setMarca(newInputValue.toUpperCase());
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Marca"
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' } }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' } }}
                  >
                    <InputLabel>Categoria</InputLabel>
                    <Select
                      value={categoria}
                      label="Categoria"
                      onChange={(e) => setCategoria(e.target.value)}
                    >
                      {categorias.map((cat, index) => (
                        <MenuItem key={index} value={cat}>
                          {cat}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Data de Entrada"
                    type="date"
                    value={dataEntrada}
                    onChange={(e) => setDataEntrada(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' } }}
                  />
                </Grid>
                
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Valor da Peça (Venda)"
                    type="text"
                    value={valorVenda}
                    onChange={(e) => handleCurrencyChange(e, setValorVenda)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">R$</InputAdornment>
                      ),
                    }}
                    sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' } }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Quantidade"
                    type="number"
                    value={quantidade}
                    onChange={(e) => setQuantidade(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' } }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isFornecedorCora}
                        onChange={(e) => {
                          setIsFornecedorCora(e.target.checked);
                          if (e.target.checked) {
                            setFornecedor('');
                          }
                        }}
                        disabled={fornecedor}
                      />
                    }
                    label="Fornecedor Próprio"
                    sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' } }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    disabled={isFornecedorCora}
                    sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' } }}
                  >
                    <InputLabel>Fornecedor</InputLabel>
                    <Select
                      value={fornecedor}
                      onChange={(e) => setFornecedor(e.target.value)}
                    >
                      {fornecedores.map((forn) => (
                        <MenuItem key={forn.id} value={forn.nome}>
                          {forn.nome}
                        </MenuItem>
                      ))}
                    </Select>

                  </FormControl>
                </Grid>
                {isFornecedorCora && (
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Valor de Compra"
                      type="text"
                      value={valorCompra}
                      onChange={(e) => handleCurrencyChange(e, setValorCompra)}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R$</InputAdornment>
                        ),
                      }}
                      sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' } }}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      marginTop: '20px',
                      padding: { xs: '8px', sm: '10px' },
                      fontWeight: 'bold',
                      borderRadius: '8px',
                      textTransform: 'none',
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                      width: '100%',
                      fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
                    }}
                    disabled={loading}
                  >
                    {loading ? <CircularProgress size={24} /> : 'Cadastrar Peça'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <Box sx={{ 
              p: 4, 
              bgcolor: "background.paper", 
              borderRadius: 2, 
              width: "450px", 
              margin: "auto", 
              mt: 5, 
              boxShadow: 3
          }}>
            <Typography variant="h6" gutterBottom>
              📌 Formato do Arquivo CSV
            </Typography>
            <Typography variant="body2" gutterBottom>
              Para evitar erros no processamento, o arquivo CSV deve conter as seguintes colunas:
            </Typography>

            <List dense>
              {["codigoPeca", "ID_Fornecedor", "descricao", "valorVenda", "valorCompra", "fornecedor", "quantidade", "tamanho", "NCM", "cpfCnpj", "categoria", "marca", "situacao_tributaria", "pis_antecipado"].map((coluna, index) => (
                <ListItem key={index} sx={{ pl: 2 }}>
                  <ListItemText primary={`✅ ${coluna}`} />
                </ListItem>
              ))}
            </List>

            <Typography variant="body2" sx={{ mt: 2, fontWeight: "bold" }}>
              📝 Exemplo de CSV correto:
            </Typography>
            <Typography variant="body2" sx={{ fontFamily: "monospace", bgcolor: "#f5f5f5", p: 1, borderRadius: 1 }}>
          
            </Typography>

            <Button 
              variant="outlined" 
              onClick={() => {
                const csvContent = "data:text/csv;charset=utf-8," +
                  "codigoPeca,ID_Fornecedor,descricao,valorVenda,valorCompra,fornecedor,quantidade,tamanho,NCM,categoria,marca,situacao_tributaria,pis_antecipado\n" +
                  "";
                const encodedUri = encodeURI(csvContent);
                const link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", "modelo_fornecedores.csv");
                document.body.appendChild(link);
                link.click();
              }} 
              sx={{ mt: 2 }}
            >
              📥 Baixar Modelo CSV
            </Button>

            <Button onClick={() => setOpenModal(false)} sx={{ mt: 2, ml: 1 }} variant="contained">
              Fechar
            </Button>
          </Box>
        </Modal>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </CardContent>
    </Card>
  </Container>
);
};
export default Cadastrodepecas;
