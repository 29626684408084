import React from 'react';
import { createContext, useState, useEffect } from "react";
import { auth } from "./firebase"; // Importe o auth do seu arquivo de configuração do Firebase
import { getFirestore, doc, getDoc } from "firebase/firestore"; // Importações adicionais do Firestore

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userDetails, setUserDetails] = useState({ empresaId: null });
  const [empresaData, setEmpresaData] = useState(null); // Adicionando estado para os dados da empresa
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
      console.log("Auth state changed. Usuário:", user);
      setCurrentUser(user);

      if (user) {
        try {
          console.log("Buscando documento do usuário no Firestore para o UID:", user.uid);
          // Buscar documento do usuário no Firestore
          const userDocRef = doc(getFirestore(), "USUARIOS", user.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const { empresaId } = userDoc.data();
            console.log("Dados do usuário encontrados:", userDoc.data());

            if (!empresaId) {
              console.error("Erro: empresaId não está definido no documento do usuário.");
              setLoading(false);
              return;
            }

            console.log("empresaId encontrado:", empresaId);
            setUserDetails({ empresaId });

            // Buscar dados da empresa com o empresaId
            console.log("Buscando documento da empresa com empresaId:", empresaId);
            const empresaDocRef = doc(getFirestore(), "EMPRESAS", empresaId);
            const empresaDoc = await getDoc(empresaDocRef);

            if (empresaDoc.exists()) {
              console.log("Dados da empresa encontrados:", empresaDoc.data());
              setEmpresaData(empresaDoc.data()); // Armazena os dados da empresa no estado
            } else {
              console.error(`Documento da empresa com o ID ${empresaId} não foi encontrado.`);
            }
          } else {
            console.error("Documento do usuário não encontrado.");
          }
        } catch (error) {
          console.error("Erro ao buscar dados do Firestore:", error);
        }
      } else {
        console.log("Nenhum usuário autenticado.");
      }

      setLoading(false);
    });

    return unsubscribe;
  }, []);

  // Monitor de inatividade
  useEffect(() => {
    const TIMEOUT = 30 * 60 * 1000; // 30 minutos
    let logoutTimer;

    const resetTimer = () => {
      clearTimeout(logoutTimer);
      if (currentUser) {
        logoutTimer = setTimeout(() => auth.signOut(), TIMEOUT);
        console.log("Timer de inatividade reiniciado. Usuário será desconectado em 30 minutos.");
      }
    };

    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    window.addEventListener("scroll", resetTimer);

    resetTimer();

    return () => {
      clearTimeout(logoutTimer);
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      window.removeEventListener("scroll", resetTimer);
    };
  }, [currentUser]);

  const value = {
    currentUser,
    userDetails,
    empresaData, // Passar os dados da empresa no contexto para serem usados nos componentes filhos
    signOut: auth.signOut,
    loginUser: async (email, password) => {
      try {
        console.log(`Tentando fazer login com o email: ${email}`);
        await auth.signInWithEmailAndPassword(email, password);
        console.log("Login bem-sucedido.");
      } catch (error) {
        console.error("Erro no login:", error);
        throw error; // Lançar para ser tratado no componente de login
      }
    },
    registerUser: async (email, password) => {
      try {
        console.log(`Tentando registrar o usuário com o email: ${email}`);
        await auth.createUserWithEmailAndPassword(email, password);
        console.log("Usuário registrado com sucesso.");
      } catch (error) {
        console.error("Erro no cadastro:", error);
        throw error; // Lançar para ser tratado no componente de cadastro
      }
    }
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
