import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import {
  collection,
  getDocs,
  setDoc,
  query,
  where,
  doc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { useTheme } from "@mui/material/styles";
import { db, storage } from "./firebase";

import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Box,
  useMediaQuery,
  Divider, // Adicionado aqui ✅
  TextField,
  Snackbar,
  Modal,
  List,
  ListItem,
  ListItemText,
  Alert
} from "@mui/material";
import { jsPDF } from "jspdf";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Papa from "papaparse";
import debounce from 'lodash/debounce';
import JSZip from "jszip"; // Certifique-se de importar JSZip
import { saveAs } from 'file-saver';

const Cadastrodefornecedor = () => {
  const theme = useTheme();
   const [searchValue, setSearchValue] = useState("");
  const { userDetails } = useContext(AuthContext);
  const empresaId = userDetails.empresaId;

  const [fornecedor, setFornecedor] = useState({
    id: "",
    nome: "",
    endereco: "", // Novo campo
    numero: "", // Novo campo
    bairro: "", // Novo campo
    uf: "", // Novo campo
    cidade: "", // Novo campo
    cep: "", // Novo campo
    contato: "",
    email: "",
    cpfCnpj: "",
    ieRg: "", // Novo campo
    porcentagem: "", // Campo já existente
  });

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [fornecedoresEncontrados, setFornecedoresEncontrados] = useState([]);
  


  // 🔹 Pesquisa otimizada (nome ou CPF/CNPJ)
  useEffect(() => {
    const fetchFornecedores = async () => {
      if (!searchValue.trim()) {
        setFornecedoresEncontrados([]);
        return;
      }

      setLoading(true);
      try {
        const fornecedoresRef = collection(db, "EMPRESAS", empresaId, "Cadastro de fornecedores");

        const nomeQuery = query(
          fornecedoresRef,
          where("nome", ">=", searchValue.toUpperCase()),
          where("nome", "<=", searchValue.toUpperCase() + "\uf8ff")
        );

        const cpfCnpjQuery = query(
          fornecedoresRef,
          where("cpfCnpj", "==", searchValue)
        );

        const [nomeSnapshot, cpfCnpjSnapshot] = await Promise.all([
          getDocs(nomeQuery),
          getDocs(cpfCnpjQuery),
        ]);

        const fornecedoresList = [];
        nomeSnapshot.forEach((doc) => fornecedoresList.push({ id: doc.id, ...doc.data() }));
        cpfCnpjSnapshot.forEach((doc) => fornecedoresList.push({ id: doc.id, ...doc.data() }));

        setFornecedoresEncontrados(fornecedoresList);
      } catch (error) {
        setError("Erro ao buscar fornecedores: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    const debouncedFetch = debounce(fetchFornecedores, 500);
    debouncedFetch();

    return () => debouncedFetch.cancel();
  }, [searchValue, empresaId]);

  // 🔹 Atualiza os campos e inicia a pesquisa
  const handleChange = (event) => {
    const { name, value } = event.target;

    setFornecedor((prev) => ({
      ...prev,
      [name]: name === "nome" ? value.toUpperCase() : value,
    }));

    if (name === "cep") {
      buscarEnderecoPorCEP(value);
    }

    if (name === "nome" || name === "cpfCnpj") {
      setSearchValue(value);
    }
  };

  // 🔹 Buscar endereço pelo CEP
  const buscarEnderecoPorCEP = async (cep) => {
    const cepNumerico = cep.replace(/\D/g, "");
    if (cepNumerico.length !== 8) return;

    try {
      const response = await fetch(`https://viacep.com.br/ws/${cepNumerico}/json/`);
      const data = await response.json();

      if (!data.erro) {
        setFornecedor((prev) => ({
          ...prev,
          endereco: data.logradouro || "",
          bairro: data.bairro || "",
          cidade: data.localidade || "",
          uf: data.uf || "",
        }));
      }
    } catch (error) {
      console.error("Erro ao buscar endereço pelo CEP:", error);
    }
  };

  const verificarDuplicidade = async (cpfCnpj, fornecedorId) => {
    if (!cpfCnpj) return true; // Se não houver CPF/CNPJ, não verifica duplicidade

    try {
      const fornecedoresRef = collection(db, "EMPRESAS", empresaId, "Cadastro de fornecedores");
      const querySnapshot = await getDocs(query(fornecedoresRef, where("cpfCnpj", "==", cpfCnpj)));

      let fornecedorDuplicado = false;

      querySnapshot.forEach((doc) => {
        console.log(`Verificando fornecedor ID: ${doc.id}, esperado ID: ${fornecedorId}`); // 🔥 Depuração

        if (doc.id !== fornecedorId) { // ✅ Agora ele ignora o próprio fornecedor ao editar
          fornecedorDuplicado = true;
        }
      });

      if (fornecedorDuplicado) {
        console.log("🔴 Fornecedor duplicado encontrado!"); // 🔥 Depuração
        setError("Já existe um fornecedor cadastrado com este CPF/CNPJ.");
        return false;
      }

      console.log("✅ Nenhum fornecedor duplicado encontrado."); // 🔥 Depuração
      return true;
    } catch (error) {
      setError("Erro ao verificar duplicidade: " + error.message);
      return false;
    }
  };





  const handleSubmit = async (event) => {
      event.preventDefault();
      setLoading(true);
      setError("");

      if (!fornecedor.nome.trim() || !fornecedor.cpfCnpj.trim()) {
          setError("Nome e CPF/CNPJ são obrigatórios.");
          setLoading(false);
          return;
      }

      let fornecedorId = fornecedor.id && isEditing ? fornecedor.id : fornecedor.nome.replace(/\s+/g, "-").toLowerCase();

      const isDuplicated = await verificarDuplicidade(fornecedor.cpfCnpj, fornecedorId);
      if (!isDuplicated) {
          setLoading(false);
          return;
      }

      const fornecedorDados = {
          ...fornecedor,
          id: fornecedorId,
      };

      const fornecedorRef = doc(db, "EMPRESAS", empresaId, "Cadastro de fornecedores", fornecedorId);

      try {
          await setDoc(fornecedorRef, fornecedorDados, { merge: true });

          console.log("📄 Gerando contrato para fornecedor:", fornecedor.nome);
          const contrato = await gerarESalvarContrato(fornecedorRef, fornecedor);

          if (contrato && contrato.pdfBlob) {
              // 🔽 Faz download automático do contrato após cadastro
              const downloadUrl = URL.createObjectURL(contrato.pdfBlob);
              const link = document.createElement("a");
              link.href = downloadUrl;
              link.setAttribute("download", `Contrato_${fornecedor.nome}.pdf`);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
          }

          setSnackbarMessage(isEditing ? "Fornecedor atualizado e contrato gerado!" : "Fornecedor cadastrado e contrato gerado!");
          setOpenSnackbar(true);
          limparFormulario();
          setIsEditing(false);
      } catch (error) {
          setError(error.message);
      } finally {
          setLoading(false);
      }
  };




  const limparFormulario = () => {
    setFornecedor({
      id: "",
      nome: "",
      endereco: "",
      numero: "",
      bairro: "",
      uf: "",
      cidade: "",
      cep: "",
      contato: "",
      email: "",
      cpfCnpj: "",
      ieRg: "",
      porcentagem: "",
    });

    setIsEditing(false);
    setSearchValue(""); // 🔥 Limpa a pesquisa
    setFornecedoresEncontrados([]); // 🔥 Oculta a lista de pesquisa
  };

  const gerarESalvarContrato = async (fornecedorRef, fornecedor) => {
      try {
          console.log("📜 Buscando modelo de contrato...");

          const contractText = await fetchContractText();
          if (!contractText || contractText === "Texto padrão do contrato não encontrado.") {
              throw new Error("Modelo de contrato não disponível.");
          }

          console.log("📝 Preenchendo contrato com dados do fornecedor...");
          let textoContrato = contractText
              .replace("{nomeFornecedor}", fornecedor.nome)
              .replace("{cpfCnpj}", fornecedor.cpfCnpj)
              .replace("{contato}", fornecedor.contato)
              .replace("{email}", fornecedor.email)
              .replace("{porcentagem}", fornecedor.porcentagem + "%")
              .replace("{porcentagemConsignataria}", (100 - parseInt(fornecedor.porcentagem, 10)) + "%");

          const docPdf = new jsPDF();
          const margin = 20;
          const pageWidth = docPdf.internal.pageSize.getWidth();
          const maxLineWidth = pageWidth - margin * 2;
          let cursorY = margin;
          const lineHeight = 10;

          docPdf.setFontSize(16);
          docPdf.setFont("helvetica", "bold");
          docPdf.text("Contrato de Consignação", margin, cursorY);
          cursorY += lineHeight * 2;

          docPdf.setFontSize(12);
          docPdf.setFont("helvetica", "normal");

          const paragrafos = textoContrato.split("\n\n");
          paragrafos.forEach((paragrafo) => {
              let linhas = docPdf.splitTextToSize(paragrafo, maxLineWidth);
              linhas.forEach((linha) => {
                  docPdf.text(linha, margin, cursorY);
                  cursorY += lineHeight;
                  if (cursorY + lineHeight > docPdf.internal.pageSize.getHeight() - margin) {
                      docPdf.addPage();
                      cursorY = margin;
                  }
              });
              cursorY += lineHeight;
          });

          const pdfBlob = docPdf.output("blob");

          console.log("📤 Enviando contrato para o Firebase Storage...");
          const pdfRef = ref(storage, `${empresaId}/contratos_fornecedores/Contrato_${fornecedor.nome}.pdf`);
          await uploadBytes(pdfRef, pdfBlob);
          const pdfURL = await getDownloadURL(pdfRef);

          console.log("✅ Contrato salvo em:", pdfURL);
          await setDoc(fornecedorRef, { contratoUrl: pdfURL }, { merge: true });

          return { nome: fornecedor.nome, pdfBlob };
      } catch (error) {
          console.error("❌ Erro ao gerar e salvar o contrato:", error);
      }
  };


  
  const handleDownloadContrato = async () => {
      if (!fornecedor.id || fornecedor.id.trim() === "") {
          setError("Nenhum fornecedor selecionado.");
          return;
      }

      try {
          setLoading(true);
          console.log("🔍 Buscando contrato para fornecedor:", fornecedor.id);

          const fornecedorRef = doc(db, "EMPRESAS", empresaId, "Cadastro de fornecedores", fornecedor.id);
          const fornecedorSnap = await getDoc(fornecedorRef);

          if (!fornecedorSnap.exists()) {
              setError("Fornecedor não encontrado no banco de dados.");
              setLoading(false);
              return;
          }

          const fornecedorData = fornecedorSnap.data();
          console.log("📂 Dados do fornecedor no Firestore:", fornecedorData);

          if (!fornecedorData || !fornecedorData.contratoUrl) {
              setError("Contrato não encontrado para este fornecedor.");
              setLoading(false);
              return;
          }

          const contratoUrl = fornecedorData.contratoUrl.trim();
          console.log("✅ URL do contrato encontrada:", contratoUrl);

          // 🔹 Abrindo o contrato em uma nova aba do navegador
          window.open(contratoUrl, "_blank");

          setSnackbarMessage("Contrato aberto em uma nova guia.");
          setOpenSnackbar(true);
      } catch (error) {
          setError(`Erro ao baixar o contrato: ${error.message}`);
          console.error("❌ Erro ao baixar contrato:", error);
      } finally {
          setLoading(false);
      }
  };






  const fetchContractText = async () => {
    try {
      const contratoRef = collection(db, "EMPRESAS", empresaId, "Contratos");
      const contratoSnapshot = await getDocs(contratoRef);
      let contractText = null;

      contratoSnapshot.forEach((doc) => {
        if (doc.exists()) {
          contractText = doc.data().contrato_fornecedor;
        }
      });

      if (!contractText) {
        throw new Error("Texto de contrato não encontrado.");
      }
      return contractText;
    } catch (error) {
      console.error("Erro ao buscar texto de contrato:", error);
      return "Texto padrão do contrato não encontrado.";
    }
  };

  const handleCSVUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    Papa.parse(file, {
      header: true,
      complete: async (results) => {
        const fornecedores = results.data;
        setLoading(true);
        try {
          for (const fornecedor of fornecedores) {
            if (fornecedor.nome) {
              const nomeFornecedor = fornecedor.nome.trim().toUpperCase(); // Converte para caixa alta
              const fornecedorId = nomeFornecedor.replace(/\s+/g, "-").toLowerCase();
              const fornecedorRef = doc(db, "EMPRESAS", empresaId, "Cadastro de fornecedores", fornecedorId);

              const novoFornecedor = {
                ...fornecedor,
                nome: nomeFornecedor, // Garante que o nome seja sempre salvo em maiúsculas
              };

              await setDoc(fornecedorRef, novoFornecedor, { merge: true });
            }
          }

          setSnackbarMessage("Fornecedores importados com sucesso!");
          setOpenSnackbar(true);
        } catch (error) {
          setError("Erro ao importar fornecedores: " + error.message);
        } finally {
          setLoading(false);
        }
      },
      error: (error) => {
        setError("Erro ao processar CSV: " + error.message);
      },
    });
  };
  


  const handleDelete = async () => {
    if (!fornecedor.id || fornecedor.id.trim() === "") {
      setError("Nenhum fornecedor selecionado para exclusão.");
      return;
    }

    try {
      setLoading(true);

      const fornecedorRef = doc(db, "EMPRESAS", empresaId, "Cadastro de fornecedores", fornecedor.id);

      const fornecedorSnap = await getDoc(fornecedorRef);
      if (!fornecedorSnap.exists()) {
        setError("Fornecedor não encontrado no banco de dados.");
        setLoading(false);
        return;
      }

      await deleteDoc(fornecedorRef);

      setSnackbarMessage("Fornecedor excluído com sucesso!");
      setOpenSnackbar(true);
      limparFormulario();
    } catch (error) {
      setError("Erro ao excluir fornecedor. Tente novamente.");
      console.error("Erro ao excluir fornecedor:", error);
    } finally {
      setLoading(false);
    }
  };



 

 

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const selecionarParaEdicao = (fornecedorSelecionado) => {
    console.log("Fornecedor selecionado para edição:", fornecedorSelecionado); // 🔍 Depuração
    setFornecedor({ ...fornecedorSelecionado });
    setIsEditing(true);
    setFornecedoresEncontrados([]); // 🔥 Oculta a lista de pesquisa após a seleção
  };



            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "100vh",
                  padding: theme.spacing(2),
                  ml: { xs: 0, md: "240px" }, // Ajuste para a sidebar
                  width: "100vw", // 🔥 Preenche toda a largura
                  maxWidth: "calc(100vw - 240px)", // 🔥 Evita ultrapassar a tela
                  overflowX: "hidden", // 🔥 Impede barra de rolagem horizontal
                }}
              >
                <Paper
                  elevation={3}
                  sx={{
                    flex: 1,
                    p: 3,
                    width: "100%", // 🔥 Ajuste para ocupar toda a largura
                    overflow: "auto",
                  }}
                >
                  <Typography component="h1" variant="h6" align="center" gutterBottom>
                    Cadastro e Consulta de Fornecedor
                  </Typography>

                  <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 2 }}>
                    {error && <Alert severity="error">{error}</Alert>}

                      <Grid container spacing={2}>
                        {/* 🔹 Campos de busca */}
                        <Grid item xs={12} sm={6}>
                          <TextField fullWidth label="Nome do Fornecedor" name="nome" value={fornecedor.nome} onChange={handleChange} size="small" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField fullWidth label="CPF/CNPJ" name="cpfCnpj" value={fornecedor.cpfCnpj} onChange={handleChange} size="small" />
                        </Grid>

                        
                        {/* 🔹 Lista de fornecedores encontrados (com limite e rolagem) */}
                        {fornecedoresEncontrados.length > 0 && !isEditing && (
                          <Grid item xs={12}>
                            <Box sx={{ maxHeight: "200px", overflowY: "auto", border: "1px solid #ccc", borderRadius: "4px", padding: "8px" }}>
                              <List>
                                {fornecedoresEncontrados.map((fornecedor) => (
                                  <ListItem button key={fornecedor.id} onClick={() => selecionarParaEdicao(fornecedor)}>
                                    <ListItemText primary={fornecedor.nome} secondary={`CPF/CNPJ: ${fornecedor.cpfCnpj}`} />
                                  </ListItem>
                                ))}
                              </List>
                            </Box>
                          </Grid>
                        )}


                      {/* Segunda linha */}
                      <Grid item xs={12} sm={6}>
                        <TextField fullWidth label="Contato (Telefone)" name="contato" value={fornecedor.contato} onChange={handleChange} size="small" />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField fullWidth label="E-mail do Fornecedor" name="email" value={fornecedor.email} onChange={handleChange} size="small" />
                      </Grid>

                      {/* Endereço */}
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">Endereço</Typography>
                        <Divider sx={{ mb: 2 }} />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextField fullWidth label="CEP" name="cep" value={fornecedor.cep} onChange={handleChange} size="small" />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField fullWidth label="Endereço" name="endereco" value={fornecedor.endereco} onChange={handleChange} size="small" />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextField fullWidth label="Bairro" name="bairro" value={fornecedor.bairro} onChange={handleChange} size="small" />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField fullWidth label="Cidade" name="cidade" value={fornecedor.cidade} onChange={handleChange} size="small" />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField fullWidth label="UF" name="uf" value={fornecedor.uf} onChange={handleChange} size="small" />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextField fullWidth label="Número" name="numero" value={fornecedor.numero} onChange={handleChange} size="small" />
                      </Grid>

                      {/* Dados Financeiros */}
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">Dados Financeiros</Typography>
                        <Divider sx={{ mb: 2 }} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField fullWidth label="IE/RG" name="ieRg" value={fornecedor.ieRg} onChange={handleChange} size="small" />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField fullWidth label="Chave Pix" name="chavePix" value={fornecedor.chavePix} onChange={handleChange} size="small" />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField fullWidth label="Agência" name="agencia" value={fornecedor.agencia} onChange={handleChange} size="small" />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField fullWidth label="Conta Corrente" name="conta" value={fornecedor.conta} onChange={handleChange} size="small" />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField fullWidth label="Porcentagem de Comissão (%)" name="porcentagem" type="number" value={fornecedor.porcentagem} onChange={handleChange} size="small" />
                      </Grid>

                      {/* Botões */}
                      <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
                        <Button variant="contained" color="primary" onClick={() => setOpenModal(true)} sx={{ mr: 1 }}>
                          Informações CSV
                        </Button>
                        <Button variant="contained" component="label">
                          Upload CSV
                          <input type="file" hidden accept=".csv" onChange={handleCSVUpload} />
                        </Button>
                      </Grid>
                      <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
                        <Button type="submit" variant="contained" color="secondary" sx={{ width: "200px" }}>
                          {loading ? <CircularProgress size={24} /> : isEditing ? "Editar" : "Cadastrar"}
                        </Button>
                      </Grid>
              
          
            {isEditing && (
              <Grid item xs={12} sm={6}>
                <Button fullWidth variant="contained" color="primary" onClick={handleDownloadContrato} disabled={loading}>
                  Baixar Contrato
                </Button>
              </Grid>
            )}

            {isEditing && (
              <Grid item xs={12}>
                <Button fullWidth variant="contained" color="error" disabled={loading} onClick={handleDelete}>
                  Excluir Fornecedor
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Paper>

      {/* 🔹 Modal de Informações CSV */}
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box sx={{ p: 4, bgcolor: "background.paper", borderRadius: 2, width: "450px", margin: "auto", mt: 5, boxShadow: 3 }}>
          <Typography variant="h6" gutterBottom>
            📌 Formato do Arquivo CSV
          </Typography>
          <Typography variant="body2" gutterBottom>
            Para evitar erros no processamento, o arquivo CSV deve conter as seguintes colunas:
          </Typography>

          <List dense>
            {["nome", "endereco", "numero", "bairro", "uf", "cidade", "cep", "contato", "email", "cpfCnpj", "ieRg", "porcentagem", "chavePix", "agencia", "conta"].map((coluna, index) => (
              <ListItem key={index}>
                <ListItemText primary={`✅ ${coluna}`} />
              </ListItem>
            ))}
          </List>

          <Button onClick={() => setOpenModal(false)} sx={{ mt: 2 }} variant="contained">
            Fechar
          </Button>
        </Box>
      </Modal>



    <Snackbar
      open={openSnackbar}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
      message={snackbarMessage}
    />
          </Box>
        );
      };

export default Cadastrodefornecedor;
