import React, { useContext, useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemText,
  Box,
  IconButton
} from "@mui/material";
import { AuthContext } from "./AuthContext";
import { db } from "./firebase";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
  addDoc
} from "firebase/firestore";
import ImpressaoEtiqueta from "./ImpressaoEtiqueta";
import HistoryIcon from "@mui/icons-material/History";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";

const Etiquetas = () => {
  const { userDetails } = useContext(AuthContext);
  const [codigoProduto, setCodigoProduto] = useState("");
  const [quantidadeImpressoes, setQuantidadeImpressoes] = useState(1);
  const [produto, setProduto] = useState(null);
  const [erro, setErro] = useState("");
  const [produtosSugeridos, setProdutosSugeridos] = useState([]);
  const [historicoEtiquetas, setHistoricoEtiquetas] = useState([]);
  const [imprimir, setImprimir] = useState(false);

  useEffect(() => {
    const buscarHistoricoEtiquetas = async () => {
      if (!userDetails?.empresaId) return;

      const historicoRef = collection(db, "EMPRESAS", userDetails.empresaId, "HistoricoEtiquetas");
      const q = query(historicoRef, orderBy("data", "desc"), limit(5));

      const querySnapshot = await getDocs(q);
      const historico = querySnapshot.docs.map(doc => doc.data());
      setHistoricoEtiquetas(historico);
    };

    buscarHistoricoEtiquetas();
  }, [userDetails.empresaId]);

  useEffect(() => {
    const buscarProdutosSugeridos = async () => {
      if (!codigoProduto) {
        setProdutosSugeridos([]);
        return;
      }

      const produtosRef = collection(
        db,
        "EMPRESAS",
        userDetails.empresaId,
        "Cadastro de Peças",
        "dados",
        "Estoque Ativo"
      );

      const qCodigo = query(
        produtosRef,
        where("codigoPeca", ">=", codigoProduto),
        where("codigoPeca", "<=", codigoProduto + "\uf8ff"),
        orderBy("codigoPeca"),
        limit(5)
      );

      const qDescricao = query(
        produtosRef,
        where("descricao", ">=", codigoProduto.toUpperCase()),
        where("descricao", "<=", codigoProduto.toUpperCase() + "\uf8ff"),
        orderBy("descricao"),
        limit(5)
      );

      const [querySnapshot1, querySnapshot2] = await Promise.all([
        getDocs(qCodigo),
        getDocs(qDescricao)
      ]);

      const produtosEncontrados1 = querySnapshot1.docs.map(doc => doc.data());
      const produtosEncontrados2 = querySnapshot2.docs.map(doc => doc.data());

      const produtosUnicos = [
        ...new Map(
          [...produtosEncontrados1, ...produtosEncontrados2].map(item => [item.codigoPeca, item])
        ).values()
      ];

      setProdutosSugeridos(produtosUnicos);
    };

    buscarProdutosSugeridos();
  }, [codigoProduto, userDetails.empresaId]);

  const handleSelecionarSugestao = sugestao => {
    setCodigoProduto(sugestao.codigoPeca);
    setProdutosSugeridos([]);
    setProduto(sugestao);
    setImprimir(false);
  };

  const buscarProdutoPorCodigo = async () => {
    if (!codigoProduto) {
      setErro("Por favor, insira um código de produto válido.");
      return;
    }

    const produtosRef = collection(
      db,
      "EMPRESAS",
      userDetails.empresaId,
      "Cadastro de Peças",
      "dados",
      "Estoque Ativo"
    );
    const q = query(produtosRef, where("codigoPeca", "==", codigoProduto));

    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const produtoEncontrado = querySnapshot.docs[0].data();
      setProduto(produtoEncontrado);
      setErro("");
      setImprimir(false);
    } else {
      setErro("Produto não encontrado.");
      setProduto(null);
    }
  };

  const registrarImpressao = async () => {
    if (!produto) return;

    const historicoRef = collection(db, "EMPRESAS", userDetails.empresaId, "HistoricoEtiquetas");
    await addDoc(historicoRef, {
      codigoPeca: produto.codigoPeca,
      descricao: produto.descricao,
      data: new Date().toISOString(),
      quantidade: quantidadeImpressoes
    });

    setHistoricoEtiquetas(prev => [
      { codigoPeca: produto.codigoPeca, descricao: produto.descricao, data: new Date().toISOString(), quantidade: quantidadeImpressoes },
      ...prev.slice(0, 4)
    ]);

    setImprimir(true);
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ padding: "20px", marginTop: "20px", marginBottom: "20px" }}>
        <Typography variant="h5" sx={{ marginBottom: "20px", textAlign: "center", fontWeight: "bold" }}>
          Impressão de Etiquetas
        </Typography>

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={8}>
            <TextField
              label="Código ou Descrição"
              variant="outlined"
              fullWidth
              value={codigoProduto}
              onChange={e => setCodigoProduto(e.target.value)}
              error={!!erro}
              helperText={erro}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={buscarProdutoPorCodigo}
              fullWidth
              startIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>

        {produtosSugeridos.length > 0 && (
          <Paper sx={{ maxHeight: 200, overflow: "auto", marginTop: "10px" }}>
            <List>
              {produtosSugeridos.map((produto, index) => (
                <ListItem key={index} button onClick={() => handleSelecionarSugestao(produto)}>
                  <ListItemText primary={`${produto.codigoPeca} - ${produto.descricao}`} />
                </ListItem>
              ))}
            </List>
          </Paper>
        )}

        {produto && (
          <Grid container spacing={2} alignItems="center" sx={{ marginTop: "20px" }}>
            <Grid item xs={6}>
              <TextField
                label="Quantidade de Impressões"
                variant="outlined"
                fullWidth
                type="number"
                value={quantidadeImpressoes}
                onChange={e => setQuantidadeImpressoes(e.target.value)}
                inputProps={{ min: 1 }}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="secondary"
                onClick={registrarImpressao}
                fullWidth
                startIcon={<PrintIcon />}
              >
                Imprimir Etiqueta
              </Button>
            </Grid>
          </Grid>
        )}

        {imprimir && produto && (
          <ImpressaoEtiqueta
            codigo={produto.codigoPeca}
            descricao={produto.descricao}
            tamanho={produto.tamanho}
            marca={produto.marca}
            categoria={produto.categoria}
            valorVenda={produto.valorVenda}
            quantidade={quantidadeImpressoes}
          />
        )}

        <Box sx={{ marginTop: "20px" }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Histórico de Impressões <HistoryIcon sx={{ verticalAlign: "middle" }} />
          </Typography>
          <List>
            {historicoEtiquetas.map((hist, index) => (
              <ListItem key={index}>
                <ListItemText primary={`${hist.codigoPeca} - ${hist.descricao}`} secondary={`Impressões: ${hist.quantidade} | ${new Date(hist.data).toLocaleString()}`} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Paper>
    </Container>
  );
};

export default Etiquetas;
