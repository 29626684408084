import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "./AuthContext";
import { db } from "./firebase";
import {
  collection,
  query,
  getDocs,
  getDoc,
  updateDoc,
  getFirestore,
  deleteDoc,
  doc,
  setDoc,
  writeBatch
} from "firebase/firestore";
import { limit, startAfter, where } from "firebase/firestore";

import {
  AppBar,
  Toolbar,
  Container,
  TextField,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
  Button,
  Grid,
  Card,
  List,
  ListItem,
  ListItemText,
  FormControl,
  Typography,
  Select,
  InputLabel,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  Divider,
  Tooltip,
  Box
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import DownloadIcon from "@mui/icons-material/GetApp";
import ApprovalIcon from "@mui/icons-material/Check";
import { green, grey, yellow, red, blue } from "@mui/material/colors";
import jsPDF from "jspdf";
import { useTheme, createTheme, ThemeProvider } from "@mui/material/styles";
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import { arrayUnion, arrayRemove } from "firebase/firestore";
import QRCode from "qrcode";
import JsBarcode from 'jsbarcode';
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { onSnapshot } from "firebase/firestore";





// Define the theme here
const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2"
    },
    secondary: {
      main: "#dc004e"
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#f0f4f7",
          color: "#000"
        }
      }
    }
  }
});

const ListaDeOrcamentos = () => { 
  const currentTheme = useTheme();
  const { userDetails } = useContext(AuthContext);
  const empresaId = userDetails.empresaId;
  const [orcamentos, setOrcamentos] = useState([]);
  const [busca, setBusca] = useState("");
  const [filtro, setFiltro] = useState("Pendente");
  const [expanded, setExpanded] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOrcamento, setSelectedOrcamento] = useState(null);
  const [editingItems, setEditingItems] = useState({});
  const [newItem, setNewItem] = useState({
    descricao: "",
    quantidade: "",
    valorVenda: ""
  });
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [deleteItemType, setDeleteItemType] = useState(null);
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [addItemModalOpen, setAddItemModalOpen] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [editItemModalOpen, setEditItemModalOpen] = useState(false);
  const [selectedPeca, setSelectedPeca] = useState(null);

  const [lastDoc, setLastDoc] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState(null);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

  const isMdUp = useMediaQuery(currentTheme.breakpoints.up("md"));
  // ✅ Função para buscar orçamentos em lotes, respeitando o filtro correto
  const fetchOrcamentos = async (status, loadMore = false) => {
    if (!empresaId || isLoading || (loadMore && !hasMore)) return;

    setIsLoading(true);

    let q = query(
      collection(db, "EMPRESAS", empresaId, "Lista de Orçamentos"),
      where("status", "==", status),
      limit(10)
    );

    // 🔥 Se for um carregamento extra, pegamos a partir do último documento carregado
    if (loadMore && lastDoc) {
      q = query(
        collection(db, "EMPRESAS", empresaId, "Lista de Orçamentos"),
        where("status", "==", status),
        startAfter(lastDoc),
        limit(10)
      );
    }

    try {
      const querySnapshot = await getDocs(q);
      const newOrcamentos = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setOrcamentos((prev) => (loadMore ? [...prev, ...newOrcamentos] : newOrcamentos));

      // 🔥 Atualiza `lastDoc` para o próximo carregamento
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1] || null);

      // 🔥 Se o número de documentos carregados for menor que o limite, significa que não há mais documentos
      setHasMore(querySnapshot.docs.length === 10);
    } catch (error) {
      console.error("Erro ao buscar orçamentos:", error);
    }

    setIsLoading(false);
  };

  // ✅ Carregar os primeiros orçamentos ao montar o componente
  useEffect(() => {
    if (empresaId) {
      fetchOrcamentos(filtro, false);
    }
  }, [empresaId, filtro]);

  // ✅ Alterar filtro e recarregar orçamentos
  const handleFiltroChange = (novoFiltro) => {
    setFiltro(novoFiltro);
    setOrcamentos([]); // 🔥 Limpa os dados antigos
    setLastDoc(null); // 🔥 Reinicia o carregamento
    setHasMore(true); // 🔥 Habilita a possibilidade de carregar mais
    fetchOrcamentos(novoFiltro, false);
  };

  const filtrarOrcamentos = () => {
    return orcamentos.filter((orcamento) => orcamento.status === filtro);
  };





  // 🔄 Carregar mais ao clicar
  const handleLoadMore = () => fetchOrcamentos(true);
  useEffect(() => {
    const fetchCategoriasEMarcas = async () => {
      const empresaDocRef = doc(db, "EMPRESAS", empresaId);
      const empresaDoc = await getDoc(empresaDocRef);
      if (empresaDoc.exists()) {
        setCategorias(empresaDoc.data().Categorias || []);
        setMarcas(empresaDoc.data().Marcas || []);
      } else {
        console.error("Documento da empresa não encontrado.");
      }
    };

    if (empresaId) {
      fetchCategoriasEMarcas();
    }
  }, [empresaId]);

    useEffect(() => {
    if (!empresaId || orcamentos.length > 0) return; // Evita refetch desnecessário


    // 🔹 Primeiro, verificamos se já temos dados no cache (localStorage)
    const cachedOrcamentos = localStorage.getItem(`orcamentos_${empresaId}`);
    if (cachedOrcamentos) {
      setOrcamentos(JSON.parse(cachedOrcamentos));
    }

    // 🔹 Criamos um listener em tempo real para manter os dados atualizados
    const q = query(collection(db, "EMPRESAS", empresaId, "Lista de Orçamentos"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const orcamentosData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // ✅ Atualiza o estado
      setOrcamentos(orcamentosData);

      // ✅ Atualiza o session 
      sessionStorage.setItem(`orcamentos_${empresaId}`, JSON.stringify(orcamentosData));

    });

    // 🔄 Retorno da função para limpar o listener ao desmontar o componente
    return () => unsubscribe();
  }, [empresaId]);

  const handleBuscaChange = (event) => {
    const valorBusca = event.target.value.toLowerCase().trim();
    setBusca(valorBusca);

    if (!valorBusca) {
      setOrcamentos(orcamentos); // 🔥 Mantém todos os orçamentos se a busca estiver vazia
      return;
    }

    const resultadosFiltrados = orcamentos.filter((orcamento) => {
      // 🔍 Converte para string para garantir que a busca funcione corretamente
      const idOrcamento = String(orcamento.id).toLowerCase();
      const nomeFornecedor = orcamento.fornecedor?.toLowerCase() || "";
      const valorOrcamento = orcamento.pecas
        ? orcamento.pecas.reduce((total, peca) => total + (parseFloat(peca.valorVenda) || 0), 0)
        : 0;

      // 🔍 Verifica se a busca corresponde a ID, fornecedor ou valor
      return (
        idOrcamento.includes(valorBusca) ||
        nomeFornecedor.includes(valorBusca) ||
        String(valorOrcamento).includes(valorBusca)
      );
    });

    setOrcamentos(resultadosFiltrados);
  };


  const handleToggle = id => {
    setExpanded(expanded === id ? null : id);
  };
  const handleOpenAddItemModal = () => {
    setAddItemModalOpen(true);
  };

  const handleCloseAddItemModal = () => {
    setAddItemModalOpen(false);
  };

  const handleOpenModal = (orcamento) => {
      if (!orcamento) {
        console.error("Erro: Nenhum orçamento selecionado.");
        return;
      }

      console.log("✅ Orçamento selecionado:", orcamento);

      setSelectedOrcamento(orcamento);
      setModalOpen(true);
  };




  const formatCurrency = value => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL"
    }).format(value);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedOrcamento(null);
    setEditingItems({});
    setNewItem({ descricao: "", quantidade: "", valorVenda: "" });
  };

  const handleStatusChange = async (id, status) => {
    const orcamentoRef = doc(
      db,
      "EMPRESAS",
      empresaId,
      "Lista de Orçamentos",
      id
    );
    await updateDoc(orcamentoRef, { status });
    setOrcamentos(prevOrcamentos =>
      prevOrcamentos.map(orcamento =>
        orcamento.id === id ? { ...orcamento, status } : orcamento
      )
    );
  };

  const confirmDeleteItem = (id, type) => {
    setDeleteItemId(id);
    setDeleteItemType(type);
    setConfirmDialogOpen(true);
  };

  const handleCancelDelete = () => {
    setConfirmDialogOpen(false);
    setDeleteItemId(null);
    setDeleteItemType(null);
  };

  const handleConfirmDelete = async () => {
    if (deleteItemType === "orcamento") {
      await deleteOrcamento(deleteItemId);
    } else if (deleteItemType === "peca") {
      await deletePeca(selectedOrcamento.id, deleteItemId);
    }
    setConfirmDialogOpen(false);
    setDeleteItemId(null);
    setDeleteItemType(null);
  };

  const deleteOrcamento = async (id) => {
      try {
          const orcamentoRef = doc(db, "EMPRESAS", empresaId, "Lista de Orçamentos", id);

          // 🔄 Atualiza o status corretamente para "Excluido" (com "E" maiúsculo)
          await updateDoc(orcamentoRef, { status: "Excluido" });

          // ✅ Atualiza o estado local para refletir a alteração
          setOrcamentos(prevOrcamentos =>
              prevOrcamentos.map(orcamento =>
                  orcamento.id === id ? { ...orcamento, status: "Excluido" } : orcamento
              )
          );

          console.log(`✅ Orçamento ${id} marcado como "Excluido"`);
      } catch (error) {
          console.error("❌ Erro ao excluir orçamento:", error);
      }
  };



  const deletePeca = async (orcamentoId, pecaId) => {
    const orcamentoRef = doc(
      db,
      "EMPRESAS",
      empresaId,
      "Lista de Orçamentos",
      orcamentoId
    );
    const orcamentoDoc = await getDoc(orcamentoRef);
    const orcamentoData = orcamentoDoc.data();
    const updatedPecas = orcamentoData.pecas.filter(peca => peca.id !== pecaId);
    await updateDoc(orcamentoRef, { pecas: updatedPecas });
    setOrcamentos(prevOrcamentos =>
      prevOrcamentos.map(orcamento =>
        orcamento.id === orcamentoId
          ? { ...orcamento, pecas: updatedPecas }
          : orcamento
      )
    );
  };

  const handleAddNewItem = async () => {
      if (!selectedOrcamento) {
          console.error("Nenhum orçamento selecionado.");
          return;
      }

      const orcamentoId = selectedOrcamento.id;
      const novoId = Date.now().toString(); // 🔹 Gera um ID único baseado no timestamp

      // 🔹 Criando um novo item formatado corretamente
      const newPeca = {
          pecaId: novoId,
          descricao: newItem.descricao.trim(),
          categoria: newItem.categoria.trim(),
          marca: newItem.marca.trim(),
          tamanho: newItem.tamanho.trim(),
          quantidade: parseInt(newItem.quantidade, 10) || 1,
          valorCompra: parseFloat(newItem.valorCompra) || 0,
          valorVenda: parseFloat(newItem.valorVenda.replace(",", ".")) || 0, // 🔥 Converte corretamente valores com vírgula
          imageUrl: newItem.imageUrl || "",
          fornecedor: selectedOrcamento.fornecedor,
          orcamentoId: selectedOrcamento.id,
          status: "Pendente",
          dataEntrada: new Date().toISOString().split("T")[0],
      };

      try {
          const orcamentoRef = doc(db, "EMPRESAS", empresaId, "Lista de Orçamentos", orcamentoId);

          // 🔹 Atualiza o Firestore sem sobrescrever outras peças
          await updateDoc(orcamentoRef, { pecas: arrayUnion(newPeca) });

          // 🔹 Aguarda a atualização para garantir que o Firestore reflita o novo estado
          const orcamentoSnap = await getDoc(orcamentoRef);
          if (!orcamentoSnap.exists()) {
              console.error("❌ Erro: Orçamento não encontrado!");
              return;
          }

          // 🔹 Obtém a versão mais recente do orçamento com a nova peça incluída
          const orcamentoAtualizado = { id: orcamentoSnap.id, ...orcamentoSnap.data() };

          // 🔹 Atualiza o estado global do orçamento para refletir a mudança
          setOrcamentos((prev) =>
              prev.map((orcamento) =>
                  orcamento.id === orcamentoId ? orcamentoAtualizado : orcamento
              )
          );

          // 🔹 Atualiza `selectedOrcamento` com a versão mais recente, garantindo que a UI reconheça o novo item
          setSelectedOrcamento(orcamentoAtualizado);

          // 🔹 Mantém o modal aberto e reseta apenas os campos do formulário
          setNewItem({ descricao: "", categoria: "", marca: "", tamanho: "", quantidade: "", valorCompra: "", valorVenda: "", imageUrl: "" });

          console.log("✅ Novo item adicionado com sucesso!");
      } catch (error) {
          console.error("❌ Erro ao adicionar novo item:", error);
          alert("Erro ao adicionar novo item: " + error.message);
      }
  };







  const handleEditItem = (orcamentoId, pecaId) => {
    if (!orcamentoId || !pecaId) {
      console.error("Erro: ID do orçamento ou ID da peça inválido.", { orcamentoId, pecaId });
      return;
    }

    console.log(`🔍 Buscando orçamento com ID: ${orcamentoId}`);
    console.log(`🔍 Buscando peça com pecaId: ${pecaId}`);

    const orcamentoSelecionado = orcamentos.find(orc => orc.id === orcamentoId);
    if (!orcamentoSelecionado) {
      console.error("❌ Erro: Orçamento não encontrado.", orcamentoId);
      return;
    }

    if (!Array.isArray(orcamentoSelecionado.pecas)) {
      console.error("❌ Erro: Lista de peças não é um array válido.", orcamentoSelecionado.pecas);
      return;
    }

    const pecaSelecionada = orcamentoSelecionado.pecas.find(p => p.pecaId === pecaId);
    if (!pecaSelecionada) {
      console.error("❌ Erro: Peça não encontrada no orçamento.", pecaId);
      return;
    }

    console.log("✅ Peça encontrada para edição:", pecaSelecionada);

    // 🔹 Define a peça selecionada para edição e abre o modal
    setSelectedOrcamento(orcamentoSelecionado);
    setSelectedPeca(pecaSelecionada);
    setEditItemModalOpen(true);
  };



  const imprimirEtiquetas = async (pecas) => {
      let etiquetasHTML = "";

      const promises = pecas.flatMap((peca) => {
          const quantidade = peca.quantidade || 1;
          return Array.from({ length: quantidade }).map(async () => {
              let canvas = document.createElement("canvas");

              JsBarcode(canvas, peca.codigoPeca, {
                  format: "CODE128",
                  width: 6,  // 🔥 Aumentei a largura das barras para facilitar a leitura
                  height: 100, // 🔥 Aumentei a altura para melhor captura pelo leitor
                  displayValue: false, // 🔥 Oculta o valor abaixo do código de barras (evita ruídos visuais)
                  margin: 5, // 🔥 Adiciona um espaçamento entre o código de barras e os textos
              });

              let codigoBarras = canvas.toDataURL("image/png");

              etiquetasHTML += `
                  <div style="
                      width: 5cm;
                      height: 7.5cm;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      font-family: Arial, sans-serif;
                      font-size: 12px;
                      padding: 0.13cm;
                      box-sizing: border-box;
                      page-break-after: always;
                  ">
                      <!-- Parte superior da etiqueta -->
                      <div style="
                          width: 100%;
                          height: 5cm;
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                          text-align: center;
                          justify-content: space-around;
                      ">
                          <p style="font-weight: bold; font-size: 12px; margin: 2px;">Descrição: ${peca.descricao}</p>
                          <p style="margin: 2px 0; font-size: 12px;">Marca: ${peca.marca}</p>
                          <p style="margin: 2px 0; font-size: 12px;">Tamanho: ${peca.tamanho}</p>

                          <img src="${codigoBarras}" alt="Código de Barras" style="width: 130px; height: 70px; margin: 5px 0;" />
                          <p style="margin: 2px 0; font-size: 14px; font-weight: bold;">Código: ${peca.codigoPeca}</p>
                      </div>

                      <!-- Linha tracejada para indicar a separação -->
                      <div style="
                          width: 100%;
                          border-top: 2px dashed black;
                          margin: 2px 0;
                      "></div>

                      <!-- Parte destacável -->
                      <div style="
                          width: 100%;
                          height: 2.5cm;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          font-size: 15px;
                          font-weight: bold;
                      ">
                          R$ ${peca.valorVenda.toFixed(2).replace(".", ",")}
                      </div>
                  </div>
              `;
          });
      });

      await Promise.all(promises);

      let style = `
          <style>
              @page {
                  size: 5cm 7.5cm;
                  margin: 0;
              }
              body {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  margin: 0;
                  padding: 0;
              }
          </style>
      `;

      // ✅ Abre a nova janela e garante que a impressão funcione corretamente
      let janelaImpressao = window.open("", "_blank");

      if (!janelaImpressao || janelaImpressao.closed || typeof janelaImpressao.document === "undefined") {
          alert("Não foi possível abrir a janela de impressão. Verifique se seu navegador bloqueou pop-ups.");
          return;
      }

      setTimeout(() => {
          if (!janelaImpressao || janelaImpressao.closed || typeof janelaImpressao.document === "undefined") {
              console.error("Erro ao acessar a janela de impressão.");
              return;
          }

          janelaImpressao.document.write("<html><head>" + style + "</head><body>" + etiquetasHTML + "</body></html>");
          janelaImpressao.document.close();

          janelaImpressao.onload = () => {
              janelaImpressao.focus();
              janelaImpressao.print();
              janelaImpressao.close();
          };
      }, 500);
  };














  const handleSaveItem = async (orcamentoId, updatedItem, pecaId) => {
      try {
          if (!empresaId) return;

          const orcamentoRef = doc(db, "EMPRESAS", empresaId, "Lista de Orçamentos", orcamentoId);
          const orcamentoDoc = await getDoc(orcamentoRef);
          if (!orcamentoDoc.exists()) return;

          const orcamentoData = orcamentoDoc.data();
          const existingPecas = orcamentoData.pecas || [];

          // 🔍 Encontrar a peça correta pelo `pecaId`
          const pecaIndex = existingPecas.findIndex(p => p.pecaId === pecaId);
          if (pecaIndex === -1) {
              console.error("❌ Peça não encontrada no orçamento!", { orcamentoId, pecaId });
              return;
          }

          // 🔄 Atualizar os campos editados da peça garantindo que `valorVenda` seja um número
          existingPecas[pecaIndex] = {
              ...existingPecas[pecaIndex],
              descricao: updatedItem.descricao,
              quantidade: parseInt(updatedItem.quantidade, 10) || 0, // 🔥 Garante que seja um número
              valorVenda: parseFloat(updatedItem.valorVenda.toString().replace(",", ".")) || 0, // 🔥 Sempre salva como número
              categoria: updatedItem.categoria,
              marca: updatedItem.marca,
              tamanho: updatedItem.tamanho
          };

          // 🔥 Atualizar o Firestore com a peça editada
          await updateDoc(orcamentoRef, { pecas: existingPecas });

          // ✅ Atualizar o estado local
          setOrcamentos(prevOrcamentos =>
              prevOrcamentos.map(orcamento =>
                  orcamento.id === orcamentoId
                      ? { ...orcamento, pecas: existingPecas }
                      : orcamento
              )
          );

          // 🔄 Limpa o estado de edição do item
          setEditingItems(prev => {
              const newItems = { ...prev };
              delete newItems[pecaId];
              return newItems;
          });

          // 🚀 Fecha o modal após salvar
          setEditItemModalOpen(false);

          console.log("✅ Peça editada e salva com sucesso!");

      } catch (error) {
          console.error("❌ Erro ao salvar item:", error);
      }
  };







  const gerarPDF = async (orcamentoDoc) => {
    return new Promise(async (resolve, reject) => {
      try {
        const jsPdfDoc = new jsPDF({ orientation: "landscape" });

        jsPdfDoc.setFontSize(18);
        jsPdfDoc.text(`${empresaId}`, 14, 20);
        jsPdfDoc.setFontSize(11);
        jsPdfDoc.text(`Orçamento: ${orcamentoDoc.numero}`, 14, 25);
        jsPdfDoc.text(`Fornecedor: ${orcamentoDoc.fornecedor}`, 14, 30);

        const agora = new Date();
        const dataHora = agora.toLocaleString("pt-BR");
        jsPdfDoc.text(`Data/Hora: ${dataHora}`, 14, 34);

        const colunas = [
          { header: "Item", dataKey: "item" },
          { header: "ID Peça", dataKey: "pecaId" },
          { header: "Descrição", dataKey: "descricao" },
          { header: "Qtd", dataKey: "quantidade" },
          { header: "Tam", dataKey: "tamanho" },
          { header: "Marca", dataKey: "marca" },
          { header: "Fornecedor", dataKey: "fornecedor" },
          { header: "Entrada", dataKey: "dataEntrada" },
          { header: "Valor Unitário", dataKey: "valorUnitario" },
          { header: "Valor Total", dataKey: "valorTotal" },
          { header: "Comissão", dataKey: "valorComissao" }
        ];

        const linhas = [];
        let totalComissao = 0;

        for (const [index, peca] of orcamentoDoc.pecas.entries()) {
          const porcentagemComissao = await buscarPorcentagemFornecedor(peca.fornecedor);

          const valorUnitario = parseFloat(peca.valorVenda) || 0;
          const quantidade = parseInt(peca.quantidade) || 1;
          const valorTotal = valorUnitario * quantidade;
          const valorComissao = (valorTotal * porcentagemComissao) / 100;

          totalComissao += valorComissao;

          linhas.push({
            item: index + 1,
            pecaId: peca.pecaId || "N/A",
            descricao: peca.descricao,
            quantidade: quantidade,
            tamanho: peca.tamanho,
            marca: peca.marca,
            fornecedor: peca.fornecedor,
            dataEntrada: peca.dataEntrada,
            valorUnitario: valorUnitario.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
            valorTotal: valorTotal.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
            valorComissao: valorComissao.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
          });
        }

        jsPdfDoc.autoTable({
          head: [colunas.map(col => col.header)],
          body: linhas.map(row => colunas.map(col => row[col.dataKey])),
          startY: 40,
          margin: { horizontal: 10 },
          styles: { fontSize: 9, cellPadding: 3 },
          headStyles: { fillColor: [40, 40, 40], textColor: [255, 255, 255] },
          columnStyles: {
            0: { cellWidth: 10 },
            1: { cellWidth: 18 },
            2: { cellWidth: 45 },
            3: { cellWidth: 15 },
            4: { cellWidth: 15 },
            6: { cellWidth: 18 },
            7: { cellWidth: 40 },
            8: { cellWidth: 30 },
            9: { cellWidth: 30 },
            10: { cellWidth: 30 }
          },
          didDrawPage: function (data) {
            const pageHeight = jsPdfDoc.internal.pageSize.height;
            jsPdfDoc.setFontSize(11);
            jsPdfDoc.text(
              `Página ${jsPdfDoc.internal.getNumberOfPages()}`,
              data.settings.margin.left,
              pageHeight - 10
            );
          }
        });

        const lastY = jsPdfDoc.lastAutoTable.finalY + 10;
        const pageHeight = jsPdfDoc.internal.pageSize.height;

        if (lastY > pageHeight - 30) {
          jsPdfDoc.addPage();
        }

        jsPdfDoc.setFontSize(12);
        jsPdfDoc.text(
          `Total Previsto para o Fornecedor: ${totalComissao.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
          14,
          lastY
        );

        const pdfBlob = jsPdfDoc.output("blob");
        resolve(pdfBlob);
      } catch (error) {
        console.error("❌ Erro ao gerar PDF:", error);
        reject(error);
      }
    });
  };





  // ✅ Converte PDF para Base64 para enviar por e-mail
  const pdfBlobToBase64 = async (orcamento) => {
    const pdfBlob = await gerarPDF(orcamento);

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(pdfBlob);
    });
  };

  // ✅ Enviar PDF por e-mail (mantendo a chamada existente)
  const enviarEmailComPDF = async (orcamento) => {
    setAlertMessage("Enviando email para o fornecedor...");
    setAlertModalOpen(true);

    const fornecedorRef = doc(getFirestore(), "EMPRESAS", empresaId, "Cadastro de fornecedores", orcamento.fornecedor);
    const fornecedorDoc = await getDoc(fornecedorRef);

    if (!fornecedorDoc.exists()) {
      alert("Erro: Fornecedor não encontrado.");
      return;
    }

    const fornecedorData = fornecedorDoc.data();
    const fornecedorEmail = fornecedorData.email;

    if (!fornecedorEmail) {
      alert("Erro: Fornecedor não possui email cadastrado.");
      return;
    }

    const attachmentBase64 = await pdfBlobToBase64(orcamento);
    const mailData = {
      to: fornecedorEmail,
      subject: `Orçamento ${empresaId} para o fornecedor ${orcamento.fornecedor}`,
      text: "Segue em anexo o orçamento solicitado.",
      attachment: attachmentBase64,
      filename: `Orcamento_${empresaId}_${orcamento.fornecedor}.pdf`
    };

    try {
      const response = await fetch(
        "https://us-central1-dona-cora.cloudfunctions.net/sendEmailWithPDF",
        {
          method: "POST",
          body: JSON.stringify(mailData),
          headers: {
            "Content-Type": "application/json"
          }
        }
      );

      if (!response.ok) {
        const responseText = await response.text();
        throw new Error(responseText);
      }

      setAlertMessage("Email enviado com sucesso.");
      setTimeout(() => {
        setAlertModalOpen(false);
      }, 2000);
    } catch (error) {
      console.error("Erro ao enviar email:", error);
      alert("Erro ao enviar email: " + error.message);
      setAlertModalOpen(false);
    }
  };

  const handleDownloadPDF = async (orcamento) => {
    try {
      setIsGeneratingPDF(true); // 🔥 Inicia o estado de carregamento

      const pdfBlob = await gerarPDF(orcamento);
      const url = URL.createObjectURL(pdfBlob);

      const a = document.createElement("a");
      a.href = url;
      a.download = `Orcamento_${orcamento.id}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Erro ao gerar PDF:", error);
      alert("Erro ao gerar PDF. Tente novamente.");
    } finally {
      setIsGeneratingPDF(false); // 🔥 Finaliza o estado de carregamento
    }
  };

  const buscarPorcentagemFornecedor = async (fornecedorId) => {
    if (!fornecedorId || fornecedorId === "Fornecedor Próprio") return 40; // Padrão 40% se for próprio

    try {
      const fornecedorRef = doc(getFirestore(), "EMPRESAS", empresaId, "Cadastro de fornecedores", fornecedorId);
      const fornecedorSnap = await getDoc(fornecedorRef);

      if (fornecedorSnap.exists()) {
        const fornecedorData = fornecedorSnap.data();
        return fornecedorData.porcentagem ?? 40; // Se não houver campo porcentagem, assume 40%
      } else {
        console.warn(`Fornecedor ${fornecedorId} não encontrado!`);
        return 40;
      }
    } catch (error) {
      console.error("Erro ao buscar porcentagem do fornecedor:", error);
      return 40;
    }
  };




            const handleApprove = async (orcamento) => {
              if (!orcamento || !orcamento.pecas || orcamento.pecas.length === 0) {
                  console.error("Erro: Orçamento inválido ou sem peças!", orcamento);
                  alert("Erro: Este orçamento está inválido ou não contém peças.");
                  return;
              }

              if (orcamento.status === "Aprovado") {
                  alert("Este orçamento já está aprovado e não pode ser aprovado novamente.");
                  return;
              }

              setAlertMessage("Aprovando orçamento e salvando peças...");
              setAlertModalOpen(true);

              const estoqueCollectionRef = collection(
                  db,
                  "EMPRESAS",
                  empresaId,
                  "Cadastro de Peças",
                  "dados",
                  "Estoque Ativo"
              );

              const orcamentoRef = doc(db, "EMPRESAS", empresaId, "Lista de Orçamentos", orcamento.id);

              try {
                  let nomeFornecedor = orcamento.fornecedor;
                  if (orcamento.fornecedor !== "Fornecedor Próprio") {
                      const fornecedorRef = doc(db, "EMPRESAS", empresaId, "Cadastro de fornecedores", orcamento.fornecedor);
                      const fornecedorSnap = await getDoc(fornecedorRef);

                      if (fornecedorSnap.exists()) {
                          nomeFornecedor = fornecedorSnap.data().nome;
                      } else {
                          console.warn(`Fornecedor com ID ${orcamento.fornecedor} não encontrado.`);
                          nomeFornecedor = "Fornecedor Desconhecido";
                      }
                  }

                  // 🔹 Função para salvar cada peça garantindo que todas sejam registradas
                  const savePiece = async (peca) => {
                      const codigoPeca = Date.now().toString().slice(-8);
                      const pecaRef = doc(estoqueCollectionRef, codigoPeca);

              const pecaData = {
                  categoria: peca.categoria || "Desconhecido",
                  codigoPeca: codigoPeca,
                  dataEntrada: new Date().toISOString().split("T")[0],
                  descricao: peca.descricao,
                  fornecedor: nomeFornecedor,
                  imageUrl: peca.imageUrl || "/static/imagem_padrao.png",
                  marca: peca.marca || "Desconhecido",
                  quantidade: peca.quantidade,
                  tamanho: peca.tamanho || "Único",
                  valorCompra: peca.valorCompra,
                  valorVenda: peca.valorVenda,
                  orcamentoId: orcamento.id
              };

  let tentativas = 3;
              for (let i = 0; i < tentativas; i++) {
                  try {
                      await setDoc(pecaRef, pecaData);
                      peca.codigoPeca = codigoPeca; // ✅ Agora o objeto `peca` tem o código correto
                      return;
                  } catch (error) {
                      console.warn(`Tentativa ${i + 1} falhou ao salvar peça ${peca.descricao}`, error);
                      await new Promise(resolve => setTimeout(resolve, 500));
                  }
              }
              throw new Error(`Falha ao salvar a peça ${peca.descricao} após ${tentativas} tentativas.`);
          };

          // 🔹 Processar as peças uma por uma para garantir que todas sejam salvas
          for (const peca of orcamento.pecas) {
              await savePiece(peca);
          }

          // 🔹 Atualiza o status do orçamento após salvar todas as peças
          await updateDoc(orcamentoRef, { status: "Aprovado" });

          setOrcamentos((prevOrcamentos) =>
              prevOrcamentos.map((o) => (o.id === orcamento.id ? { ...o, status: "Aprovado" } : o))
          );

          setAlertMessage("Orçamento aprovado e etiquetas sendo impressas...");

          // ✅ Agora todas as peças têm um código válido, então podemos imprimir as etiquetas
          imprimirEtiquetas(orcamento.pecas);

          setTimeout(() => {
              setAlertModalOpen(false);
          }, 2000);
      } catch (error) {
          console.error("Erro ao aprovar orçamento:", error);
          alert("Erro ao aprovar orçamento: " + error.message);
          setAlertModalOpen(false);
      }
  };




  const getStatusIcon = (status) => {
      switch (status) {
          case "aprovado":
              return <CheckCircleOutlineIcon style={{ color: green[500] }} />;
          case "pendente":
              return <HourglassEmptyIcon style={{ color: yellow[700] }} />;
          case "excluido":
              return <CloseIcon style={{ color: red[500] }} />;
          default:
              return <HourglassEmptyIcon style={{ color: grey[700] }} />;
      }
  };


  const renderOrcamentoActions = (orcamento) => {
    const isApproved = orcamento.status === "Aprovado";

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1.5, // 🔥 Aumenta o espaçamento entre os botões
          backgroundColor: "#f8f9fa",
          padding: "8px 12px",
          borderRadius: "12px",
          boxShadow: "0px 0px 7px #195ad2",
 // 🔥 Adiciona leve sombra
          transition: "all 0.2s ease-in-out",
          "&:hover": { backgroundColor: "#e9ecef" }, // 🔥 Suave efeito de hover
        }}
      >
        <Tooltip title="Expandir" arrow>
          <IconButton onClick={() => handleToggle(orcamento.id)} sx={{ color: "#6c757d" }}>
            {expanded === orcamento.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Tooltip>

        {orcamento.status === "Excluido" ? (
          // 🔥 Se estiver excluído, mostra apenas o botão de recuperação
          <Tooltip title="Recuperar Orçamento" arrow>
            <IconButton
              onClick={() => handleRecoverOrcamento(orcamento.id)}
              sx={{
                color: green[500],
                "&:hover": { backgroundColor: "rgba(76, 175, 80, 0.1)" }, // 🔥 Efeito hover
              }}
            >
              <CheckCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        ) : (
          // 🔥 Se não estiver excluído, mostra as ações normais
          <>
            <Tooltip title="Editar" arrow>
              <IconButton
                onClick={() => handleOpenModal(orcamento)}
                sx={{
                  color: blue[500],
                  "&:hover": { backgroundColor: "rgba(33, 150, 243, 0.1)" },
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Excluir" arrow>
              <IconButton
                onClick={() => confirmDeleteItem(orcamento.id, "orcamento")}
                sx={{
                  color: red[500],
                  "&:hover": { backgroundColor: "rgba(244, 67, 54, 0.1)" },
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Enviar Email" arrow>
              <IconButton
                onClick={() => enviarEmailComPDF(orcamento)}
                sx={{
                  color: blue[400],
                  "&:hover": { backgroundColor: "rgba(30, 136, 229, 0.1)" },
                }}
              >
                <SendIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Baixar PDF" arrow>
              <span>
                <IconButton
                  onClick={() => handleDownloadPDF(orcamento)}
                  sx={{
                    color: grey[700],
                    "&:hover": { backgroundColor: "rgba(158, 158, 158, 0.1)" },
                  }}
                  disabled={isGeneratingPDF} // 🔥 Desabilita o botão enquanto gera o PDF
                >
                  {isGeneratingPDF ? <HourglassEmptyIcon /> : <DownloadIcon />} {/* 🔄 Ícone de carregamento */}
                </IconButton>
              </span>
            </Tooltip>
      
            <Dialog open={isGeneratingPDF} fullWidth maxWidth="xs">
              <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
                <HourglassEmptyIcon sx={{ fontSize: 30, color: "#ff9800", marginBottom: 1 }} />
                Gerando PDF...
              </DialogTitle>

              <DialogContent dividers sx={{ textAlign: "center", padding: "16px 24px" }}>
                <Typography variant="body1">Aguarde, estamos gerando o orçamento em PDF.</Typography>
              </DialogContent>

              <DialogActions sx={{ justifyContent: "center", padding: "8px 24px" }}>
                <Button color="primary" variant="contained" size="small" disabled>
                  Processando...
                </Button>
              </DialogActions>
            </Dialog>
            <Tooltip title="Aprovar" arrow>
              <span>
                <IconButton
                  onClick={() => handleApprove(orcamento)}
                  disabled={isApproved}
                  sx={{
                    color: isApproved ? grey[500] : green[500],
                    "&:hover": {
                      backgroundColor: isApproved ? "transparent" : "rgba(76, 175, 80, 0.1)",
                    },
                  }}
                >
                  <ApprovalIcon />
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}
      </Box>
    );
  };


  const handleRecoverOrcamento = async (id) => {
    try {
      const orcamentoRef = doc(db, "EMPRESAS", empresaId, "Lista de Orçamentos", id);

      // Atualizar o status no Firestore para "Pendente"
      await updateDoc(orcamentoRef, { status: "Pendente" });

      // Atualizar o estado local para refletir a mudança
      setOrcamentos((prevOrcamentos) =>
        prevOrcamentos.map((orcamento) =>
          orcamento.id === id ? { ...orcamento, status: "Pendente" } : orcamento
        )
      );

      console.log(`✅ Orçamento ${id} recuperado com sucesso!`);
    } catch (error) {
      console.error("❌ Erro ao recuperar o orçamento:", error);
    }
  };
  // 📌 Abre o modal de confirmação antes de excluir
  const handleDeleteItemConfirm = (orcamentoId, pecaId) => {
    setDeleteTarget({ orcamentoId, pecaId });
    setConfirmDeleteOpen(true);
  };
  // 📌 Função para excluir a peça do orçamento no Firestore
  const handleDeleteItem = async () => {
    if (!deleteTarget) return;

    try {
      const { orcamentoId, pecaId } = deleteTarget;
      const orcamentoRef = doc(db, "EMPRESAS", empresaId, "Lista de Orçamentos", orcamentoId);
      const orcamentoDoc = await getDoc(orcamentoRef);

      if (!orcamentoDoc.exists()) return;

      // 🔄 Remove a peça do array
      const orcamentoData = orcamentoDoc.data();
      const updatedPecas = orcamentoData.pecas.filter(peca => peca.pecaId !== pecaId);

      await updateDoc(orcamentoRef, { pecas: updatedPecas });

      // Atualiza o estado local
      setOrcamentos((prev) =>
        prev.map((orcamento) =>
          orcamento.id === orcamentoId ? { ...orcamento, pecas: updatedPecas } : orcamento
        )
      );

      console.log("✅ Peça removida com sucesso!");
    } catch (error) {
      console.error("❌ Erro ao excluir a peça:", error);
    }

    setConfirmDeleteOpen(false);
  };
            return (
                <Container
                  maxWidth="xl"
                  style={{
                    marginTop: "20px",
                    paddingLeft: isMdUp ? "230px" : "16px",
                    paddingRight: "16px",
                    maxWidth: isMdUp ? "calc(110% - 240px)" : "100%", // Ajustando o espaço restante da tela ao lado do sidebar
                  }}
                >
                <ThemeProvider theme={theme}>
                  {/* Barra de navegação */}
                  <AppBar position="static" sx={{ borderRadius: 2, boxShadow: 3, padding: 1 }}>
                    <Toolbar sx={{ flexWrap: "wrap", justifyContent: "space-between", gap: 2 }}>
                      <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        Lista de Orçamentos por Aprovação
                      </Typography>

                      {/* Área de busca e filtros */}
                      <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
                        {/* Campo de busca */}
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            fullWidth
                            label="Buscar Orçamento"
                            variant="outlined"
                            value={busca}
                            onChange={handleBuscaChange}
                            size="small"
                          />
                        </Grid>

                        {/* Botões de filtro */}
                        <Grid item xs={12} sm={6} md={9} display="flex" justifyContent="flex-end" gap={1}>
                          {["Pendente", "Aprovado", "Excluido"].map((status) => (
                            <Button
                              key={status}
                              variant={filtro === status ? "contained" : "outlined"}
                              color={status === "Pendente" ? "primary" : status === "Aprovado" ? "success" : "error"}
                              onClick={() => handleFiltroChange(status)}
                              sx={{
                                textTransform: "none",
                                fontSize: "0.875rem",
                                padding: "6px 12px",
                              }}
                            >
                              {status}
                            </Button>
                          ))}
                        </Grid>
                      </Grid>
                    </Toolbar>
                  </AppBar>





                <Grid container spacing={3} sx={{ marginTop: 3 }}>
                  <Grid item xs={12}>
                    <Box>
                      <Typography
                        variant="h5"
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          marginBottom: 3,
                          marginTop: -5,  
                          color: "primary.main",
                        }}
                      >
                      </Typography>

                      {/* ABERTURA DO MODAL PARA MOSTRAR OS ITENS PARA EDICAO */}
                      <List sx={{ width: "100%" }}>
                        {filtrarOrcamentos().map((orcamento) => (
                          <Paper
                            key={orcamento.id}
                            elevation={2}
                            sx={{
                              marginBottom: "8px",
                              padding: "6px 10px",
                              borderRadius: "6px",
                              transition: "0.3s",
                              backgroundColor: "#fff",
                              "&:hover": {
                                boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                                backgroundColor: "#f8f9fc",
                              },
                            }}
                          >
                            <ListItem
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                padding: "4px 8px",
                              }}
                            >
                              {/* Informações do orçamento */}
                              <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                                <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "0.9rem", color: "#195ad2" }}>
                                  ID: {orcamento.id} {" "}
                                  <Typography 
                                    variant="caption" 
                                    sx={{
                                      backgroundColor: "#1976d2", 
                                      color: "#fff", 
                                      borderRadius: "10px",
                                      padding: "2px 8px",
                                      fontSize: "0.75rem",
                                      fontWeight: "bold",
                                      marginLeft: "8px"
                                    }}
                                  >
                                    {orcamento.pecas ? orcamento.pecas.length : 0} itens
                                  </Typography>
                                </Typography>
                                <Typography variant="body2" sx={{ color: "#666", fontSize: "0.75rem" }}>
                                  Fornecedor: {orcamento.fornecedor}
                                </Typography>
                              </Box>

                              {/* Ícone de Status + Botões de Ação */}
                              <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                                {getStatusIcon(orcamento.status)}

                                {/* Botões de ação mais compactos */}
                                {renderOrcamentoActions(orcamento)}

                                {/* Botão de Expandir */}
                                <Tooltip title="Expandir">
                                  <IconButton
                                    onClick={() => setExpanded(expanded === orcamento.id ? null : orcamento.id)}
                                    sx={{
                                      color: expanded === orcamento.id ? "#195ad2" : "#666",
                                      padding: "4px",
                                      "&:hover": { backgroundColor: "rgba(25, 90, 210, 0.1)" },
                                    }}
                                  >
                                    {expanded === orcamento.id ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </ListItem>

                            <Collapse in={expanded === orcamento.id} timeout="auto" unmountOnExit>
                              <Box
                                sx={{
                                  padding: "12px",
                                  backgroundColor: "#f5f7fa",
                                  borderRadius: "8px",
                                  border: "1px solid #ddd",
                                }}
                              >
                                <List component="div" disablePadding>
                                  {orcamento.pecas.map((peca) => (
                                    <React.Fragment key={peca.pecaId}>
                                      <ListItem
                                        sx={{
                                          padding: "6px 8px",
                                          borderBottom: "1px solid #e0e0e0",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
                                          {/* Informações principais da peça */}
                                          <Box sx={{ flexGrow: 1 }}>
                                            <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "0.9rem", color: "#333" }}>
                                              {peca.descricao}
                                            </Typography>
                                            <Typography variant="body2" sx={{ color: "#666", fontSize: "0.8rem" }}>
                                              Quantidade: {peca.quantidade} | Preço: {new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(peca.valorVenda)}

                                            </Typography>
                                          </Box>
                                        </Box>
                                      </ListItem>
                                    </React.Fragment>
                                  ))}
                                </List>
                              </Box>
                            </Collapse>
                          </Paper>
                        ))}
                      </List>
                  

        {/* 🔹 Botão "Carregar Mais" */}
                  {hasMore && (
                    <Box sx={{ textAlign: "center", marginTop: 3 }}>
                      <Button variant="contained" color="primary" onClick={() => fetchOrcamentos(filtro, true)} disabled={isLoading}>
                        {isLoading ? "Carregando..." : "Carregar Mais"}
                      </Button>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        {selectedOrcamento && (
        <Dialog
          open={modalOpen}
          onClose={handleCloseModal}
          fullWidth
          maxWidth="sm" // 🔹 Definindo um tamanho menor para evitar modal exagerado
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: 2,
              boxShadow: 3,
              maxHeight: "90vh", // 🔹 Evita que ocupe a tela toda
            },
          }}
        >
          <DialogTitle sx={{ fontSize: "1.1rem", fontWeight: "bold", padding: "12px 24px" }}>
            Orçamento ID: {selectedOrcamento.id}
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              sx={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent dividers sx={{ padding: "16px 24px" }}>
            <Typography variant="subtitle2" sx={{ fontWeight: "bold", marginBottom: 1 }}>
              Fornecedor: {selectedOrcamento.fornecedor}
            </Typography>
            <Typography variant="subtitle2" sx={{ fontWeight: "bold", marginBottom: 2 }}>
              Status: {selectedOrcamento.status}
            </Typography>

            {selectedOrcamento.pecas?.map((peca) => (
              <Box key={peca.pecaId} sx={{ marginBottom: 2, padding: 1, borderRadius: 1, border: "1px solid #ddd" }}>
                {editingItems[peca.pecaId] ? (
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Descrição"
                        size="small"
                        value={editingItems[peca.pecaId]?.descricao || ""}
                        onChange={(e) =>
                          setEditingItems((prev) => ({
                            ...prev,
                            [peca.pecaId]: { ...(prev[peca.pecaId] || {}), descricao: e.target.value },
                          }))
                        }
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl fullWidth size="small">
                        <InputLabel>Categoria</InputLabel>
                        <Select
                          value={editingItems[peca.pecaId]?.categoria || ""}
                          onChange={(e) =>
                            setEditingItems((prev) => ({
                              ...prev,
                              [peca.pecaId]: { ...(prev[peca.pecaId] || {}), categoria: e.target.value },
                            }))
                          }
                        >
                          {categorias.map((cat, index) => (
                            <MenuItem key={index} value={cat}>
                              {cat}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                      <Autocomplete
                        freeSolo
                        options={marcas}
                        value={editingItems[peca.pecaId]?.marca || ""}
                        onChange={(event, newValue) =>
                          setEditingItems((prev) => ({
                            ...prev,
                            [peca.pecaId]: { ...(prev[peca.pecaId] || {}), marca: newValue },
                          }))
                        }
                        renderInput={(params) => <TextField {...params} label="Marca" size="small" fullWidth />}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Quantidade"
                        type="number"
                        size="small"
                        value={editingItems[peca.pecaId]?.quantidade || ""}
                        onChange={(e) =>
                          setEditingItems((prev) => ({
                            ...prev,
                            [peca.pecaId]: { ...(prev[peca.pecaId] || {}), quantidade: e.target.value },
                          }))
                        }
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Valor de Venda"
                        type="text" // 🔥 Mantemos como "text" para aceitar valores formatados corretamente
                        size="small"
                        value={editingItems[peca.pecaId]?.valorVenda !== undefined 
                          ? editingItems[peca.pecaId]?.valorVenda.toString().replace(".", ",") 
                          : ""}
                        onChange={(e) => {
                          let valor = e.target.value.replace(/\D/g, ""); // 🔥 Mantém apenas números
                          let valorFormatado = valor ? parseFloat(valor) / 100 : ""; // 🔥 Converte para número decimal

                          setEditingItems((prev) => ({
                            ...prev,
                            [peca.pecaId]: {
                              ...(prev[peca.pecaId] || {}),
                              valorVenda: valorFormatado, // 🔥 Salva como número, não string formatada!
                            },
                          }));
                        }}
                        inputProps={{ inputMode: "numeric" }}
                      />
                    </Grid>



                    <Grid item xs={12} display="flex" justifyContent="flex-end">
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => handleSaveItem(selectedOrcamento.id, editingItems[peca.pecaId], peca.pecaId)}
                      >
                        Salvar
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box>
                    <Typography variant="body2">
                      <strong>Descrição:</strong> {peca.descricao}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Qtd:</strong> {peca.quantidade} | <strong>Preço:</strong> {formatCurrency(peca.valorVenda)}
                    </Typography>
                  </Box>

                  <Box display="flex" gap={1}>
                    <IconButton onClick={() => handleEditItem(selectedOrcamento.id, peca.pecaId)} size="small">
                      <EditIcon fontSize="small" />
                    </IconButton>

                    <IconButton onClick={() => handleDeleteItemConfirm(selectedOrcamento.id, peca.pecaId)} size="small" color="error">
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>

                )}
              </Box>
            ))}
          </DialogContent>
          {/*DIALGO PARA PERGUNTA DE EXCLUSAO E CONFIRMACAO DO ITEM */}
          <Dialog open={confirmDeleteOpen} onClose={() => setConfirmDeleteOpen(false)} fullWidth maxWidth="xs">
            <DialogTitle sx={{ fontWeight: "bold", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <WarningAmberIcon color="warning" sx={{ fontSize: 32, marginRight: 1 }} />
              Confirmar Exclusão
            </DialogTitle>

            <DialogContent dividers sx={{ textAlign: "center", padding: "16px 24px" }}>
              <Typography variant="body1">
                Tem certeza de que deseja excluir esta peça? Esta ação não pode ser desfeita.
              </Typography>
            </DialogContent>

            <DialogActions sx={{ padding: "8px 24px", justifyContent: "space-between" }}>
              <Button onClick={() => setConfirmDeleteOpen(false)} color="primary" variant="outlined" size="small">
                Cancelar
              </Button>
              <Button onClick={handleDeleteItem} color="error" variant="contained" size="small">
                Excluir
              </Button>
            </DialogActions>
          </Dialog>

          <DialogActions sx={{ padding: "8px 24px", justifyContent: "space-between" }}>
            <Button onClick={handleCloseModal} color="primary" variant="outlined" size="small">
              Fechar
            </Button>
            <Button onClick={handleOpenAddItemModal} color="secondary" variant="contained" size="small">
              Incluir Novo Item
            </Button>
          </DialogActions>
        </Dialog>
        )}

          <Dialog
            open={editItemModalOpen}
            onClose={() => setEditItemModalOpen(false)}
            fullWidth
            maxWidth="xs" // 🔹 Modal menor para evitar excesso de espaço em telas pequenas
            sx={{
              "& .MuiDialog-paper": {
                borderRadius: 2,
                boxShadow: 3,
                padding: 2,
              },
            }}
          >
            <DialogTitle sx={{ fontSize: "1.1rem", fontWeight: "bold", padding: "12px 24px" }}>
              Editar Peça
              <IconButton
                aria-label="close"
                onClick={() => setEditItemModalOpen(false)}
                sx={{ position: "absolute", right: 8, top: 8 }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            {/*Dialogo para edicao de itens dentro do orcamento */}
            <DialogContent dividers sx={{ padding: "16px 24px" }}>
              {selectedPeca ? (
                <Grid container spacing={2}>
                  {/* Campo para Descrição */}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Descrição"
                      size="small"
                      value={selectedPeca.descricao || ""}
                      onChange={(e) =>
                        setSelectedPeca({ ...selectedPeca, descricao: e.target.value })
                      }
                    />
                  </Grid>

                  {/* Campo de seleção de Categoria */}
                  <Grid item xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Categoria</InputLabel>
                      <Select
                        value={selectedPeca.categoria || ""}
                        onChange={(e) =>
                          setSelectedPeca({ ...selectedPeca, categoria: e.target.value })
                        }
                      >
                        {categorias.map((cat, index) => (
                          <MenuItem key={index} value={cat}>
                            {cat}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Campo de seleção de Marca */}
                  <Grid item xs={12}>
                    <Autocomplete
                      freeSolo
                      options={marcas}
                      value={selectedPeca.marca || ""}
                      onChange={(event, newValue) =>
                        setSelectedPeca({ ...selectedPeca, marca: newValue })
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Marca" size="small" fullWidth />
                      )}
                    />
                  </Grid>

                  {/* Campo para Quantidade */}
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Quantidade"
                      type="number"
                      size="small"
                      value={selectedPeca.quantidade || ""}
                      onChange={(e) =>
                        setSelectedPeca({
                          ...selectedPeca,
                          quantidade: parseInt(e.target.value, 10) || 0,
                        })
                      }
                    />
                  </Grid>

                  {/* Campo para Valor de Venda */}
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Valor de Venda"
                      type="text" // 🔥 Alterado de "number" para "text" para aceitar formatação
                      size="small"
                      value={selectedPeca.valorVenda || ""}
                      onChange={(e) => {
                        let valor = e.target.value.replace(/\D/g, ""); // 🔥 Remove tudo que não for número
                        if (valor) {
                          let valorFormatado = parseFloat(valor) / 100; // 🔥 Divide por 100 para adicionar casas decimais
                          setSelectedPeca({
                            ...selectedPeca,
                            valorVenda: valorFormatado.toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }),
                          });
                        } else {
                          setSelectedPeca({
                            ...selectedPeca,
                            valorVenda: "",
                          });
                        }
                      }}
                      inputProps={{ inputMode: "numeric" }}
                    />
                  </Grid>

                </Grid>
              ) : (
                <Typography>Erro ao carregar dados da peça.</Typography>
              )}
            </DialogContent>

            <DialogActions sx={{ padding: "8px 24px", justifyContent: "space-between" }}>
              <Button onClick={() => setEditItemModalOpen(false)} color="primary" variant="outlined" size="small">
                Cancelar
              </Button>
              <Button
                onClick={() => handleSaveItem(selectedOrcamento.id, selectedPeca, selectedPeca.pecaId)}
                color="secondary"
                variant="contained"
                size="small"
              >
                Salvar Alterações
              </Button>
            </DialogActions>
          </Dialog>


          <Dialog
            open={addItemModalOpen}
            onClose={handleCloseAddItemModal}
            fullWidth
            maxWidth="xs" // 🔹 Modal menor para não ficar exagerado
            sx={{
              "& .MuiDialog-paper": {
                borderRadius: 2,
                boxShadow: 3,
                padding: 2,
              },
            }}
          >
            <DialogTitle sx={{ fontSize: "1.1rem", fontWeight: "bold", padding: "12px 24px" }}>
              Adicionar Novo Item
              <IconButton
                aria-label="close"
                onClick={handleCloseAddItemModal}
                sx={{ position: "absolute", right: 8, top: 8 }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent dividers sx={{ padding: "16px 24px" }}>
              <Grid container spacing={2}>
                {/* Campo para Descrição */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Descrição"
                    size="small"
                    value={newItem.descricao}
                    onChange={(e) => setNewItem({ ...newItem, descricao: e.target.value })}
                  />
                </Grid>

                {/* Campo de seleção de Categoria */}
                <Grid item xs={12}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Categoria</InputLabel>
                    <Select
                      value={newItem.categoria}
                      onChange={(e) => setNewItem({ ...newItem, categoria: e.target.value })}
                    >
                      {categorias.map((cat, index) => (
                        <MenuItem key={index} value={cat}>
                          {cat}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Campo de seleção de Marca */}
                <Grid item xs={12}>
                  <Autocomplete
                    freeSolo
                    options={marcas}
                    value={newItem.marca}
                    onChange={(event, newValue) => setNewItem({ ...newItem, marca: newValue })}
                    renderInput={(params) => <TextField {...params} label="Marca" size="small" fullWidth />}
                  />
                </Grid>

                {/* Campo para Tamanho */}
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Tamanho"
                    size="small"
                    value={newItem.tamanho}
                    onChange={(e) => setNewItem({ ...newItem, tamanho: e.target.value })}
                  />
                </Grid>

                {/* Campo para Quantidade */}
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Quantidade"
                    type="number"
                    size="small"
                    value={newItem.quantidade}
                    onChange={(e) => setNewItem({ ...newItem, quantidade: e.target.value })}
                  />
                </Grid>

                {/* Campo para Valor de Venda */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Valor de Venda"
                    type="text"
                    size="small"
                    value={newItem.valorVenda}
                    onChange={(e) => {
                      let valor = e.target.value.replace(/\D/g, ""); // 🔥 Remove caracteres não numéricos
                      if (valor) {
                        let valorFormatado = parseFloat(valor) / 100; // 🔥 Divide por 100 para converter em decimal
                        setNewItem({ 
                          ...newItem, 
                          valorVenda: valorFormatado.toLocaleString("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) 
                        });
                      } else {
                        setNewItem({ ...newItem, valorVenda: "" });
                      }
                    }}
                    inputProps={{ inputMode: "numeric" }}
                  />
                </Grid>


              </Grid>
            </DialogContent>

            <DialogActions sx={{ padding: "8px 24px", justifyContent: "space-between" }}>
              <Button onClick={handleCloseAddItemModal} color="primary" variant="outlined" size="small">
                Cancelar
              </Button>
              <Button onClick={handleAddNewItem} color="secondary" variant="contained" size="small">
                Adicionar Item
              </Button>
            </DialogActions>
          </Dialog>



            <Dialog open={confirmDialogOpen} onClose={handleCancelDelete} fullWidth maxWidth="xs">
              <DialogTitle sx={{ fontWeight: "bold", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <WarningAmberIcon color="warning" sx={{ fontSize: 32, marginRight: 1 }} />
                Confirmar Exclusão
              </DialogTitle>

              <DialogContent dividers sx={{ textAlign: "center", padding: "16px 24px" }}>
                <Typography variant="body1">
                  Tem certeza de que deseja excluir este item? O item sera enviado para os Excluidos.
                </Typography>
              </DialogContent>

              <DialogActions sx={{ padding: "8px 24px", justifyContent: "space-between" }}>
                <Button onClick={handleCancelDelete} color="primary" variant="outlined" size="small">
                  Cancelar
                </Button>
                <Button onClick={handleConfirmDelete} color="error" variant="contained" size="small">
                  Excluir
                </Button>
          </DialogActions>
        </Dialog>
          <Dialog open={alertModalOpen} onClose={() => setAlertModalOpen(false)} fullWidth maxWidth="xs">
            <DialogTitle sx={{ fontWeight: "bold", textAlign: "center", padding: "12px 24px" }}>
              Aviso
              <IconButton
                aria-label="close"
                onClick={() => setAlertModalOpen(false)}
                sx={{ position: "absolute", right: 8, top: 8 }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent dividers sx={{ textAlign: "center", padding: "16px 24px" }}>
              <Typography variant="body1">{alertMessage}</Typography>
            </DialogContent>

            <DialogActions sx={{ justifyContent: "center", padding: "8px 24px" }}>
              <Button onClick={() => setAlertModalOpen(false)} color="primary" variant="contained" size="small" autoFocus>
                Fechar
              </Button>
            </DialogActions>
          </Dialog>
      </ThemeProvider>
    </Container>
  );
};

export default ListaDeOrcamentos;
