import React, { useState, useContext, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
  Collapse,
  Modal,
  Tabs,
  Tab,
  Box,
  InputAdornment,
  Typography,
  Card,
  CardContent,
  TextField,
  Button,
  List as MuiList,
  ListItem as MuiListItem,
  ListItemSecondaryAction,
  Grid,
  Divider
} from "@mui/material";
import {
  Menu as MenuIcon,
  Notifications as NotificationsIcon,
  Settings as SettingsIcon,
  Dashboard as DashboardIcon,
  GroupAdd as GroupAddIcon,
  Business as BusinessIcon,
  Checkroom as CheckroomIcon,
  Calculate as CalculateIcon,
  ListAlt as ListAltIcon,
  PointOfSale as PointOfSaleIcon,
  Inventory as InventoryIcon,
  BarChart as BarChartIcon,
  Payment as PaymentIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Phone as PhoneIcon,
  Add as AddIcon,
  LocalOffer as LocalOfferIcon,
  CorporateFare as CorporateFareIcon,
  FormatListBulleted as FormatListBulletedIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Logout as LogoutIcon,
  Check as CheckIcon
} from "@mui/icons-material";
import { getFirestore, doc, getDoc, updateDoc, collection, query, getDocs } from "firebase/firestore";
import { signOut, getAuth } from "firebase/auth";
import { Sync as SyncIcon } from "@mui/icons-material";


const Sidebar = () => {
  const { userDetails } = useContext(AuthContext);
  const empresaId = userDetails.empresaId;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [openFinanceiro, setOpenFinanceiro] = useState(false);
  const [openCadastros, setOpenCadastros] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [empresaData, setEmpresaData] = useState({});
  const [categorias, setCategorias] = useState([]);
  const [newCategoria, setNewCategoria] = useState("");
  const [editIndex, setEditIndex] = useState(-1);
  const [editCategoria, setEditCategoria] = useState("");
  const [categoriasOpen, setCategoriasOpen] = useState(false);
  const [marcas, setMarcas] = useState([]);
  const [newMarca, setNewMarca] = useState("");
  const [editMarcaIndex, setEditMarcaIndex] = useState(-1);
  const [editMarca, setEditMarca] = useState("");
  const [marcasOpen, setMarcasOpen] = useState(false);
  const [custosEfetivos, setCustosEfetivos] = useState([]);
  const [newCusto, setNewCusto] = useState({ descricao: "", valor: "" });
  const [editCustoIndex, setEditCustoIndex] = useState(-1);
  const [editCusto, setEditCusto] = useState({ descricao: "", valor: "" });
  const [custosOpen, setCustosOpen] = useState(false);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();

  const [contratoFornecedor, setContratoFornecedor] = useState(""); // Estado para armazenar o contrato
  const [isEditingContrato, setIsEditingContrato] = useState(false); // Controle de edição
  const [contratoId, setContratoId] = useState(""); // Guardar o ID do contrato
  const [contratos, setContratos] = useState([]); // Lista de contratos
  const [isContratoOpen, setIsContratoOpen] = useState(false);

  useEffect(() => {
    const fetchEmpresaData = async () => {
      const db = getFirestore();
      const empresaDocRef = doc(db, "EMPRESAS", empresaId);
      const empresaDoc = await getDoc(empresaDocRef);
      if (empresaDoc.exists()) {
        const data = empresaDoc.data();
        setEmpresaData(data);
        setCategorias(data.Categorias || []);
        setMarcas(data.Marcas || []);
        setCustosEfetivos(data.CustosEfetivos || []);
      }
    };

    // Função para buscar os contratos do fornecedor
    const fetchContratosFornecedor = async () => {
      try {
        const db = getFirestore();
        const contratosRef = collection(db, "EMPRESAS", empresaId, "Contratos");
        const q = query(contratosRef);

        const querySnapshot = await getDocs(q);
        const contratosList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setContratos(contratosList);
      } catch (error) {
        console.error("Erro ao buscar os contratos:", error);
      }
    };

    fetchEmpresaData();
    fetchContratosFornecedor();
  }, [empresaId]);

  const toggleSidebar = () => {
    if (!isLargeScreen) {
      setSidebarOpen(!sidebarOpen);
    }
  };

  const handleClickFinanceiro = () => {
    setOpenFinanceiro(!openFinanceiro);
  };

  const handleClickCadastros = () => {
    setOpenCadastros(!openCadastros);
  };

  const handleOpenSettings = () => {
    setSettingsOpen(true);
  };

  const handleCloseSettings = () => {
    setSettingsOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Função para selecionar um contrato
  const handleContratoSelect = (id, name) => {
    const contratoSelecionado = contratos.find(contrato => contrato.id === id);
    setContratoFornecedor(contratoSelecionado?.contrato_fornecedor || "");
    setContratoId(id);
    setIsContratoOpen(true);
  };

  // Função para salvar as alterações no contrato
  const handleSaveContrato = async () => {
    try {
      const db = getFirestore();
      const contratoDocRef = doc(db, "EMPRESAS", empresaId, "Contratos", contratoId);
      await updateDoc(contratoDocRef, { contrato_fornecedor: contratoFornecedor });
      setIsEditingContrato(false);
      console.log("Contrato atualizado com sucesso!");
    } catch (error) {
      console.error("Erro ao atualizar o contrato:", error);
    }
  };

  const handleAddCategoria = async () => {
    if (newCategoria.trim() === "") return;

    const updatedCategorias = [...categorias, newCategoria];
    await updateDoc(doc(getFirestore(), "EMPRESAS", empresaId), {
      Categorias: updatedCategorias
    });

    setCategorias(updatedCategorias);
    setNewCategoria("");
  };

  const handleEditCategoria = index => {
    setEditIndex(index);
    setEditCategoria(categorias[index]);
  };

  const handleSaveEditCategoria = async () => {
    if (editCategoria.trim() === "") return;

    const updatedCategorias = categorias.map((cat, index) =>
      index === editIndex ? editCategoria : cat
    );
    await updateDoc(doc(getFirestore(), "EMPRESAS", empresaId), {
      Categorias: updatedCategorias
    });

    setCategorias(updatedCategorias);
    setEditIndex(-1);
    setEditCategoria("");
  };

  const handleDeleteCategoria = async index => {
    const updatedCategorias = categorias.filter((_, i) => i !== index);
    await updateDoc(doc(getFirestore(), "EMPRESAS", empresaId), {
      Categorias: updatedCategorias
    });

    setCategorias(updatedCategorias);
  };

  const handleAddMarca = async () => {
    if (newMarca.trim() === "") return;

    const updatedMarcas = [...marcas, newMarca];
    await updateDoc(doc(getFirestore(), "EMPRESAS", empresaId), {
      Marcas: updatedMarcas
    });

    setMarcas(updatedMarcas);
    setNewMarca("");
  };

  const handleEditMarca = index => {
    setEditMarcaIndex(index);
    setEditMarca(marcas[index]);
  };

  const handleSaveEditMarca = async () => {
    if (editMarca.trim() === "") return;

    const updatedMarcas = marcas.map((marca, index) =>
      index === editMarcaIndex ? editMarca : marca
    );
    await updateDoc(doc(getFirestore(), "EMPRESAS", empresaId), {
      Marcas: updatedMarcas
    });

    setMarcas(updatedMarcas);
    setEditMarcaIndex(-1);
    setEditMarca("");
  };

  const handleDeleteMarca = async index => {
    const updatedMarcas = marcas.filter((_, i) => i !== index);
    await updateDoc(doc(getFirestore(), "EMPRESAS", empresaId), {
      Marcas: updatedMarcas
    });

    setMarcas(updatedMarcas);
  };

  const handleCategoriasToggle = () => {
    setCategoriasOpen(!categoriasOpen);
  };

  const handleMarcasToggle = () => {
    setMarcasOpen(!marcasOpen);
  };

  const handleCustosToggle = () => {
    setCustosOpen(!custosOpen);
  };

  const formatCurrency = value => {
    return parseFloat(value).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL"
    });
  };

  const handleAddCusto = async () => {
    if (newCusto.descricao.trim() === "" || newCusto.valor.trim() === "")
      return;

    const updatedCustos = [...custosEfetivos, newCusto];
    await updateDoc(doc(getFirestore(), "EMPRESAS", empresaId), {
      CustosEfetivos: updatedCustos
    });

    setCustosEfetivos(updatedCustos);
    setNewCusto({ descricao: "", valor: "" });
  };

  const handleEditCusto = index => {
    setEditCustoIndex(index);
    setEditCusto(custosEfetivos[index]);
  };

  const handleSaveEditCusto = async () => {
    if (editCusto.descricao.trim() === "" || editCusto.valor.trim() === "")
      return;

    const updatedCustos = custosEfetivos.map((custo, index) =>
      index === editCustoIndex ? editCusto : custo
    );
    await updateDoc(doc(getFirestore(), "EMPRESAS", empresaId), {
      CustosEfetivos: updatedCustos
    });

    setCustosEfetivos(updatedCustos);
    setEditCustoIndex(-1);
    setEditCusto({ descricao: "", valor: "" });
  };

  const handleDeleteCusto = async index => {
    const updatedCustos = custosEfetivos.filter((_, i) => i !== index);
    await updateDoc(doc(getFirestore(), "EMPRESAS", empresaId), {
      CustosEfetivos: updatedCustos
    });

    setCustosEfetivos(updatedCustos);
  };

  const handleLogout = async () => {
    try {
      await signOut(getAuth());
      navigate(`/login`);
    } catch (error) {
      console.error("Erro ao sair:", error);
    }
  };

  const menuItems = [
    { name: "Início", path: "/dashboard", icon: <DashboardIcon /> },
    {
      name: "Cadastros",
      icon: <InventoryIcon />,
      onClick: handleClickCadastros,
      subItems: [
        {
          name: "Cadastro e Consulta de Fornecedor",
          path: "/cadastro-fornecedor"
        },
        { name: "Cadastro de Peças", path: "/cadastro-roupas" },
        { name: "Cadastro e Consulta de Clientes", path: "/cadastro-Clientes" }
      ]
    },
    { name: "Orçamentos", path: "/orcamentos", icon: <CalculateIcon /> },
    {
      name: "Lista de Orçamentos",
      path: "/lista-orcamentos",
      icon: <ListAltIcon />
    },
    { name: "PDV", path: "/vender", icon: <PointOfSaleIcon /> },

    {
      name: "Gerenciar Estoque",
      path: "/gerenciar-produtos",
      icon: <InventoryIcon />
    },
   
    { name: "Etiquetas", path: "/etiquetas", icon: <LocalOfferIcon /> },
    {
      name: "Financeiro",
      icon: <PaymentIcon />,
      onClick: handleClickFinanceiro,
      subItems: [
        { name: "Pagamento a Fornecedores", path: "/pagamento-fornecedores" },
        { name: "Extrato de Pagamentos", path: "/historicodepagamentos" },
        { name: "Caixa Empresa", path: "/caixa" }
      ]
    }
  ];

  const drawerContent = (
    <List sx={{ backgroundColor: "#003f5c", height: "100%" }}>
      {menuItems.map((item, index) => {
        const isSubItem = item.subItems && item.subItems.length > 0;
        let isOpen = false;
        if (item.name === "Financeiro") {
          isOpen = openFinanceiro;
        } else if (item.name === "Cadastros") {
          isOpen = openCadastros;
        }

        return (
          <React.Fragment key={index}>
            <ListItem
              button
              component={isSubItem ? "div" : NavLink}
              to={isSubItem ? "#" : item.path}
              onClick={isSubItem ? item.onClick : toggleSidebar}
              sx={{
                color: "white",
                "&.active": {
                  backgroundColor: "#005f73"
                },
                "&:hover": {
                  backgroundColor: "#005f73"
                }
              }}
            >
              <ListItemIcon sx={{ color: "white" }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
              {isSubItem && (isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
            </ListItem>
            {isSubItem && (
              <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.subItems.map((subItem, subIndex) => (
                    <ListItem
                      button
                      key={subIndex}
                      component={NavLink}
                      to={subItem.path}
                      onClick={toggleSidebar}
                      sx={{
                        pl: 4,
                        color: "white",
                        "&.active": {
                          backgroundColor: "#007f91"
                        },
                        "&:hover": {
                          backgroundColor: "#007f91"
                        }
                      }}
                    >
                      <ListItemText primary={subItem.name} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        );
      })}
      <ListItem
        button
        onClick={handleLogout}
        sx={{
          color: "white",
          mt: "auto",
          "&:hover": {
            backgroundColor: "#005f73"
          }
        }}
      >
        <ListItemIcon sx={{ color: "white" }}>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Sair" />
      </ListItem>
      <ListItem
        button
        onClick={handleOpenSettings}
        sx={{
          color: "white",
          mt: 1,
          "&:hover": {
            backgroundColor: "#005f73"
          }
        }}
      >
        <ListItemIcon sx={{ color: "white" }}>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Configurações" />
      </ListItem>
    </List>
  );
  const handleSyncCategorias = async () => {
    try {
      const db = getFirestore();
      const estoqueAtivoRef = collection(db, "EMPRESAS", empresaId, "Cadastro de Peças", "dados", "Estoque Ativo");
      const estoqueSnapshot = await getDocs(estoqueAtivoRef);
      let categoriasEncontradas = new Set();

      estoqueSnapshot.forEach((doc) => {
        const itemData = doc.data();
        if (itemData.categoria) {
          categoriasEncontradas.add(itemData.categoria);
        }
      });

      const categoriasArray = Array.from(categoriasEncontradas);
      await updateDoc(doc(db, "EMPRESAS", empresaId), { Categorias: categoriasArray });

      setCategorias(categoriasArray);
      console.log("Categorias sincronizadas com sucesso!");
    } catch (error) {
      console.error("Erro ao sincronizar categorias:", error);
    }
  };

  const handleSyncMarcas = async () => {
    try {
      const db = getFirestore();
      const estoqueAtivoRef = collection(db, "EMPRESAS", empresaId, "Cadastro de Peças", "dados", "Estoque Ativo");
      const estoqueSnapshot = await getDocs(estoqueAtivoRef);
      let marcasEncontradas = new Set();

      estoqueSnapshot.forEach((doc) => {
        const itemData = doc.data();
        if (itemData.marca) {
          marcasEncontradas.add(itemData.marca);
        }
      });

      const marcasArray = Array.from(marcasEncontradas);
      await updateDoc(doc(db, "EMPRESAS", empresaId), { Marcas: marcasArray });

      setMarcas(marcasArray);
      console.log("Marcas sincronizadas com sucesso!");
    } catch (error) {
      console.error("Erro ao sincronizar marcas:", error);
    }
  };

  return (
    <>
      <AppBar position="static" color="default" elevation={1}>
        <Toolbar sx={{ justifyContent: "flex-end" }}>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton color="inherit" onClick={handleOpenSettings}>
            <SettingsIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleSidebar}
        sx={{ ...(isLargeScreen && { display: "none" }) }}
      >
        <MenuIcon />
      </IconButton>
      {isLargeScreen ? (
        <Drawer
          variant="permanent"
          open
          sx={{
            width: 250,
            "& .MuiDrawer-paper": {
              width: 250,
              boxSizing: "border-box",
              backgroundColor: "#003f5c",
              color: "white"
            }
          }}
        >
          {drawerContent}
        </Drawer>
      ) : (
        <Drawer
          variant="temporary"
          anchor="left"
          open={sidebarOpen}
          onClose={toggleSidebar}
          ModalProps={{ keepMounted: true }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              backgroundColor: "#005f73",
              color: "white"
            }
          }}
        >
          {drawerContent}
        </Drawer>
      )}
      <Modal
        open={settingsOpen}
        onClose={handleCloseSettings}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 600,
            p: 3,
            outline: "none",
            backgroundColor: "#f0f4f7",
            boxShadow: 24,
            borderRadius: 2,
            maxHeight: "80vh", // Define altura máxima
            overflowY: "auto" // Permite rolagem dentro do modal
          }}
        >
          <Tabs value={tabValue} onChange={handleTabChange} centered>
            <Tab icon={<BusinessIcon />} label="Dados da Empresa" />
            <Tab icon={<SettingsIcon />} label="Configurações" />
            <Tab icon={<PaymentIcon />} label="Financeiro" />
            <Tab icon={<CorporateFareIcon />} label="Contratos" />
          </Tabs>

          <CardContent sx={{ maxHeight: "70vh", overflowY: "auto" }}>
            <TabPanel value={tabValue} index={0}>
              <Typography variant="h6" gutterBottom>
                Dados da Empresa
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
                    <CorporateFareIcon sx={{ mr: 1 }} />
                    <strong>CNPJ:</strong> {empresaData.CNPJ}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
                    <BusinessIcon sx={{ mr: 1 }} />
                    <strong>Nome:</strong> {empresaData.nome}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
                    <PhoneIcon sx={{ mr: 1 }} />
                    <strong>Telefone:</strong> {empresaData.telefone}
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <Typography variant="h6" gutterBottom>
                Configurações
              </Typography>
              <Divider sx={{ mb: 2 }} />

              {/* Categorias */}
              <Typography
                variant="subtitle1"
                onClick={handleCategoriasToggle}
                sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
              >
                <FormatListBulletedIcon sx={{ mr: 1 }} />
                Categorias
                {categoriasOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Typography>
              <Collapse in={categoriasOpen} timeout="auto" unmountOnExit>
                <Box sx={{ maxHeight: 200, overflowY: "auto", paddingRight: 1 }}>
                  <MuiList>
                    {categorias.map((cat, index) => (
                      <MuiListItem key={index} sx={{ pl: 4 }}>
                        {editIndex === index ? (
                          <TextField
                            value={editCategoria}
                            onChange={e => setEditCategoria(e.target.value)}
                            fullWidth
                            sx={{ mr: 2 }}
                          />
                        ) : (
                          <ListItemText primary={cat} />
                        )}
                        <ListItemSecondaryAction>
                          {editIndex === index ? (
                            <IconButton onClick={handleSaveEditCategoria} sx={{ color: "green" }}>
                              <CheckIcon />
                            </IconButton>
                          ) : (
                            <IconButton onClick={() => handleEditCategoria(index)} sx={{ color: "blue" }}>
                              <EditIcon />
                            </IconButton>
                          )}
                          <IconButton onClick={() => handleDeleteCategoria(index)} sx={{ color: "red" }}>
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </MuiListItem>
                    ))}
                  </MuiList>
                </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 2 }}>
                  <Button
                    onClick={handleSyncCategorias}
                    variant="contained"
                    color="secondary"
                    startIcon={<SyncIcon fontSize="small" />}
                    sx={{ fontSize: "0.8rem", padding: "5px 10px" }}
                  >
                    Sincronizar
                  </Button>
                  <TextField
                    value={newCategoria}
                    onChange={e => setNewCategoria(e.target.value)}
                    placeholder="Nova Categoria"
                    fullWidth
                    sx={{ flexGrow: 1 }}
                  />
                  <Button
                    onClick={handleAddCategoria}
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon fontSize="small" />}
                    sx={{ fontSize: "0.8rem", padding: "5px 10px" }}
                  >
                    Adicionar
                  </Button>
                </Box>
              </Collapse>

              <Divider sx={{ my: 2 }} />

              {/* Marcas */}
              <Typography
                variant="subtitle1"
                onClick={handleMarcasToggle}
                sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
              >
                <LocalOfferIcon sx={{ mr: 1 }} />
                Marcas
                {marcasOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Typography>
              <Collapse in={marcasOpen} timeout="auto" unmountOnExit>
                <Box sx={{ maxHeight: 200, overflowY: "auto", paddingRight: 1 }}>
                  <MuiList>
                    {marcas.map((marca, index) => (
                      <MuiListItem key={index} sx={{ pl: 4 }}>
                        {editMarcaIndex === index ? (
                          <TextField
                            value={editMarca}
                            onChange={e => setEditMarca(e.target.value)}
                            fullWidth
                            sx={{ mr: 2 }}
                          />
                        ) : (
                          <ListItemText primary={marca} />
                        )}
                        <ListItemSecondaryAction>
                          {editMarcaIndex === index ? (
                            <IconButton onClick={handleSaveEditMarca} sx={{ color: "green" }}>
                              <CheckIcon />
                            </IconButton>
                          ) : (
                            <IconButton onClick={() => handleEditMarca(index)} sx={{ color: "blue" }}>
                              <EditIcon />
                            </IconButton>
                          )}
                          <IconButton onClick={() => handleDeleteMarca(index)} sx={{ color: "red" }}>
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </MuiListItem>
                    ))}
                  </MuiList>
                </Box>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 2 }}>
                    <Button
                      onClick={handleSyncMarcas}
                      variant="contained"
                      color="secondary"
                      startIcon={<SyncIcon fontSize="small" />}
                      sx={{ fontSize: "0.8rem", padding: "5px 10px" }}
                    >
                      Sincronizar
                    </Button>
                    <TextField
                      value={newMarca}
                      onChange={e => setNewMarca(e.target.value)}
                      placeholder="Nova Marca"
                      fullWidth
                      sx={{ flexGrow: 1 }}
                    />
                    <Button
                      onClick={handleAddMarca}
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon fontSize="small" />}
                      sx={{ fontSize: "0.8rem", padding: "5px 10px" }}
                    >
                      Adicionar
                    </Button>
                </Box>
              </Collapse>
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Custos Efetivos
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Typography
                  variant="subtitle1"
                  onClick={handleCustosToggle}
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <FormatListBulletedIcon sx={{ mr: 1 }} />
                  Custos Efetivos
                  {custosOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Typography>
                <Collapse in={custosOpen} timeout="auto" unmountOnExit>
                  <MuiList>
                    {custosEfetivos.map((custo, index) => (
                      <MuiListItem key={index} sx={{ pl: 4 }}>
                        {editCustoIndex === index ? (
                          <TextField
                            value={editCusto.descricao}
                            onChange={e =>
                              setEditCusto(prev => ({
                                ...prev,
                                descricao: e.target.value
                              }))
                            }
                            fullWidth
                            sx={{ mr: 2 }}
                          />
                        ) : (
                          <ListItemText primary={custo.descricao} />
                        )}
                        {editCustoIndex === index ? (
                          <TextField
                            value={editCusto.valor}
                            onChange={e =>
                              setEditCusto(prev => ({
                                ...prev,
                                valor: e.target.value
                              }))
                            }
                            fullWidth
                            sx={{ mr: 2 }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  R$
                                </InputAdornment>
                              )
                            }}
                          />
                        ) : (
                          <ListItemText primary={formatCurrency(custo.valor)} />
                        )}
                        <ListItemSecondaryAction>
                          {editCustoIndex === index ? (
                            <IconButton
                              onClick={handleSaveEditCusto}
                              sx={{ color: "green" }}
                            >
                              <CheckIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => handleEditCusto(index)}
                              sx={{ color: "blue" }}
                            >
                              <EditIcon />
                            </IconButton>
                          )}
                          <IconButton
                            onClick={() => handleDeleteCusto(index)}
                            sx={{ color: "red" }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </MuiListItem>
                    ))}
                    <MuiListItem sx={{ pl: 4 }}>
                      <TextField
                        value={newCusto.descricao}
                        onChange={e =>
                          setNewCusto(prev => ({
                            ...prev,
                            descricao: e.target.value
                          }))
                        }
                        placeholder="Nova Descrição"
                        fullWidth
                        sx={{ mr: 2 }}
                      />
                      <TextField
                        value={newCusto.valor}
                        onChange={e =>
                          setNewCusto(prev => ({
                            ...prev,
                            valor: e.target.value
                          }))
                        }
                        placeholder="Novo Valor"
                        fullWidth
                        sx={{ mr: 2 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">R$</InputAdornment>
                          )
                        }}
                      />
                      <Button
                        onClick={handleAddCusto}
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                      >
                        Adicionar
                      </Button>
                    </MuiListItem>
                  </MuiList>
                </Collapse>
              </CardContent>
            </TabPanel>

            {/* Aba de Contratos Atualizada */}
            <TabPanel value={tabValue} index={3}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Contratos
                </Typography>
                <Divider sx={{ mb: 2 }} />
                {!isContratoOpen ? (
                  <MuiList>
                    {contratos.length > 0 ? (
                      contratos.map((contrato, index) => (
                        <MuiListItem
                          button
                          key={index}
                          onClick={() => handleContratoSelect(contrato.id, contrato.contrato_fornecedor)}
                          sx={{ "&:hover": { backgroundColor: "#e0f7fa" } }}
                        >
                          <ListItemText primary={contrato.contrato_fornecedor.substring(0, 25) + '...'} />
                        </MuiListItem>
                      ))
                    ) : (
                      <Typography variant="body2" sx={{ color: "#999" }}>
                        Nenhum contrato disponível
                      </Typography>
                    )}
                  </MuiList>
                ) : (
                  <Box sx={{ maxHeight: 400, overflowY: "auto", padding: 2 }}>
                    <TextField value={contratoFornecedor} onChange={(e) => setContratoFornecedor(e.target.value)} fullWidth multiline rows={10} />
                    <Button onClick={handleSaveContrato} variant="contained" color="primary" sx={{ mt: 2 }}>
                      Salvar
                    </Button>
                  </Box>
                )}
              </CardContent>
            </TabPanel>
          </CardContent>
        </Card>
      </Modal>

    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default Sidebar;
