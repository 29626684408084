import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import { db } from "./firebase";
import { useTheme } from "@mui/material/styles";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  setDoc,
  addDoc,
  getDoc,
  deleteDoc
} from "firebase/firestore";
import {
  Container,
  Grid,
  Button,
  IconButton,
  useMediaQuery,
  TextField,
  FormControlLabel,
  InputAdornment,
  Box,
  Paper,
  CircularProgress,
  Typography,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Checkbox,
  Modal,
  Card,
  CardContent  
} from "@mui/material"; // Certifique-se de importar Card e CardContent
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@mui/material";

import "jspdf-autotable";
import CloseIcon from "@mui/icons-material/Close";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PrintIcon from "@mui/icons-material/Print";
import JsBarcode from "jsbarcode";
import QRCode from "qrcode";
import * as XLSX from "xlsx";
import { FixedSizeList as ListVirtualized } from "react-window";
import { Tooltip } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SortIcon from "@mui/icons-material/Sort";
import jsPDF from "jspdf";
import "jspdf-autotable";


// Função para carregar produtos e fornecedores do Firestore
const carregarProdutosEFornecedores = async (userDetails, setProdutos, setFornecedores, setCategorias, setMarcas, setLoading) => {
  if (userDetails && userDetails.empresaId) {
    setLoading(true);
    const querySnapshot = await getDocs(
      collection(db, "EMPRESAS", userDetails.empresaId, "Cadastro de Peças", "dados", "Estoque Ativo")
    );

    const produtosData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      fornecedor: doc.data().fornecedor?.trim() || userDetails.empresaId, // Se fornecedor for null ou vazio, usa empresaId
    }));

    setProdutos(produtosData);
    setLoading(false);

    // Ordena fornecedores alfabeticamente
    const fornecedoresUnicos = [...new Set(produtosData.map((produto) => produto.fornecedor))]
      .sort((a, b) => a.localeCompare(b));

    setFornecedores(fornecedoresUnicos);
    setCategorias([...new Set(produtosData.map((produto) => produto.categoria))]);
    setMarcas([...new Set(produtosData.map((produto) => produto.marca))]);
  }
};
const gerarRelatorioDevolucao = (produtosSelecionados) => {
  if (!produtosSelecionados || produtosSelecionados.length === 0) {
    console.error("Nenhum produto selecionado para devolução.");
    return;
  }

  const doc = new jsPDF();
  doc.setFontSize(16);
  doc.text("Relatório de Devolução de Produtos", 14, 15);

  const tableData = produtosSelecionados.map((produto) => [
    produto.codigoPeca,
    produto.descricao,
    produto.fornecedor,
    produto.quantidade,
    `R$ ${parseFloat(produto.valorVenda || 0).toFixed(2)}`,
  ]);

  doc.autoTable({
    startY: 25,
    head: [["Código", "Descrição", "Fornecedor", "Quantidade", "Valor"]],
    body: tableData,
  });

  doc.save("relatorio_devolucao.pdf");
};



// Função utilitária para gerar a etiqueta no padrão correto
const gerarConteudoEtiqueta = async ({ codigo, descricao, tamanho, marca, valorVenda }) => {
  let canvas = document.createElement("canvas");

  // Gerar código de barras com tamanho aumentado
  JsBarcode(canvas, codigo, {
    format: "CODE128",
    width: 5, // 🔥 Aumentei a largura das barras para melhor leitura
    height: 80, // 🔥 Aumentei a altura para melhorar a captura
    displayValue: false,
    margin: 5,
  });

  let codigoBarras = canvas.toDataURL("image/png");

  // Gerar QR Code
  const qrCode = await QRCode.toDataURL(codigo, { width: 60, height: 60 });

  return `
    <div style="
        width: 5cm;
        height: 7.5cm;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: Arial, sans-serif;
        font-size: 12px;
        padding: 0.13cm;
        box-sizing: border-box;
        page-break-after: always;
    ">
        <!-- Parte superior da etiqueta -->
        <div style="
            width: 100%;
            height: 5cm;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            justify-content: space-around;
        ">
            <p style="font-weight: bold; font-size: 12px; margin: 2px;">Descrição: ${descricao}</p>
            <p style="margin: 2px 0; font-size: 12px;">Marca: ${marca}</p>
            <p style="margin: 2px 0; font-size: 12px;">Tamanho: ${tamanho}</p>
            <p style="margin: 2px 0; font-size: 14px; font-weight: bold;">Código: ${codigo}</p>

            <img src="${codigoBarras}" alt="Código de Barras" style="width: 150px; height: 80px; margin: 5px 0;" />
            <img src="${qrCode}" alt="QR Code" style="width: 60px; height: 60px;" />
        </div>

        <!-- Linha tracejada para indicar a separação -->
        <div style="
            width: 100%;
            border-top: 2px dashed black;
            margin: 3px 0;
        "></div>

        <!-- Parte destacável -->
        <div style="
            width: 100%;
            height: 2.5cm;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px; /* 🔥 Preço mais destacado */
            font-weight: bold;
        ">
            R$ ${valorVenda.toFixed(2).replace(".", ",")}
        </div>
    </div>
  `;
};

// Função para gerar múltiplas etiquetas com o padrão correto
const gerarEtiquetasMultiplas = async (produtosSelecionados) => {
  if (!Array.isArray(produtosSelecionados) || produtosSelecionados.length === 0) {
    console.error("Nenhum produto selecionado para gerar etiquetas.");
    return;
  }

  let etiquetas = '';

  for (let produto of produtosSelecionados) {
    const { codigoPeca, descricao, tamanho, marca, valorVenda } = produto;

    const etiqueta = await gerarConteudoEtiqueta({
      codigo: codigoPeca,
      descricao,
      tamanho,
      marca,
      valorVenda,
    });

    etiquetas += etiqueta;
  }

  // Estilos para garantir a impressão no formato correto
  let style = `
    <style>
        @page {
            size: 5cm 7.5cm;
            margin: 0;
        }
        body {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0;
            padding: 0;
        }
    </style>
  `;

  let janelaImpressao = window.open("", "_blank", "width=800,height=600");
  if (janelaImpressao) {
    janelaImpressao.document.write(`<html><head>${style}</head><body>${etiquetas}</body></html>`);
    janelaImpressao.document.close();
    janelaImpressao.focus();
    setTimeout(() => {
      janelaImpressao.print();
      janelaImpressao.close();
    }, 500);
  } else {
    console.error("A janela de impressão não foi criada. Popup bloqueado?");
  }
};



const GerenciarProdutos = () => {
  const theme = useTheme();
  const { userDetails } = useContext(AuthContext);
  const [produtos, setProdutos] = useState([]);
  const [fornecedores, setFornecedores] = useState([]);
 
  const [busca, setBusca] = useState("");
  const [produtosFiltrados, setProdutosFiltrados] = useState([]);
  const [produtosSelecionados, setProdutosSelecionados] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [produtoEditavel, setProdutoEditavel] = useState(null);
  const [desconto, setDesconto] = useState(0);
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [selectAll, setSelectAll] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [filtro, setFiltro] = useState({ fornecedor: "", categoria: "", marca: "", codigoPeca: "" });
  const [loading, setLoading] = useState(true);
  const [filtroEstoque, setFiltroEstoque] = useState(false);
  const [filtroEstoqueAtivo, setFiltroEstoqueAtivo] = useState(false);
  const [openDevolucao, setOpenDevolucao] = useState(false);
  const [reciboGerado, setReciboGerado] = useState(false);
  const [produtosDevolvidos, setProdutosDevolvidos] = useState([]); // Guarda os produtos devolvidos
  const [ordenacao, setOrdenacao] = useState({ campo: "", direcao: "asc" });



  const [selectedFields, setSelectedFields] = useState({
    categoria: true,
    codigoPeca: true,
    dataEntrada: true,
    descricao: true,
    fornecedor: true,
    id: true,
    imageUrl: true,
    marca: true,
    quantidade: true,
    tamanho: true,
    valorCompra: true,
    valorVenda: true,
  });

  useEffect(() => {
    if (userDetails) {
      carregarProdutosEFornecedores(userDetails, setProdutos, setFornecedores, setCategorias, setMarcas, setLoading);
    }
  }, [userDetails]);


  useEffect(() => {
    const filtrarProdutos = () => {
      let filtrados = produtos.filter(produto => {
        const fornecedorValido = filtro.fornecedor ? produto.fornecedor === filtro.fornecedor : true;
        const categoriaValida = filtro.categoria ? produto.categoria === filtro.categoria : true;
        const marcaValida = filtro.marca ? produto.marca === filtro.marca : true;
        const codigoPecaValido = filtro.codigoPeca ? produto.codigoPeca.includes(filtro.codigoPeca) : true;
        const descricaoValida = produto.descricao.toLowerCase().includes(busca.toLowerCase());

        return fornecedorValido && categoriaValida && marcaValida && codigoPecaValido && descricaoValida;
      });
      setProdutosFiltrados(filtrados);
    };

    filtrarProdutos();
  }, [busca, filtro, produtos]);

  const handleAbrirDevolucao = () => {
    if (produtosSelecionados.length > 0) {
      setOpenDevolucao(true);
    }
  };

  const handleConfirmarDevolucao = async () => {
    if (produtosSelecionados.length === 0) return;

    const produtosParaRecibo = [...produtosSelecionados]; // Salvar os produtos antes de limpar

    for (let produto of produtosSelecionados) {
      try {
        // Referência ao produto no estoque ativo
        const produtoRef = doc(
          db,
          "EMPRESAS",
          userDetails.empresaId,
          "Cadastro de Peças",
          "dados",
          "Estoque Ativo",
          produto.id
        );

        // Atualiza a quantidade para 0 (item retirado do estoque)
        await updateDoc(produtoRef, { quantidade: 0 });

        // Referência ao fornecedor
        const fornecedorRef = doc(
          db,
          "EMPRESAS",
          userDetails.empresaId,
          "Fornecedores",
          produto.fornecedor
        );

        // **Verifica se o fornecedor existe antes de salvar**
        const fornecedorDoc = await getDoc(fornecedorRef);
        if (!fornecedorDoc.exists()) {
          console.error(`⚠️ O fornecedor ${produto.fornecedor} não existe no Firestore!`);
          continue; // Pula para o próximo produto
        }

        // Criar referência à subcoleção "Devolvidos"
        const devolucaoRef = collection(fornecedorRef, "Devolvidos");

        // **Garante que um documento seja criado corretamente**
        await addDoc(devolucaoRef, {
          descricao: produto.descricao,
          codigoPeca: produto.codigoPeca,
          marca: produto.marca,
          categoria: produto.categoria,
          quantidade: produto.quantidade,
          valorCompra: produto.valorCompra,
          dataDevolucao: new Date().toISOString(), // Salva a data da devolução
        });

        console.log(`✅ Produto devolvido: ${produto.descricao} salvo em ${fornecedorRef.path}/Devolvidos`);

      } catch (error) {
        console.error("❌ Erro ao registrar a devolução:", error);
      }
    }

    setProdutos((prev) =>
      prev.map((p) =>
        produtosSelecionados.some((sel) => sel.id === p.id) ? { ...p, quantidade: 0 } : p
      )
    );

    setProdutosDevolvidos(produtosParaRecibo); // Agora os produtos são armazenados para o recibo
    setProdutosSelecionados([]); // Limpa a seleção após salvar os produtos devolvidos
    setReciboGerado(true);
  };

  // Função para gerar o PDF de recibo de devolução
  const gerarReciboDevolucao = () => {
    if (produtosDevolvidos.length === 0) {
      console.error("Nenhum produto para gerar o recibo.");
      return;
    }

    const doc = new jsPDF();

    doc.setFontSize(16);
    doc.text("Recibo de Devolução de Peças", 14, 15);
    doc.setFontSize(10);
    doc.text(`Data: ${new Date().toLocaleDateString("pt-BR")}`, 14, 25);
    doc.text(`Fornecedor: ${produtosDevolvidos[0]?.fornecedor || "N/A"}`, 14, 30);

    const tableData = produtosDevolvidos.map((produto) => [
      produto.codigoPeca,
      produto.descricao,
      produto.marca,
      produto.categoria,
      produto.quantidade,
      
    ]);

    doc.autoTable({
      startY: 35,
      head: [["Código", "Descrição", "Marca", "Categoria", "Qtd", "Assinale"]],
      body: tableData,
    });

    doc.save(`recibo_devolucao_${new Date().getTime()}.pdf`);
  };



  const handleSelecionarProduto = produto => {
    setProdutosSelecionados(prev => {
      const exists = prev.find(p => p.id === produto.id);
      if (exists) {
        return prev.filter(p => p.id !== produto.id);
      } else {
        return [...prev, produto];
      }
    });
  };

  const handleEditarProduto = produto => {
    setProdutoEditavel(produto);
    setModalOpen(true);
  };

  const handleSelecionarTodos = () => {
    if (selectAll) {
      setProdutosSelecionados([]);
    } else {
      setProdutosSelecionados(produtosFiltrados);
    }
    setSelectAll(!selectAll);
  };

  const handleSalvarEdicao = async () => {
    if (!produtoEditavel) return;

    try {
      const produtoRef = doc(db, "EMPRESAS", userDetails.empresaId, "Cadastro de Peças", "dados", "Estoque Ativo", produtoEditavel.id);

      await updateDoc(produtoRef, {
        descricao: produtoEditavel.descricao,
        codigoPeca: produtoEditavel.codigoPeca,
        marca: produtoEditavel.marca,
        categoria: produtoEditavel.categoria,
        valorCompra: produtoEditavel.valorCompra,
        valorVenda: produtoEditavel.valorVenda,
        quantidade: produtoEditavel.quantidade
      });

      setModalOpen(false);
      setProdutoEditavel(null);
      carregarProdutosEFornecedores(userDetails, setProdutos, setFornecedores, setCategorias, setMarcas, setLoading);
    } catch (error) {
      console.error("Erro ao atualizar o produto:", error);
    }
  };


 

  const handleAplicarDesconto = async (percentual, fornecedor = null) => {
    if (!percentual || percentual <= 0) return;

    let produtosParaDescontar = fornecedor
      ? produtos.filter(p => p.fornecedor === fornecedor)
      : produtosSelecionados;

    if (produtosParaDescontar.length === 0) {
      console.error("Nenhum produto selecionado para desconto.");
      return;
    }

    let novosProdutos = [...produtos];

    for (let produto of produtosParaDescontar) {
      let valorVendaAtual = parseFloat(produto.valorVenda) || 0;
      let novoValorVenda = valorVendaAtual * (1 - percentual / 100);
      novoValorVenda = parseFloat(novoValorVenda.toFixed(2)); // 🔹 Garante duas casas decimais

      const produtoRef = doc(
        db, "EMPRESAS", userDetails.empresaId, "Cadastro de Peças", "dados", "Estoque Ativo", produto.id
      );

      await updateDoc(produtoRef, { valorVenda: novoValorVenda });

      // Atualiza localmente sem precisar recarregar os produtos do Firestore
      novosProdutos = novosProdutos.map(p =>
        p.id === produto.id ? { ...p, valorVenda: novoValorVenda } : p
      );
    }

    setProdutos(novosProdutos);
    setDesconto(0); // Reseta o campo de desconto
  };


  const [produtoParaExcluir, setProdutoParaExcluir] = useState(null);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const handleConfirmDelete = (produto = null) => {
    if (produto) {
      setProdutoParaExcluir(produto);
    } else {
      setProdutoParaExcluir(null); // Indica que estamos apagando múltiplos
    }
    setOpenConfirmDelete(true);
  };


  // Função para confirmar e excluir o produto
  const handleExcluirProduto = async () => {
    if (!produtoParaExcluir) return;

    try {
      const produtoRef = doc(
        db,
        "EMPRESAS",
        userDetails.empresaId,
        "Cadastro de Peças",
        "dados",
        "Estoque Ativo",
        produtoParaExcluir.id
      );

      await deleteDoc(produtoRef);

      // Atualiza a lista de produtos no estado
      setProdutos((prevProdutos) =>
        prevProdutos.filter((p) => p.id !== produtoParaExcluir.id)
      );

      setOpenConfirmDelete(false);
      setProdutoParaExcluir(null);
    } catch (error) {
      console.error("Erro ao excluir produto:", error);
    }
  };


  const renderModalConfirmDelete = () => (
    <Modal open={openConfirmDelete} onClose={() => setOpenConfirmDelete(false)}>
      <Box
        sx={{
          padding: 3,
          backgroundColor: "white",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          boxShadow: 24,
          borderRadius: 2,
          textAlign: "center",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Confirmar Exclusão
        </Typography>

        {produtoParaExcluir ? (
          <Typography sx={{ mt: 1, mb: 2 }}>
            Tem certeza que deseja excluir o produto <b>{produtoParaExcluir?.descricao}</b>?
          </Typography>
        ) : (
          <Typography sx={{ mt: 1, mb: 2 }}>
            Tem certeza que deseja excluir <b>{produtosSelecionados.length}</b> produtos?
          </Typography>
        )}

        <Button
          variant="contained"
          color="error"
          onClick={produtoParaExcluir ? handleExcluirProduto : handleExcluirSelecionados}
          sx={{ mr: 2 }}
        >
          Excluir
        </Button>
        <Button variant="outlined" onClick={() => setOpenConfirmDelete(false)}>
          Cancelar
        </Button>
      </Box>
    </Modal>
  );


  
  const handleExportarPDF = () => {
    if (produtosSelecionados.length === 0) return;

    const doc = new jsPDF();
    doc.setFontSize(16);
    doc.text("Relatório de Produtos Selecionados", 14, 15);

    const tableData = produtosSelecionados.map(produto => [
      produto.codigoPeca,
      produto.descricao,
      produto.fornecedor,
      produto.marca,
      produto.categoria,
      produto.quantidade,
      `R$ ${parseFloat(produto.valorVenda || 0).toFixed(2)}`
    
    ]);

    doc.autoTable({
      startY: 25,
      head: [["Código", "Descrição", "Fornecedor", "Marca", "Categoria", "Quantidade", "Valor", "Livre"]],
      body: tableData,
      styles: { fontSize: 10 },
    });

    doc.save("produtos_selecionados.pdf");
  };

  const handleExportarExcel = () => {
    const worksheetData = produtosSelecionados.map(p => {
      const data = {};
      for (const field in selectedFields) {
        if (selectedFields[field]) {
          data[field] = p[field];
        }
      }
      return data;
    });

    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Produtos");
    XLSX.writeFile(workbook, "estoque.xlsx");
    setExportModalOpen(false);
  };
  const handleExcluirSelecionados = async () => {
    if (produtosSelecionados.length === 0) return;

    try {
      // Deleta cada produto no Firestore
      const batchDelete = produtosSelecionados.map(async (produto) => {
        const produtoRef = doc(
          db, 
          "EMPRESAS", 
          userDetails.empresaId, 
          "Cadastro de Peças", 
          "dados", 
          "Estoque Ativo", 
          produto.id
        );
        return deleteDoc(produtoRef);
      });

      await Promise.all(batchDelete); // Aguarda todas as exclusões serem concluídas

      // Remove os produtos deletados do estado
      setProdutos(prevProdutos =>
        prevProdutos.filter(p => !produtosSelecionados.some(sel => sel.id === p.id))
      );

      setProdutosSelecionados([]); // Limpa a seleção
      setOpenConfirmDelete(false);
    } catch (error) {
      console.error("Erro ao excluir produtos:", error);
    }
  };
  useEffect(() => {
    aplicarFiltros();
  }, [filtroEstoque, ordenacao, produtos]);

  const ordenarProdutos = (produtos, campo, direcao) => {
    return [...produtos].sort((a, b) => {
      if (campo === "valorVenda" || campo === "quantidade") {
        return direcao === "asc"
          ? (parseFloat(a[campo]) || 0) - (parseFloat(b[campo]) || 0)
          : (parseFloat(b[campo]) || 0) - (parseFloat(a[campo]) || 0);
      }
      return direcao === "asc"
        ? String(a[campo]).localeCompare(String(b[campo]))
        : String(b[campo]).localeCompare(String(a[campo]));
    });
  };

  const aplicarFiltros = () => {
    let filtrados = [...produtos];

    // Aplica todos os filtros combinados
    if (filtro.fornecedor) {
      filtrados = filtrados.filter(p => p.fornecedor === filtro.fornecedor);
    }
    if (filtro.categoria) {
      filtrados = filtrados.filter(p => p.categoria === filtro.categoria);
    }
    if (filtro.marca) {
      filtrados = filtrados.filter(p => p.marca === filtro.marca);
    }
    if (filtro.codigoPeca) {
      filtrados = filtrados.filter(p => p.codigoPeca.includes(filtro.codigoPeca));
    }
    if (busca) {
      filtrados = filtrados.filter(p => p.descricao.toLowerCase().includes(busca.toLowerCase()));
    }

    if (filtroEstoqueAtivo) {
      filtrados = filtrados.filter(p => parseFloat(p.quantidade) > 0);
    }

    // Aplica ordenação
    if (ordenacao.campo) {
      filtrados = ordenarProdutos(filtrados, ordenacao.campo, ordenacao.direcao);
    }

    setProdutosFiltrados(filtrados);
  };


  const renderModalExport = () => (
    <Modal open={exportModalOpen} onClose={() => setExportModalOpen(false)}>
      <Card sx={{ width: 400, margin: "auto", mt: 10, p: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Como você deseja exportar?
          </Typography>

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={handleExportarExcel}
              >
                Exportar para Excel
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                color="success"
                onClick={handleExportarPDF}
              >
                Exportar para PDF
              </Button>
            </Grid>
          </Grid>

          <Typography variant="body2" sx={{ fontStyle: "italic", color: "gray" }}>
            {produtosSelecionados.length} produto(s) selecionado(s) para exportação.
          </Typography>
        </CardContent>
      </Card>
    </Modal>
  );


  const renderModalEditarProduto = () => (
    <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
      <Box
        sx={{
          backgroundColor: 'white',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          boxShadow: 24,
          borderRadius: 2,
          p: 4
        }}
      >
        {/* Cabeçalho do Modal */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>Editar Produto</Typography>
          <IconButton onClick={() => setModalOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Formulário */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Descrição"
              value={produtoEditavel?.descricao || ""}
              onChange={(e) => setProdutoEditavel({ ...produtoEditavel, descricao: e.target.value })}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Código da Peça"
              value={produtoEditavel?.codigoPeca || ""}
              onChange={(e) => setProdutoEditavel({ ...produtoEditavel, codigoPeca: e.target.value })}
            />
          </Grid>

          {/* Select para Categoria */}
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Categoria</InputLabel>
              <Select
                value={produtoEditavel?.categoria || ""}
                onChange={(e) => setProdutoEditavel({ ...produtoEditavel, categoria: e.target.value })}
              >
                <MenuItem value=""><em>Selecione</em></MenuItem>
                {categorias
                  .slice() // Criando uma cópia do array original para não mutar diretamente
                  .sort((a, b) => a.localeCompare(b, "pt-BR")) // Ordenação alfabética considerando acentos
                  .map((categoria, index) => (
                    <MenuItem key={index} value={categoria}>{categoria}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Select para Marca */}
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Marca</InputLabel>
              <Select
                value={produtoEditavel?.marca || ""}
                onChange={(e) => setProdutoEditavel({ ...produtoEditavel, marca: e.target.value })}
              >
                <MenuItem value=""><em>Selecione</em></MenuItem>
                {marcas
                  .slice() // Criando uma cópia do array original
                  .sort((a, b) => a.localeCompare(b, "pt-BR")) // Ordenação alfabética
                  .map((marca, index) => (
                    <MenuItem key={index} value={marca}>{marca}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>


          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Quantidade"
              type="number"
              value={produtoEditavel?.quantidade || ""}
              onChange={(e) => setProdutoEditavel({ ...produtoEditavel, quantidade: parseInt(e.target.value, 10) })}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Valor de Compra"
              type="number"
              InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
              }}
              value={produtoEditavel?.valorCompra || ""}
              onChange={(e) => setProdutoEditavel({ ...produtoEditavel, valorCompra: parseFloat(e.target.value) })}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Valor de Venda"
              type="number"
              InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
              }}
              value={produtoEditavel?.valorVenda || ""}
              onChange={(e) => setProdutoEditavel({ ...produtoEditavel, valorVenda: parseFloat(e.target.value) })}
            />
          </Grid>
        </Grid>

        {/* Botões de Ação */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          <Button onClick={() => setModalOpen(false)} sx={{ mr: 2 }} color="secondary" variant="outlined">
            Cancelar
          </Button>
          <Button onClick={handleSalvarEdicao} color="primary" variant="contained">
            Salvar
          </Button>
        </Box>
      </Box>
    </Modal>
  );



  const Row = ({ index, style }) => {
    const produto = produtosFiltrados[index];
    const formatCurrency = (value) => {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      }).format(value);
    };
    

    return (
      <div style={{ ...style, padding: "8px 0" }}>
        <Grid container spacing={2} alignItems="center" sx={{ padding: 1, borderBottom: "1px solid #ddd" }}>

          {/* Checkbox de Seleção */}
          <Grid item xs={1}>
            <Checkbox
              checked={produtosSelecionados.some(p => p.id === produto.id)}
              onChange={() => handleSelecionarProduto(produto)}
            />
          </Grid>

          {/* Descrição com fonte menor e quebra de linha automática */}
          <Grid item xs={3} sx={{ maxHeight: 50, overflow: "hidden" }}>
            <Tooltip title={produto.descricao} arrow>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "0.8rem", // Reduzindo o tamanho da fonte
                  fontWeight: "bold",
                  whiteSpace: "normal", // Permitir quebras de linha
                  wordBreak: "break-word", // Forçar quebra quando necessário
                  lineHeight: 1.2, // Reduzindo o espaçamento entre linhas
                  maxHeight: 50, // Limitando a altura para evitar invasão de espaço
                  overflow: "hidden", // Garantindo que não invada outros elementos
                }}
              >
                {produto.descricao}
              </Typography>
            </Tooltip>
          </Grid>

          {/* Fornecedor */}
          <Grid item xs={2.5}>
            <Typography variant="body2" color="text.secondary">
              {produto.fornecedor}
            </Typography>
          </Grid>

          {/* Código da Peça */}
          <Grid item xs={1}>
            <Typography variant="body2" sx={{ fontFamily: "monospace", fontWeight: "bold" }}>
              {produto.codigoPeca}
            </Typography>
          </Grid>

          {/* Valor de Venda Formatado */}
          <Grid item xs={2}>
            <Typography variant="body2" color="primary" sx={{ fontWeight: "bold" }}>
              {typeof produto.valorVenda === "number"
                ? `R$ ${produto.valorVenda.toFixed(2)}`
                : `R$ ${parseFloat(produto.valorVenda || 0).toFixed(2)}`}
            </Typography>
          </Grid>

          {/* Quantidade */}
          <Grid item xs={1}>
            <Typography variant="body2">{produto.quantidade}</Typography>
          </Grid>

          {/* Ações: Editar e Excluir */}
          <Grid item xs={1} sx={{ textAlign: "center" }}>
            <IconButton color="primary" onClick={() => handleEditarProduto(produto)} size="small">
              <EditIcon />
            </IconButton>
            <IconButton color="secondary" onClick={() => handleConfirmDelete(produto)} size="small">
              <DeleteIcon />
            </IconButton>

          </Grid>

        </Grid>
      </div>
      
    );
  };
      return (
          <Container
            maxWidth="xl"
            style={{
              marginTop: "20px",
              paddingLeft: isMdUp ? "230px" : "16px",
              paddingRight: "16px",
              maxWidth: isMdUp ? "calc(110% - 240px)" : "100%",
            }}
          >
          <Paper elevation={3} sx={{ p: 3, borderRadius: 2, minHeight: "50%" }}>
            <Typography variant="h5" sx={{ mb: 3, textAlign: "center", fontWeight: "bold" }}>
              Todos os Produtos
            </Typography>

            {/* Barra de Ações - Ajuste de Espaçamento e Organização */}
            <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ mb: 3 }}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  type="number"
                  label="Desconto (%)"
                  value={desconto}
                  onChange={(e) => setDesconto(e.target.value)}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  startIcon={<LocalOfferIcon />}
                  onClick={() => handleAplicarDesconto(desconto)}
                  sx={{ height: 45 }}
                >
                  Aplicar Desconto
                </Button>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  startIcon={<PrintIcon />}
                  onClick={() => gerarEtiquetasMultiplas(produtosSelecionados)}
                  disabled={produtosSelecionados.length === 0}
                  sx={{ height: 45 }}
                >
                  Gerar Etiquetas
                </Button>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Button
                  fullWidth
                  variant="contained"
                  color="warning"
                  startIcon={<CloudDownloadIcon />}
                  onClick={() => setExportModalOpen(true)}
                  sx={{ height: 45 }}
                  disabled={produtosSelecionados.length === 0}
                >
                  Exportar Dados
                </Button>
              </Grid>

            </Grid>
            

            {/* Filtros e Resumo - Melhor Alinhamento */}
            <Grid container spacing={2} alignItems="center">
              {/* Filtros */}
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <InputLabel></InputLabel>
                  <Select
                    value={filtro.fornecedor}
                    onChange={(e) => setFiltro({ ...filtro, fornecedor: e.target.value })}
                    displayEmpty
                  >
                    <MenuItem value=""><em>Fornecedor</em></MenuItem>
                    {fornecedores.map((fornecedor, index) => (
                      <MenuItem key={index} value={fornecedor}>{fornecedor}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  fullWidth
                  label="Descrição"
                  value={busca}
                  onChange={(e) => setBusca(e.target.value)}
                  inputProps={{ style: { textTransform: 'uppercase' } }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <InputLabel>Categoria</InputLabel>
                  <Select
                    value={filtro.categoria || ""}
                    onChange={(e) => setFiltro({ ...filtro, categoria: e.target.value })}
                  >
                    <MenuItem value=""><em>Selecione</em></MenuItem>
                    {categorias
                      .slice() // Faz uma cópia para evitar mutação do estado original
                      .filter(Boolean) // Remove valores nulos ou vazios
                      .sort((a, b) => a.localeCompare(b, "pt-BR")) // Ordenação correta
                      .map((categoria, index) => (
                        <MenuItem key={index} value={categoria}>{categoria}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <InputLabel>Marca</InputLabel>
                  <Select
                    value={filtro.marca || ""}
                    onChange={(e) => setFiltro({ ...filtro, marca: e.target.value })}
                  >
                    <MenuItem value=""><em>Selecione</em></MenuItem>
                    {marcas
                      .slice() // Evita mutação do array original
                      .filter(Boolean) // Remove valores nulos ou vazios
                      .sort((a, b) => a.localeCompare(b, "pt-BR")) // Ordenação correta
                      .map((marca, index) => (
                        <MenuItem key={index} value={marca}>{marca}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>



              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  fullWidth
                  label="Código da Peça"
                  value={filtro.codigoPeca}
                  onChange={(e) => setFiltro({ ...filtro, codigoPeca: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Button
                  fullWidth
                  variant="contained"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={handleAbrirDevolucao}
                  disabled={produtosSelecionados.length === 0} // Desativa se nenhum produto for selecionado
                  sx={{ height: 45 }}
                >
                  Devolver Produtos
                </Button>
              </Grid>

            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Button
                fullWidth
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => handleConfirmDelete()} // Chama a função de exclusão múltipla
                disabled={produtosSelecionados.length === 0} // Só ativa se houver produtos selecionados
                sx={{ height: 45 }}
              >
                Excluir Selecionados
              </Button>
            </Grid>

            {/* Resumo - Cards Pequenos e Bem Posicionados */}
            <Grid container spacing={2} sx={{ mt: 3 }}>
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    bgcolor: "#1E88E5",
                    color: "#fff",
                    p: 2,
                    textAlign: "center",
                    borderRadius: 2,
                  }}
                >
                  <Typography variant="caption" sx={{ fontWeight: "bold" }}>Total de Produtos</Typography>
                  <Typography variant="h6">{produtos.length}</Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    bgcolor: "#43A047",
                    color: "#fff",
                    p: 2,
                    textAlign: "center",
                    borderRadius: 2,
                  }}
                >
                  <Typography variant="caption" sx={{ fontWeight: "bold" }}>Valor Total de Venda</Typography>
                  <Typography variant="h6">
                    {produtos.reduce((total, produto) => {
                      const valorVenda = parseFloat(produto.valorVenda) || 0;
                      const quantidade = parseFloat(produto.quantidade) || 0;
                      return total + (valorVenda * quantidade);
                    }, 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    bgcolor: "#FDD835",
                    color: "#fff",
                    p: 2,
                    textAlign: "center",
                    borderRadius: 2,
                  }}
                >
                  <Typography variant="caption" sx={{ fontWeight: "bold" }}>Quantidade em Estoque</Typography>
                  <Typography variant="h6">
                    {produtos.reduce((total, produto) => total + Number(produto.quantidade), 0)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Paper>
       

            {renderModalEditarProduto()}
            {renderModalExport()}
            {renderModalConfirmDelete()} {/* 🔹 Agora o modal de exclusão é chamado corretamente */}




              {/* Lista Virtualizada de Produtos */}
              <Box sx={{ mb: 4, maxWidth: "100%", overflowX: "auto" }}>
                <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                  Produtos
                </Typography>

                {/* Cabeçalho da Tabela */}
                <Grid 
                  container 
                  spacing={2} 
                  sx={{ 
                    fontWeight: "bold", 
                    mb: 1, 
                    py: 1, 
                    backgroundColor: "#f5f5f5", 
                    borderRadius: 1, 
                    alignItems: "center",
                  }}
                >
                  {/* Selecionar todos */}
                  <Grid item xs={0.5} sx={{ textAlign: "center" }}>
                    <Checkbox
                      checked={produtosSelecionados.length === produtosFiltrados.length && produtosFiltrados.length > 0}
                      indeterminate={produtosSelecionados.length > 0 && produtosSelecionados.length < produtosFiltrados.length}
                      onChange={handleSelecionarTodos}
                    />
                  </Grid>

                  <Grid item xs={2.5} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>Produto</Typography>
                    <IconButton onClick={() => setOrdenacao({ campo: "descricao", direcao: ordenacao.direcao === "asc" ? "desc" : "asc" })}>
                      <SortIcon sx={{ color: ordenacao.campo === "descricao" ? "primary.main" : "inherit" }} />
                    </IconButton>
                  </Grid>


                  {/* Fornecedor - Ordenação Alfabética */}
                  <Grid item xs={2.5} sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                    <Typography variant="body2">Fornecedor</Typography>
                    <IconButton onClick={() => setOrdenacao({ campo: "fornecedor", direcao: ordenacao.direcao === "asc" ? "desc" : "asc" })}>
                      <SortIcon sx={{ color: ordenacao.campo === "fornecedor" ? "primary.main" : "inherit" }} />
                    </IconButton>
                  </Grid>

                  {/* Código - Ordenação Alfabética */}
                  <Grid item xs={1} sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                    <Typography variant="body2">Código</Typography>
                    <IconButton onClick={() => setOrdenacao({ campo: "codigoPeca", direcao: ordenacao.direcao === "asc" ? "desc" : "asc" })}>
                      <SortIcon sx={{ color: ordenacao.campo === "codigoPeca" ? "primary.main" : "inherit" }} />
                    </IconButton>
                  </Grid>
                  {/* Adiciona um espaço correto para o botão de devolução */}
                  

                  {/* Valor de Venda - Ordenação Numérica */}
                  <Grid item xs={2} sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                    <Typography variant="body2">Valor de Venda</Typography>
                    <IconButton onClick={() => setOrdenacao({ campo: "valorVenda", direcao: ordenacao.direcao === "asc" ? "desc" : "asc" })}>
                      <SortIcon sx={{ color: ordenacao.campo === "valorVenda" ? "primary.main" : "inherit" }} />
                    </IconButton>
                  </Grid>

                  {/* Estoque - Filtro de Estoque > 0 + Ordenação Numérica */}
                  <Grid item xs={1} sx={{ display: "flex", alignItems: "center" }}>
                    <Tooltip title="Filtrar produtos com estoque maior que 0">
                      <IconButton onClick={() => setFiltroEstoqueAtivo(!filtroEstoqueAtivo)} color={filtroEstoqueAtivo ? "primary" : "default"}>
                        <FilterAltIcon />
                      </IconButton>
                    </Tooltip>
                    <Typography
                      variant="body2"
                      onClick={() => setOrdenacao({ campo: "quantidade", direcao: ordenacao.direcao === "asc" ? "desc" : "asc" })}
                      sx={{ cursor: "pointer", ml: 1, display: "flex", alignItems: "center" }}
                    >
                      Estoque
                      <IconButton>
                        <SortIcon sx={{ color: ordenacao.campo === "quantidade" ? "primary.main" : "inherit" }} />
                      </IconButton>
                    </Typography>
                  </Grid>

                  <Grid item xs={1} sx={{ textAlign: "center" }}>
                    <Typography variant="body2">Ações</Typography>
                  </Grid>
                </Grid>

                <Dialog open={openDevolucao} onClose={() => setOpenDevolucao(false)}>
                  <DialogTitle>Confirmar Devolução</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Você deseja devolver {produtosSelecionados.length} produtos ao fornecedor?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenDevolucao(false)}>Cancelar</Button>
                    <Button onClick={handleConfirmarDevolucao} color="error">Dar Baixa</Button>
                  </DialogActions>
                </Dialog>
                <>
                  {/* Dialogo de Confirmação de Devolução */}
                  <Dialog open={openDevolucao} onClose={() => setOpenDevolucao(false)}>
                    <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      Confirmar Devolução
                      <IconButton onClick={() => setOpenDevolucao(false)}>
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Você deseja devolver {produtosSelecionados.length} produtos ao fornecedor?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setOpenDevolucao(false)}>Cancelar</Button>
                      <Button onClick={handleConfirmarDevolucao} color="error">Confirmar</Button>
                    </DialogActions>
                  </Dialog>

                  {/* Dialogo para Gerar Recibo */}
                  <Dialog open={reciboGerado} onClose={() => setReciboGerado(false)}>
                    <DialogTitle>Recibo de Devolução</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        A devolução foi registrada com sucesso. Deseja gerar o recibo em PDF?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setReciboGerado(false)}>Fechar</Button>
                      <Button onClick={() => gerarRelatorioDevolucao(produtosSelecionados)} color="primary" variant="contained">
                        Gerar PDF
                      </Button>

                    </DialogActions>
                  </Dialog>
                </>

                {/* Lista Virtualizada */}
                <ListVirtualized 
                  height={400} 
                  itemCount={produtosFiltrados.length} 
                  itemSize={60} 
                  width="100%" 
                  style={{ border: "1px solid #ddd", borderRadius: "4px" }}
                >
                  {Row}
                </ListVirtualized>
              </Box>


    {renderModalEditarProduto()}
    {renderModalExport()}
  </Container>
);
};

export default GerenciarProdutos;
