import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  Container,
  Card,
  CardContent,
  Typography,
  InputAdornment,
  InputLabel,
  Grid,
  Snackbar,
  useMediaQuery,
  FormControl,
  List,
  ListItem,
  ListItemSecondaryAction,
  Divider,
  ListItemText,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Autocomplete
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { getFirestore, collection, doc, setDoc, getDocs, getDoc, deleteDoc, writeBatch } from "firebase/firestore";
import Tooltip from "@mui/material/Tooltip";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const Orcamento = () => {
  const theme = useTheme();
  const { userDetails } = useContext(AuthContext);
  const empresaId = userDetails.empresaId;
  const [fornecedores, setFornecedores] = useState([]);
  const [fornecedor, setFornecedor] = useState("");
  const [descricao, setDescricao] = useState("");
  const [tamanho, setTamanho] = useState("");
  const [marca, setMarca] = useState("");
  const [categoria, setCategoria] = useState("");
  const [dataEntrada, setDataEntrada] = useState("");
  const [valorVenda, setValorVenda] = useState("");
  const [quantidade, setQuantidade] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [isFornecedorCora, setIsFornecedorCora] = useState(false);
  const [valorCompra, setValorCompra] = useState("");
  const [orcamento, setOrcamento] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [dialogValues, setDialogValues] = useState({});
  const [categorias, setCategorias] = useState([]);
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [openLoadingDialog, setOpenLoadingDialog] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [savedOrcamentoDoc, setSavedOrcamentoDoc] = useState(null);
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [marcas, setMarcas] = useState([]); // Novo estado para marcas

  useEffect(() => {
    // 🔹 Recupera os dados salvos no Local Storage ao carregar a página
    const savedOrcamento = localStorage.getItem("orcamento");

    if (savedOrcamento) {
      try {
        const parsedData = JSON.parse(savedOrcamento);

        // Garante que "orcamento" é um array válido e "fornecedor" está definido
        setOrcamento(Array.isArray(parsedData.itens) ? parsedData.itens : []);
        setFornecedor(parsedData.fornecedor || "");
      } catch (error) {
        console.error("Erro ao carregar orçamento do Local Storage:", error);
        setOrcamento([]); // Evita erros se os dados estiverem corrompidos
      }
    }
  }, []);

  useEffect(() => {
    // 🔹 Atualiza o Local Storage sempre que a lista do orçamento mudar
    if (orcamento.length > 0 || fornecedor) {
      localStorage.setItem("orcamento", JSON.stringify({ fornecedor, itens: orcamento }));
    }
  }, [orcamento, fornecedor]);

  useEffect(() => {
    const fetchFornecedores = async () => {
      const querySnapshot = await getDocs(
        collection(getFirestore(), "EMPRESAS", empresaId, "Cadastro de fornecedores")
      );

      const fornecedoresData = querySnapshot.docs.map(doc => ({
        id: doc.id, 
        nome: doc.data().nome // Aqui pegamos o nome correto do fornecedor
      }));

      setFornecedores(fornecedoresData);
    };

    const fetchCategoriasEMarcas = async () => {
      try {
        const empresaDocRef = doc(getFirestore(), "EMPRESAS", empresaId);
        const empresaDoc = await getDoc(empresaDocRef);
        if (empresaDoc.exists()) {
          setCategorias(empresaDoc.data().Categorias || []);
          setMarcas(empresaDoc.data().Marcas || []);
        } else {
          console.error("Documento da empresa não encontrado.");
        }
      } catch (error) {
        console.error("Erro ao buscar categorias e marcas:", error);
      }
    };

    if (empresaId) {
      fetchFornecedores();
      fetchCategoriasEMarcas();
    }
  }, [empresaId]);

  const handleFornecedorChange = (e) => {
    const fornecedorId = e.target.value;
    const fornecedorNome = fornecedores.find(f => f.id === fornecedorId)?.nome || "";

    setFornecedor(fornecedorNome); // Agora armazena o **nome** do fornecedor
    localStorage.setItem("orcamento", JSON.stringify({ fornecedor: fornecedorNome, itens: orcamento }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const today = new Date().toISOString().split("T")[0];

    const pecaId = gerarPecaId(fornecedor);

    const peca = {
      pecaId,
      descricao,
      tamanho,
      marca,
      categoria,
      dataEntrada: dataEntrada || today,
      valorVenda, // mantém como string '1.234,22'
      status: "Pendente",
      quantidade: parseInt(quantidade, 10) || 1,
      imageUrl: uploadedImageUrl,
      fornecedor: isFornecedorCora ? "Fornecedor Próprio" : fornecedor,
      valorCompra: isFornecedorCora ? valorCompra : "0" // também como string
    };

    const novoOrcamento = [...orcamento, peca];
    setOrcamento(novoOrcamento);

    // 🔹 Atualiza Local Storage com strings formatadas
    localStorage.setItem("orcamento", JSON.stringify({ fornecedor, itens: novoOrcamento }));

    setSnackbarMessage(`Peça ${pecaId} adicionada ao orçamento.`);
    setOpenSnackbar(true);
    limparCampos();
  };



  const gerarPecaId = (fornecedorNome) => {
    if (!fornecedorNome) return `UNK${Math.floor(10000 + Math.random() * 90000)}`;

    // Remove espaços e caracteres especiais do nome do fornecedor
    const iniciais = fornecedorNome
      .split(" ")
      .map(word => word.charAt(0)) // Pega a primeira letra de cada palavra
      .join("")
      .substring(0, 3) // Pega até 3 caracteres iniciais
      .toLowerCase();

    const numeroAleatorio = Math.floor(10000 + Math.random() * 90000); // Gera número de 5 dígitos

    return `${iniciais}${numeroAleatorio}`;
  };


  const handleImageUpload = async e => {
    const file = e.target.files[0];
    if (!file) {
      setImagePreview(null);
      setUploadedImageUrl("");
      return;
    }
    const reader = new FileReader();
    reader.onloadend = async () => {
      setImagePreview(reader.result);

      const storage = getStorage();
      const storageRef = ref(storage, `${empresaId}/imagens/${file.name}`);
      try {
        const snapshot = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        setUploadedImageUrl(downloadURL);
      } catch (error) {
        console.error("Erro ao enviar a imagem:", error);
      }
    };
    reader.readAsDataURL(file);
  };

  const showLoadingDialog = message => {
    setLoadingMessage(message);
    setOpenLoadingDialog(true);
  };

  const closeLoadingDialog = () => {
    setOpenLoadingDialog(false);
  };

  const handleSalvarOrcamento = async () => {
    showLoadingDialog("Criando Orçamento...");
    let idOrcamento;

    try {
      const db = getFirestore();
      const numeroOrcamento = gerarNumeroOrcamento();
      const dataAtual = new Date();
      idOrcamento = `${numeroOrcamento}`;

      const orcamentoDoc = {
        fornecedor: fornecedor || "Fornecedor Próprio",
        data: dataAtual.toISOString(),
        pecas: orcamento.map((peca) => {
          // 🔍 Converte corretamente valores monetários
          const valorVendaFormatado = parseFloat(
            String(peca.valorVenda || "0")
              .replace(/\./g, "") // remove pontos de milhar
              .replace(",", ".")  // substitui vírgula por ponto
          );

          const valorCompraFormatado = parseFloat(
            String(peca.valorCompra || "0")
              .replace(/\./g, "")
              .replace(",", ".")
          );

          return {
            ...peca,
            fornecedor: fornecedor,
            pecaId: peca.pecaId || gerarPecaId(fornecedor),
            dataEntrada: peca.dataEntrada || dataAtual.toISOString().split("T")[0],
            valorVenda: isNaN(valorVendaFormatado) ? 0 : Number(valorVendaFormatado.toFixed(2)), // 🔥 Garante número com duas casas decimais
            valorCompra: isNaN(valorCompraFormatado) ? 0 : Number(valorCompraFormatado.toFixed(2)),
          };
        }),
        numero: numeroOrcamento,
        id: idOrcamento,
        status: "Pendente",
      };

      // 🔄 Salva o documento principal do orçamento
      await setDoc(doc(db, "EMPRESAS", empresaId, "Lista de Orçamentos", idOrcamento), orcamentoDoc);

      // 🔁 Salva as peças separadamente (caso use subcoleções)
      const salvarEmLotes = async (pecas) => {
        const loteTamanho = 20;
        for (let i = 0; i < pecas.length; i += loteTamanho) {
          const batch = writeBatch(db);
          const loteAtual = pecas.slice(i, i + loteTamanho);

          for (const peca of loteAtual) {
            const pecaRef = doc(db, "EMPRESAS", empresaId, "Lista de Orçamentos", idOrcamento, "Pecas", peca.pecaId);
            batch.set(pecaRef, peca);
          }

          await batch.commit();
        }
      };

      await salvarEmLotes(orcamentoDoc.pecas);

      // ✅ Gera PDF
      showLoadingDialog("Criando PDF...");
      const pdfBlob = await gerarPDF(orcamentoDoc);
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfUrl(pdfUrl);

      // ✅ Envia e-mail com PDF
      showLoadingDialog("Enviando Email de orçamento ao fornecedor...");
      await enviarEmailComPDF(fornecedor, pdfBlob);

      setSnackbarMessage("Orçamento salvo e email enviado com sucesso.");
      setOpenSnackbar(true);
      setOrcamento([]);
      setFornecedor("");
      setImagePreview(null);
      setOpenPdfModal(true);

    } catch (error) {
      console.error("Erro ao salvar orçamento:", error);
      if (idOrcamento) {
        await deleteDoc(doc(getFirestore(), "EMPRESAS", empresaId, "Lista de Orçamentos", idOrcamento));
      }
      setSnackbarMessage("Erro ao salvar orçamento: " + error.message);
      setOpenSnackbar(true);
    } finally {
      closeLoadingDialog();
    }
  };







  const gerarNumeroOrcamento = () => {
    const inicialFornecedor = fornecedor.slice(0, 1, 2);
    const numeroAleatorio = Math.floor(1000 + Math.random() * 9000);
    return `${inicialFornecedor}${numeroAleatorio}`;
  };

  const buscarPorcentagemFornecedor = async (fornecedorId) => {
    if (!fornecedorId || fornecedorId === "Fornecedor Próprio") return 40; // Padrão 40% se for próprio

    try {
      const fornecedorRef = doc(getFirestore(), "EMPRESAS", empresaId, "Cadastro de fornecedores", fornecedorId);
      const fornecedorSnap = await getDoc(fornecedorRef);

      if (fornecedorSnap.exists()) {
        const fornecedorData = fornecedorSnap.data();
        return fornecedorData.porcentagem ?? 40; // Se não houver campo porcentagem, assume 50%
      } else {
        console.warn(`Fornecedor ${fornecedorId} não encontrado!`);
        return 40;
      }
    } catch (error) {
      console.error("Erro ao buscar porcentagem do fornecedor:", error);
      return 40;
    }
  };

  const gerarPDF = async (orcamentoDoc) => {
    return new Promise(async (resolve, reject) => {
      const db = getFirestore();
      const jsPdfDoc = new jsPDF({ orientation: "landscape" });

      jsPdfDoc.setFontSize(18);
      jsPdfDoc.text(`${empresaId}`, 14, 20);
      jsPdfDoc.setFontSize(11);
      jsPdfDoc.text(`Orçamento: ${orcamentoDoc.numero}`, 14, 25);
      jsPdfDoc.text(`Fornecedor: ${orcamentoDoc.fornecedor}`, 14, 30);

      const agora = new Date();
      const dataHora = agora.toLocaleString("pt-BR");
      jsPdfDoc.text(`Data/Hora: ${dataHora}`, 14, 34);

      const colunas = [
        { header: "Item", dataKey: "item" },
        { header: "ID Peça", dataKey: "pecaId" }, 
        { header: "Descrição", dataKey: "descricao" },
        { header: "Qtd", dataKey: "quantidade" },  
        { header: "Tam", dataKey: "tamanho" },
        { header: "Marca", dataKey: "marca" },
        { header: "Fornecedor", dataKey: "fornecedor" },
        { header: "Entrada", dataKey: "dataEntrada" },
        { header: "Valor Peça", dataKey: "valorPeca" },
        { header: "Valor Venda", dataKey: "valorVenda" },
        { header: "Valor Comissão", dataKey: "valorComissao" }
      ];

      const linhas = [];

      for (const [index, peca] of orcamentoDoc.pecas.entries()) {
        // Busca a porcentagem da comissão do fornecedor
        const porcentagemComissao = await buscarPorcentagemFornecedor(peca.fornecedor);

        // Valor unitário da peça
        const valorPeca = parseFloat(peca.valorVenda) || 0;
        // Valor total da venda
        const valorTotalVenda = valorPeca * (peca.quantidade || 1);
        // Comissão calculada corretamente
        const valorComissao = valorTotalVenda * (porcentagemComissao / 100);

        linhas.push({
          item: index + 1,
          pecaId: peca.pecaId || "N/A",
          descricao: peca.descricao,
          quantidade: peca.quantidade,

          tamanho: peca.tamanho,
          marca: peca.marca,
          fornecedor: peca.fornecedor,
          dataEntrada: peca.dataEntrada,
          valorPeca: `R$ ${valorPeca.toFixed(2).replace(".", ",")}`,
          valorVenda: `R$ ${valorTotalVenda.toFixed(2).replace(".", ",")}`,
          valorComissao: `R$ ${valorComissao.toFixed(2).replace(".", ",")}`
        });
      }

      jsPdfDoc.autoTable({
        head: [colunas.map(col => col.header)],
        body: linhas.map(row => colunas.map(col => row[col.dataKey])),
        startY: 40, 
        margin: { horizontal: 10 },
        styles: { fontSize: 9, cellPadding: 3 }, 
        headStyles: { fillColor: [40, 40, 40], textColor: [255, 255, 255] },
        columnStyles: {
          0: { cellWidth: 10 },  
          1: { cellWidth: 18 },  
          2: { cellWidth: 45 },  
          3: { cellWidth: 15 },  
          4: { cellWidth: 15 },  
          6: { cellWidth: 18 },  
          7: { cellWidth: 40 },  
          8: { cellWidth: 20 },  
          9: { cellWidth: 25 },  
          10: { cellWidth: 25 },  
          11: { cellWidth: 30 }  
        },
        didDrawPage: function (data) {
          const pageHeight = jsPdfDoc.internal.pageSize.height;
          jsPdfDoc.setFontSize(11);
          jsPdfDoc.text(
            `Página ${jsPdfDoc.internal.getNumberOfPages()}`,
            data.settings.margin.left,
            pageHeight - 10
          );
        }
      });

      // Total previsto para o fornecedor
      const totalPrevistoRecebimento = linhas.reduce(
        (acc, curr) => acc + parseFloat(curr.valorComissao.replace("R$ ", "").replace(",", ".")),
        0
      );

      const lastY = jsPdfDoc.lastAutoTable.finalY + 10;
      const pageHeight = jsPdfDoc.internal.pageSize.height;

      if (lastY > pageHeight - 30) {
        jsPdfDoc.addPage();
      }

      jsPdfDoc.setFontSize(12);
      jsPdfDoc.text(
        `Total Previsto para o Fornecedor: R$ ${totalPrevistoRecebimento.toFixed(2).replace(".", ",")}`,
        14,
        lastY
      );

      const pdfBlob = jsPdfDoc.output("blob");
      resolve(pdfBlob);
    });
  };







  const enviarEmailComPDF = async (fornecedorNome, pdfBlob) => {
    const fornecedorRef = collection(getFirestore(), "EMPRESAS", empresaId, "Cadastro de fornecedores");

    const querySnapshot = await getDocs(fornecedorRef);
    let fornecedorEmail = "";

    querySnapshot.forEach(doc => {
      if (doc.data().nome === fornecedorNome) {
        fornecedorEmail = doc.data().email;
      }
    });

    if (!fornecedorEmail) {
      setSnackbarMessage("Erro: Fornecedor não possui email cadastrado.");
      setOpenSnackbar(true);
      return;
    }

    const attachmentBase64 = await pdfBlobToBase64(pdfBlob);

    const mailData = {
      to: fornecedorEmail,
      subject: `Orçamento ${empresaId} para o fornecedor ${fornecedorNome}`,
      text: "Segue em anexo o orçamento solicitado.",
      attachment: attachmentBase64,
      filename: `Orcamento_${empresaId}_${fornecedorNome}.pdf`
    };

    try {
      const response = await fetch(
        "https://us-central1-dona-cora.cloudfunctions.net/sendEmailWithPDF",
        {
          method: "POST",
          body: JSON.stringify(mailData),
          headers: { "Content-Type": "application/json" }
        }
      );

      if (!response.ok) {
        throw new Error("Erro ao enviar email.");
      }
    } catch (error) {
      console.error("Erro:", error);
      setSnackbarMessage("Erro ao enviar email: " + error.message);
      setOpenSnackbar(true);
    }
  };

  const pdfBlobToBase64 = blob => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleCurrencyChange = (event, setFunction) => {
    let valor = event.target.value.replace(/\D/g, "");
    valor = (valor / 100).toFixed(2) + "";
    valor = valor.replace(".", ",");
    valor = valor.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
    valor = valor.replace(/(\d)(\d{3}),/g, "$1.$2,");
    setFunction(valor);
  };

  const handleNovoOrcamento = () => {
    setOrcamento([]);
    setFornecedor("");
    setDescricao("");
    setTamanho("");
    setMarca("");
    setCategoria("");
    setDataEntrada("");
    setValorVenda("");
    setQuantidade("");
    setValorCompra("");
    setIsFornecedorCora(false);

    // 🔴 Remove os dados do Local Storage
    localStorage.removeItem("orcamento");
  };


  const handleDeleteItem = (index) => {
    const updatedOrcamento = [...orcamento];
    updatedOrcamento.splice(index, 1);
    setOrcamento(updatedOrcamento);

    // 🔹 Atualizar o Local Storage após remover um item
    localStorage.setItem("orcamento", JSON.stringify({ fornecedor, itens: updatedOrcamento }));
  };

  const handleEditItem = index => {
    setEditIndex(index);
    setDialogValues(orcamento[index]);
    setOpenDialog(true);
  };

  const handleDialogChange = event => {
    const { name, value } = event.target;
    setDialogValues(prev => ({ ...prev, [name]: value }));
  };

  const atualizarItem = () => {
    const novoOrcamento = [...orcamento];
    novoOrcamento[editIndex] = { ...novoOrcamento[editIndex], ...dialogValues };
    setOrcamento(novoOrcamento);
    setOpenDialog(false);
    setDialogValues({});
  };

  const limparCampos = () => {
    setDescricao("");
    setTamanho("");
    setMarca("");
    setCategoria("");
    setDataEntrada("");
    setValorVenda("");
    setQuantidade("");
    setValorCompra("");
    setImagePreview(null);
    setUploadedImageUrl("");
  };

  const handleClosePdfModal = () => {
    setOpenPdfModal(false);
    setPdfUrl("");
    setSavedOrcamentoDoc(null);
  };
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [dialogType, setDialogType] = useState(""); // "marca" ou "categoria"
  const [dialogValue, setDialogValue] = useState("");

  const handleBlurMarcaCategoria = (type, value) => {
    if (!value.trim()) return;

    const listaAtual = type === "marca" ? marcas : categorias;

    if (!listaAtual.includes(value)) {
      setDialogType(type);
      setDialogValue(value);
      setOpenConfirmDialog(true);
    }
  };

  const handleConfirmSave = async () => {
    if (!dialogValue.trim()) return;

    const db = getFirestore();
    const docRef = doc(db, "EMPRESAS", empresaId);
    const fieldToUpdate = dialogType === "marca" ? "Marcas" : "Categorias";

    // Converte para caixa alta, remove espaços extras, elimina duplicatas e ordena
    const updatedList = [...(dialogType === "marca" ? marcas : categorias), dialogValue.toUpperCase()]
      .map((item) => item.trim().toUpperCase()) // Garante que todos os itens estejam em maiúsculas
      .filter((item, index, self) => self.indexOf(item) === index) // Remove duplicatas
      .sort(); // Ordena alfabeticamente

    try {
      await setDoc(docRef, { [fieldToUpdate]: updatedList }, { merge: true });

      if (dialogType === "marca") {
        setMarcas(updatedList);
      } else {
        setCategorias(updatedList);
      }

      setSnackbarMessage(`${dialogType === "marca" ? "Marca" : "Categoria"} adicionada com sucesso!`);
      setOpenSnackbar(true);
    } catch (error) {
      console.error(`Erro ao adicionar ${dialogType}:`, error);
    }

    setOpenConfirmDialog(false);
  };

  return (
    <Container
      maxWidth="xl"
      style={{
        marginTop: "20px",
        paddingLeft: isMdUp ? "230px" : "16px",
        paddingRight: "16px",
        maxWidth: isMdUp ? "calc(110% - 240px)" : "100%",
      }}
    >
      <Card sx={{ mb: 3, borderRadius: "15px", boxShadow: "0px 4px 12px rgba(0,0,0,0.1)", p: 3 }}>
        <CardContent>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 3, color: "#333" }}>
            Cadastro de Orçamento
          </Typography>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center">
              {/* Botão de Upload de Imagem (Compacto) */}
              <Grid item xs={12} sm={6} md={4} lg={1} display="flex" justifyContent="center">
                <Tooltip title="Fazer upload ou tirar foto">
                  <label htmlFor="image-upload">
                    <input accept="image/*" style={{ display: "none" }} id="image-upload" type="file" capture="environment" onChange={handleImageUpload} />
                    <IconButton color="primary" component="span">
                      <PhotoCamera fontSize="large" />
                    </IconButton>
                  </label>
                </Tooltip>
              </Grid>

              {/* Fornecedor */}
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormControl fullWidth>
                  <InputLabel>Fornecedor</InputLabel>
                  <Select value={fornecedor} onChange={(e) => setFornecedor(e.target.value)} sx={{ bgcolor: "white" }}>
                    <MenuItem value="" disabled>Escolher Fornecedor</MenuItem>
                    {fornecedores.map((forn) => (
                      <MenuItem key={forn.id} value={forn.id}>{forn.nome}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Descrição */}
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField fullWidth label="Descrição do Produto" value={descricao} onChange={(e) => setDescricao(e.target.value)} />
              </Grid>



              {/* Marca e Categoria - Alinhamento Corrigido */}
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Autocomplete
                  freeSolo
                  options={marcas}
                  value={marca}
                  onInputChange={(event, newInputValue) => setMarca(newInputValue.toUpperCase())}
                  onBlur={() => handleBlurMarcaCategoria("marca", marca.toUpperCase())}
                  renderInput={(params) => <TextField {...params} label="Marca" fullWidth />}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Autocomplete
                  freeSolo
                  options={categorias}
                  value={categoria}
                  onInputChange={(event, newInputValue) => setCategoria(newInputValue.toUpperCase())}
                  onBlur={() => handleBlurMarcaCategoria("categoria", categoria.toUpperCase())}
                  renderInput={(params) => <TextField {...params} label="Categoria" fullWidth />}
                />
              </Grid>

              {/* Quantidade e Tamanho */}
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField fullWidth label="Quantidade" type="number" value={quantidade} onChange={(e) => setQuantidade(e.target.value)} />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField fullWidth label="Tamanho" value={tamanho} onChange={(e) => setTamanho(e.target.value)} />
              </Grid>

              {/* Data de Entrada e Valor da Peça */}
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  fullWidth
                  label="Data de Entrada (Gera atual se não preenchido)"
                  type="date"
                  value={dataEntrada}
                  onChange={(e) => setDataEntrada(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  label="Valor da Peça (Venda)"
                  type="text"
                  value={valorVenda}
                  onChange={(e) => handleCurrencyChange(e, setValorVenda)}
                  fullWidth
                  InputProps={{ startAdornment: <InputAdornment position="start">R$</InputAdornment> }}
                />
              </Grid>

              {/* Botões - Melhor alinhamento */}
              <Grid container justifyContent="flex-end" spacing={2} sx={{ mt: 3 }}>
                <Grid item>
                  <Button type="submit" variant="contained" color="primary">
                    Adicionar Peça ao Orçamento
                  </Button>
                </Grid>
                {orcamento.length > 0 && (
                  <Grid item>
                    <Button variant="contained" color="secondary" onClick={handleSalvarOrcamento}>
                      Salvar Orçamento
                    </Button>
                  </Grid>
                )}

                <Grid item>
                  <Button variant="outlined" color="primary" onClick={handleNovoOrcamento}>
                    Novo Orçamento
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>

      <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
        <DialogTitle>Adicionar nova {dialogType === "marca" ? "marca" : "categoria"}?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            A {dialogType} "<strong>{dialogValue}</strong>" não existe no sistema. Deseja adicioná-la?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)} color="secondary">Cancelar</Button>
          <Button onClick={handleConfirmSave} color="primary">Adicionar</Button>
        </DialogActions>
      </Dialog>


      <Card
        style={{
          marginTop: "-10px",
          borderRadius: "15px",
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)"
        }}
      >
        <CardContent>
          <Typography
            variant="h5"
            component="h2"
            style={{
              marginBottom: "20px",
              fontWeight: "bold",
              fontFamily: "Poppins, sans-serif"
            }}
          >
            Itens do Orçamento
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Itens do Orçamento" />
              <Typography
                style={{
                  marginRight: "auto",
                  textAlign: "right",
                  fontWeight: "bold"
                }}
              >
                Ações
              </Typography>
            </ListItem>
            <Divider component="li" />
            {orcamento.map((peca, index) => (
              <React.Fragment key={index}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={peca.descricao}
                    secondary={`Quantidade: ${peca.quantidade} | Venda: R$${peca.valorVenda} | Marca: ${peca.marca} | Tamanho: ${peca.tamanho} | Categoria: ${peca.categoria} | Entrada: ${peca.dataEntrada}`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDeleteItem(index)}
                      style={{ color: "red" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => handleEditItem(index)}
                      style={{ color: "green" }}
                    >
                      <EditIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                {index < orcamento.length - 1 && <Divider component="li" />}
              </React.Fragment>
            ))}
          </List>
        </CardContent>
      </Card>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />

        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Editar Item</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Atualize os campos necessários e clique em salvar.
            </DialogContentText>

            {/* Descrição */}
            <TextField
              margin="dense"
              name="descricao"
              label="Descrição"
              fullWidth
              value={dialogValues.descricao || ""}
              onChange={handleDialogChange}
            />

            {/* Quantidade */}
            <TextField
              margin="dense"
              name="quantidade"
              label="Quantidade"
              fullWidth
              value={dialogValues.quantidade || ""}
              onChange={handleDialogChange}
            />

            {/* Categoria (Agora com Select e opções carregadas) */}
            <FormControl fullWidth margin="dense">
              <InputLabel>Categoria</InputLabel>
              <Select
                value={dialogValues.categoria || ""}
                onChange={(e) =>
                  setDialogValues((prev) => ({ ...prev, categoria: e.target.value }))
                }
              >
                {categorias.map((cat, index) => (
                  <MenuItem key={index} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Tamanho */}
            <TextField
              margin="dense"
              name="tamanho"
              label="Tamanho"
              fullWidth
              value={dialogValues.tamanho || ""}
              onChange={handleDialogChange}
            />

            {/* Marca (Agora com Autocomplete e opções carregadas) */}
            <Autocomplete
              freeSolo
              options={marcas}
              value={dialogValues.marca || ""}
              onChange={(event, newValue) => {
                setDialogValues((prev) => ({ ...prev, marca: newValue.toUpperCase() }));
              }}
              renderInput={(params) => (
                <TextField {...params} label="Marca" margin="dense" variant="outlined" />
              )}
            />

            {/* Data de Entrada */}
            <TextField
              margin="dense"
              name="dataEntrada"
              label="Data de Entrada"
              fullWidth
              type="date"
              value={dialogValues.dataEntrada || ""}
              onChange={handleDialogChange}
              InputLabelProps={{ shrink: true }}
            />

            {/* Valor de Venda */}
            <TextField
              margin="dense"
              name="valorVenda"
              label="Valor de Venda"
              fullWidth
              value={dialogValues.valorVenda || ""}
              onChange={(e) =>
                handleCurrencyChange(e, (value) =>
                  setDialogValues((prev) => ({ ...prev, valorVenda: value }))
                )
              }
              InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
              }}
            />
          </DialogContent>


        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={atualizarItem} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openLoadingDialog}>
        <DialogContent>
          <DialogContentText>{loadingMessage}</DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog open={openPdfModal} onClose={handleClosePdfModal}>
        <DialogTitle>Orçamento Salvo</DialogTitle>
        <DialogContent>
          <DialogContentText>
            O orçamento foi salvo e enviado por e-mail. Você pode baixar o PDF
            clicando no botão abaixo.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            href={pdfUrl}
            download={`Orçamento-${savedOrcamentoDoc?.numero}.pdf`}
          >
            Baixar PDF
          </Button>
          <Button onClick={handleClosePdfModal} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Orcamento;
