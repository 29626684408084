import React, { useEffect, useState, useMemo, useContext, useCallback, useRef } from "react";
import { AuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import { signOut, getAuth } from "firebase/auth";
import { useTheme } from "@mui/material/styles";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Box,
  useMediaQuery,
} from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  ShoppingCart as ShoppingCartIcon,
  MonetizationOn as MonetizationOnIcon,
  Storefront as StorefrontIcon,
  TrendingUp as TrendingUpIcon,
} from "@mui/icons-material";

// 🔹 Função para chamadas otimizadas ao backend
const callBackendAPI = async (endpoint, method, body) => {
  try {
    const response = await fetch(endpoint, {
      method,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });

    if (!response.ok) throw new Error(`Erro: ${response.statusText}`);

    return await response.json();
  } catch (error) {
    console.error(`Erro ao chamar a API ${endpoint}:`, error);
    throw error;
  }
};

// 🔹 Componente memoizado para os Cards
const MemoizedCard = React.memo(({ titulo, valor, Icone, bgColor }) => (
  <Paper
    sx={{
      p: 2,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      borderRadius: 2,
      backgroundColor: bgColor,
      color: "#fff",
      minHeight: 100,
    }}
  >
    <Icone sx={{ fontSize: 30, mb: 1 }} />
    <Typography variant="subtitle1">{titulo}</Typography>
    <Typography variant="h6" fontWeight="bold">
      {valor}
    </Typography>
  </Paper>
));

const DashboardPage = () => {
  const theme = useTheme();
  const { userDetails } = useContext(AuthContext);
  const navigate = useNavigate();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPedidos, setTotalPedidos] = useState(0);
  const [totalCaixaEmpresa, setTotalCaixaEmpresa] = useState(0);
  const [totalProdutosEstoque, setTotalProdutosEstoque] = useState(0);
  const [vendasMensais, setVendasMensais] = useState([]);

  // 🔥 Evita múltiplas chamadas simultâneas
  const isFetching = useRef(false);

  const empresaId = userDetails?.empresaId || null;

  // 🔥 Carregamento assíncrono eficiente
  const carregarDados = useCallback(async () => {
    if (!empresaId || isFetching.current) return;

    isFetching.current = true; // Marca como em carregamento
    setIsLoading(true);

    try {
      const ano = new Date().getFullYear();
      const mes = new Date().getMonth() + 1;

      const [pedidosResponse, saldoResponse, estoqueResponse, vendasMensaisResponse] = await Promise.allSettled([
        callBackendAPI("https://us-central1-dona-cora.cloudfunctions.net/calcularTotalPedidos", "POST", { empresaId, ano, mes }),
        callBackendAPI("https://us-central1-dona-cora.cloudfunctions.net/calcularSaldoAcumulado", "POST", { empresaId, ano, mes }),
        callBackendAPI("https://us-central1-dona-cora.cloudfunctions.net/calcularEstoqueTotal", "POST", { empresaId }),
        callBackendAPI("https://us-central1-dona-cora.cloudfunctions.net/obterVendasMensais", "POST", { empresaId }),
      ]);

      pedidosResponse.status === "fulfilled" && setTotalPedidos(pedidosResponse.value.totalPedidos || 0);
      saldoResponse.status === "fulfilled" && setTotalCaixaEmpresa(saldoResponse.value.saldoAcumulado || 0);
      estoqueResponse.status === "fulfilled" && setTotalProdutosEstoque(estoqueResponse.value.totalEstoque || 0);
      vendasMensaisResponse.status === "fulfilled" && setVendasMensais(vendasMensaisResponse.value.vendasMensais || []);

    } catch (error) {
      setError("Erro ao carregar dados do backend.");
    } finally {
      setIsLoading(false);
      isFetching.current = false; // Marca como finalizado
    }
  }, [empresaId]);

  useEffect(() => {
    carregarDados();
  }, [carregarDados]);

  const handleLogout = async () => {
    try {
      await signOut(getAuth());
      navigate("/login");
    } catch (error) {
      console.error("Erro ao sair:", error);
    }
  };

  const totalCaixaFormatado = useMemo(() => `R$ ${totalCaixaEmpresa.toFixed(2)}`, [totalCaixaEmpresa]);
  const mediaPedidos = useMemo(() => (totalPedidos / 12).toFixed(1), [totalPedidos]);

  if (isLoading) {
    return (
      <Container maxWidth="xl" sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "70vh" }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="xl">
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  return (
      <Container
        maxWidth="xl"
        style={{
          marginTop: "20px",
          paddingLeft: isMdUp ? "230px" : "16px",
          paddingRight: "16px",
          maxWidth: isMdUp ? "calc(110% - 240px)" : "100%", // Ajustando o espaço restante da tela ao lado do sidebar
        }}
      >
      <Paper sx={{ p: 3, mb: 3, borderRadius: 2 }}>
        <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between">
          <Typography variant="h5" fontWeight="bold">
            Bem-vindo(a), {userDetails?.empresaData?.nome || "Carregando..."}
          </Typography>
          <Button variant="contained" color="secondary" startIcon={<LogoutIcon />} onClick={handleLogout}>
            Sair
          </Button>
        </Box>
      </Paper>

      {/* 🏆 Cards Estatísticos Responsivos 🏆 */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <MemoizedCard titulo="Total de Pedidos" valor={totalPedidos} Icone={ShoppingCartIcon} bgColor="#007bff" />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MemoizedCard titulo="Saldo Acumulado" valor={totalCaixaFormatado} Icone={MonetizationOnIcon} bgColor="#28a745" />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MemoizedCard titulo="Produtos em Estoque" valor={totalProdutosEstoque} Icone={StorefrontIcon} bgColor="#ffc107" />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MemoizedCard titulo="Média de Pedidos" valor={mediaPedidos} Icone={TrendingUpIcon} bgColor="#17a2b8" />
        </Grid>
      </Grid>

      {/* 🔥 Gráfico de Fluxo de Caixa 🔥 */}
        {/* 🔥 Gráfico de Fluxo de Caixa 🔥 */}
        <Paper sx={{ p: 3, mt: 3, borderRadius: 2, boxShadow: 3 }}>
          <Typography variant="h6" gutterBottom fontWeight="bold">
            Fluxo de Caixa Mensal
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            Acompanhe a evolução do fluxo de caixa da empresa ao longo dos meses.
          </Typography>

          <ResponsiveContainer width="100%" height={350}>
            <LineChart data={vendasMensais} margin={{ top: 10, right: 30, left: 20, bottom: 40 }}>
              {/* Grade de fundo */}
              <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.5} />

              {/* Eixo X - Meses */}
              <XAxis 
                dataKey="mes" 
                label={{ 
                  value: "Meses", 
                  position: "insideBottom", 
                  offset: -25, 
                  fontSize: 14, 
                  fill: "#666" 
                }}
                tick={{ fontSize: 12, fill: "#555" }}
              />

              {/* Eixo Y - Valores em R$ */}
              <YAxis 
                label={{ 
                  value: "Valor em R$", 
                  angle: -90, 
                  position: "insideLeft", 
                  fontSize: 14, 
                  fill: "#666" 
                }}
                tick={{ fontSize: 12, fill: "#555" }}
              />

              {/* Tooltip formatado */}
              <Tooltip 
                formatter={(value) => [`R$ ${value.toFixed(2)}`, "Valor"]}
                labelFormatter={(label) => `Mês: ${label}`}
              />

              {/* Legenda personalizada */}
              <Legend verticalAlign="top" height={36} />

              {/* Linha 1: Total Mensal */}
              <Line 
                type="monotone" 
                dataKey="totalMensal" 
                stroke="#28a745" 
                strokeWidth={2} 
                dot={{ r: 4 }} 
                activeDot={{ r: 6 }} 
                name="Total Mensal"
              />

              {/* Linha 2: Pagamentos a Fornecedores */}
              <Line 
                type="monotone" 
                dataKey="totalRepasseFornecedores" 
                stroke="#FF5733" 
                strokeWidth={2} 
                dot={{ r: 4 }} 
                activeDot={{ r: 6 }} 
                name="Pagamentos a Fornecedores"
              />

              {/* Linha 3: Custos Operacionais */}
              <Line 
                type="monotone" 
                dataKey="totalCustosOperacionais" 
                stroke="#8884d8" 
                strokeWidth={2} 
                dot={{ r: 4 }} 
                activeDot={{ r: 6 }} 
                name="Custos Operacionais"
              />
            </LineChart>
          </ResponsiveContainer>
        </Paper>

    </Container>
  );
};

export default DashboardPage;
