import React, { useState, useEffect, useContext, useMemo, useCallback } from "react";
import {
  Modal,
  Box,
  IconButton,
  Typography,
  TextField,
  Select,
  MenuItem,
  Grid,
  Paper,
  InputAdornment,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { AuthContext } from "./AuthContext";
import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase";
import debounce from "lodash.debounce";

const EstoqueModal = ({ open, onClose, onProdutoSelecionado }) => {
  const { userDetails } = useContext(AuthContext);
  const [produtos, setProdutos] = useState([]);
  const [busca, setBusca] = useState("");
  const [filtroCodigo, setFiltroCodigo] = useState("");
  const [filtroFornecedor, setFiltroFornecedor] = useState("");
  const [filtroMarca, setFiltroMarca] = useState("");
  const [filtroCategoria, setFiltroCategoria] = useState("");
  const [fornecedores, setFornecedores] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userDetails?.empresaId && open) {
      carregarProdutos();
    }
  }, [userDetails, open]);

  const carregarProdutos = async () => {
    setLoading(true);

    const querySnapshot = await getDocs(
      collection(db, "EMPRESAS", userDetails.empresaId, "Cadastro de Peças", "dados", "Estoque Ativo")
    );

    const produtosData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      fornecedor: doc.data().fornecedor?.trim() || userDetails.empresaId,
    }));

    setProdutos(produtosData);
    setLoading(false);

    // Fornecedores, marcas e categorias únicas
    setFornecedores([...new Set(produtosData.map(p => p.fornecedor))].sort());
    setMarcas([...new Set(produtosData.map(p => p.marca).filter(Boolean))].sort());
    setCategorias([...new Set(produtosData.map(p => p.categoria).filter(Boolean))].sort());
  };

  const debouncedSetBusca = useCallback(debounce(setBusca, 300), []);

  const produtosFiltrados = useMemo(() => {
    return produtos.filter((produto) => {
      const descricaoValida = busca ? produto.descricao?.toLowerCase().includes(busca.toLowerCase()) : true;
      const codigoValido = filtroCodigo ? produto.codigoPeca?.includes(filtroCodigo) : true;
      const fornecedorValido = filtroFornecedor ? produto.fornecedor === filtroFornecedor : true;
      const marcaValida = filtroMarca ? produto.marca === filtroMarca : true;
      const categoriaValida = filtroCategoria ? produto.categoria === filtroCategoria : true;
      const quantidadeValida = produto.quantidade >= 1;
      return (
        descricaoValida &&
        codigoValido &&
        fornecedorValido &&
        marcaValida &&
        categoriaValida &&
        quantidadeValida
      );
    });
  }, [busca, filtroCodigo, filtroFornecedor, filtroMarca, filtroCategoria, produtos]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px" }}
    >
      <Box
        sx={{
          position: "relative",
          width: { xs: "95%", md: "75%" },
          maxHeight: "85vh",
          overflowY: "auto",
          backgroundColor: "background.paper",
          borderRadius: 3,
          boxShadow: 24,
          padding: 3,
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 12, right: 12, color: "grey.600" }}
          size="large"
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h5" component="h2" sx={{ fontWeight: "bold", textAlign: "center", borderBottom: `2px solid grey`, pb: 2, mb: 3 }}>
          Estoque de Produtos
        </Typography>

                {loading ? (
                  <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
         {/* Filtros (sempre exibidos após carregamento) */}
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Buscar por Descrição"
                  onChange={(e) => debouncedSetBusca(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Código da Peça"
                  value={filtroCodigo}
                  onChange={(e) => setFiltroCodigo(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Select
                  fullWidth
                  displayEmpty
                  value={filtroFornecedor}
                  onChange={(e) => setFiltroFornecedor(e.target.value)}
                >
                  <MenuItem value=""><em>Todos os Fornecedores</em></MenuItem>
                  {fornecedores.map((fornecedor, index) => (
                    <MenuItem key={index} value={fornecedor}>{fornecedor}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  fullWidth
                  displayEmpty
                  value={filtroMarca}
                  onChange={(e) => setFiltroMarca(e.target.value)}
                >
                  <MenuItem value=""><em>Todas as Marcas</em></MenuItem>
                  {marcas.map((marca, index) => (
                    <MenuItem key={index} value={marca}>{marca}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  fullWidth
                  displayEmpty
                  value={filtroCategoria}
                  onChange={(e) => setFiltroCategoria(e.target.value)}
                >
                  <MenuItem value=""><em>Todas as Categorias</em></MenuItem>
                  {categorias.map((categoria, index) => (
                    <MenuItem key={index} value={categoria}>{categoria}</MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>

            {produtosFiltrados.length === 0 ? (
              <Typography align="center" sx={{ mt: 3, color: "text.secondary" }}>
                Nenhum produto encontrado.
              </Typography>
            ) : (
              <TableContainer component={Paper} sx={{ maxHeight: "60vh", overflowY: "auto" }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}>Descrição</TableCell>
                      <TableCell sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}>Código</TableCell>
                      <TableCell sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}>Fornecedor</TableCell>
                      <TableCell sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}>Quantidade</TableCell>
                      <TableCell sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}>Preço (R$)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {produtosFiltrados.map((produto) => (
                      <TableRow
                        key={produto.id}
                        hover
                        sx={{ cursor: "pointer" }}
                        onClick={() => onProdutoSelecionado(produto.codigoPeca)}
                      >
                        <TableCell sx={{ wordWrap: "break-word", maxWidth: "200px", fontSize: "14px" }}>
                          {produto.descricao}
                        </TableCell>
                        <TableCell>{produto.codigoPeca}</TableCell>
                        <TableCell>{produto.fornecedor}</TableCell>
                        <TableCell>{produto.quantidade}</TableCell>
                        <TableCell>R$ {parseFloat(produto.valorVenda).toFixed(2)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default EstoqueModal;
