import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback
} from "react";
import { AuthContext } from "./AuthContext";
import { db } from "./firebase";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  TextField,
  Container,
  TableRow,
  Card,
  useMediaQuery,
  Typography,
  CardContent,
  Grid,
  Paper,
  Box,
  Button,
  Tooltip,
  Collapse,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from "@mui/material";
import { createTheme, useTheme } from "@mui/material/styles";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  AttachMoney as AttachMoneyIcon,
  TrendingUp as TrendingUpIcon,
  MonetizationOn as MonetizationOnIcon,
  AccountBalance as AccountBalanceIcon,
  Refresh as RefreshIcon,
  BarChart as BarChartIcon,
  SaveAlt as SaveAltIcon,
  Info as InfoIcon
} from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { jsPDF } from "jspdf";
import PrintIcon from "@mui/icons-material/Print";
import { Line } from "react-chartjs-2";
import "jspdf-autotable";
import ReceiptIcon from "@mui/icons-material/Receipt";


const formatCurrency = value => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  }).format(value);
};

function CaixaEmpresa() {
  const theme = useTheme();
  const { userDetails } = useContext(AuthContext);
  const empresaId = userDetails.empresaId;
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [vendas, setVendas] = useState([]);
  const [custosEfetivos, setCustosEfetivos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anoSelecionado, setAnoSelecionado] = useState(
    new Date().getFullYear()
  );
  const [mesSelecionado, setMesSelecionado] = useState("");
  const [modalidadeSelecionada, setModalidadeSelecionada] = useState("");
  const [diaSelecionado, setDiaSelecionado] = useState("");
  const [expandedRowId, setExpandedRowId] = useState(null);
  const [informacoesEmpresa, setInformacoesEmpresa] = useState({});

  const handleToggleRow = id => {
    setExpandedRowId(expandedRowId === id ? null : id); // Toggle
  };
  

  const carregarVendas = useCallback(async () => {
    setLoading(true);
    try {
      const vendasData = [];
      const promises = [];

      for (let mes = 1; mes <= 12; mes++) {
        if (mesSelecionado && mes !== mesSelecionado) continue;

        for (let dia = 1; dia <= 31; dia++) {
          if (diaSelecionado && dia !== diaSelecionado) continue; // Aplica filtro de dia

          const vendasRef = collection(
            db,
            `EMPRESAS/${empresaId}/Vendas/${anoSelecionado}/${mes}/${dia}/Vendas`
          );
          promises.push(getDocs(vendasRef));
        }
      }

      const results = await Promise.all(promises);

      results.forEach(querySnapshot => {
        querySnapshot.forEach(doc => {
          const vendaData = { id: doc.id, ...doc.data() };
          if (!modalidadeSelecionada || vendaData.metodoPagamento[modalidadeSelecionada]) {
            vendasData.push(vendaData);
          }
        });
      });

      setVendas(vendasData);
    } catch (error) {
      console.error("Erro ao buscar vendas:", error);
    }
    setLoading(false);
  }, [empresaId, anoSelecionado, mesSelecionado, diaSelecionado, modalidadeSelecionada]);

  useEffect(() => {
    carregarVendas();
    carregarCustosEfetivos();
  }, [carregarVendas]);

  const carregarCustosEfetivos = useCallback(async () => {
    try {
      const empresaRef = doc(db, "EMPRESAS", empresaId);
      const empresaDoc = await getDoc(empresaRef);
      if (empresaDoc.exists()) {
        const data = empresaDoc.data();
        setCustosEfetivos(data.CustosEfetivos || []);
      }
    } catch (error) {
      console.error("Erro ao buscar custos efetivos:", error);
    }
  }, [empresaId]);

  useEffect(() => {
    const carregarInformacoesEmpresa = async () => {
      const empresaRef = doc(db, "EMPRESAS", empresaId);
      const empresaDoc = await getDoc(empresaRef);
      if (empresaDoc.exists()) {
        setInformacoesEmpresa(empresaDoc.data());
      }
    };
    carregarVendas();
    carregarCustosEfetivos();
    carregarInformacoesEmpresa();
  }, [carregarVendas, carregarCustosEfetivos, empresaId]);
  
  const imprimirCupomFiscal = (idVenda) => {
    const venda = vendas.find(v => v.idVenda === idVenda);
    if (!venda) {
      console.warn("Venda não encontrada.");
      return;
    }

    const tipoPagamento = venda.pagamento?.tipoPagamento || {};

    const produtos = Object.values(venda.produtosPorFornecedor || {}).flat();

    const calcularQuantidadeTotal = () => {
      return produtos.reduce((total, item) => total + (parseInt(item.quantidade) || 1), 0);
    };

    let conteudoCupom = `
      <div style="font-family: monospace; font-size: 10px; line-height: 1.2; text-align: left; width: 100%;">
        <div style="text-align: center;">
          <strong>CUPOM FISCAL - 2ª VIA - ${informacoesEmpresa.nome || empresaId}</strong><br />
          CNPJ: ${informacoesEmpresa.CNPJ || "00.000.000/0000-00"}<br />
          Endereço: ${informacoesEmpresa.endereco || "Endereço não disponível"}<br />
          Telefone: ${informacoesEmpresa.telefone || "Telefone não disponível"}<br />
          ------------------------------------------<br />
          Código da Venda: ${idVenda}<br />
          Data: ${new Date(venda.dataVenda.seconds * 1000).toLocaleString()}<br />
          ------------------------------------------<br />
        </div>
        <div>
          Qtde  Código     Descrição      Total<br />
          ------------------------------------------<br />
    `;

    produtos.forEach((item) => {
      const descricaoCurta = item.descricao?.substring(0, 10).padEnd(12, " ");
      const valorTotal = formatCurrency(
        parseFloat(item.precoFinal) * (item.quantidade || 1)
      );

      conteudoCupom += `
        ${(item.quantidade || 1).toString().padEnd(4, " ")}  ${
        (item.codigoPeca || "").padEnd(8, " ")
      }  ${descricaoCurta} ${valorTotal.padStart(10, " ")}<br />
      `;
    });

    conteudoCupom += `
          ------------------------------------------<br />
          FORMA DE PAGAMENTO:<br />
    `;

    // Se houve crédito de fornecedor
    if (venda.pagamento?.fornecedorCredito) {
      conteudoCupom += `- Crédito com Fornecedor: ${venda.pagamento.fornecedorCredito}<br />`;
      conteudoCupom += `- Valor pago por crédito: ${formatCurrency(parseFloat(venda.pagamento.pagoporcredito || 0))}<br />`;

      const restante = parseFloat(venda.pagamento.pagamentoRestante || 0);
      if (restante > 0) {
        conteudoCupom += `- Valor restante pago em outra forma: ${formatCurrency(restante)}<br />`;
      }
    }

    // Agora as formas de pagamento convencionais:
    if (tipoPagamento?.divisao) {
      if (tipoPagamento.dinheiroDivisao) {
        conteudoCupom += `- Dinheiro: ${formatCurrency(parseFloat(tipoPagamento.dinheiroDivisao))}<br />`;
      }
      if (tipoPagamento.cartaoDivisao) {
        conteudoCupom += `- Cartão: ${formatCurrency(parseFloat(tipoPagamento.cartaoDivisao))}<br />`;
      }
    } else {
      if (tipoPagamento.dinheiro === true) {
        conteudoCupom += "- Dinheiro<br />";
      }
      if (tipoPagamento.pix === true) {
        conteudoCupom += "- PIX<br />";
      }
      if (tipoPagamento.cartao?.ativo === true) {
        if (tipoPagamento.cartao.credito === true) {
          conteudoCupom += `- Cartão Crédito - Parcelas: ${tipoPagamento.cartao.parcelas || 1}<br />`;
        } else if (tipoPagamento.cartao.debito === true) {
          conteudoCupom += "- Cartão Débito<br />";
        } else {
          conteudoCupom += "- Cartão<br />";
        }
      }
    }



    conteudoCupom += `
          ------------------------------------------<br />
          <div style="text-align: center;">
            Obrigado pela preferência!
          </div>
        </div>
      </div>
    `;

    const win = window.open("", "", "width=300,height=600");
    win.document.write(`<html><body>${conteudoCupom}</body></html>`);
    win.document.close();
    win.focus();
    win.print(); // Abre o diálogo de impressão
    win.close();
  };
 
  const calcularValoresPorModalidade = useMemo(() => {
    const valores = {
      dinheiro: 0,
      pix: 0,
      cartao: 0
    };

    vendas.forEach(venda => {
      const tipoPagamento = venda.tipoPagamento || {}; // Garante que o campo existe

      // Garante que cada tipo de pagamento tem um mapa
      const cartao = tipoPagamento.cartao || {};
      const dinheiro = tipoPagamento.dinheiro || {};
      const pix = tipoPagamento.pix || {};

      // Verifica se o pagamento foi feito nessa modalidade e soma ao total
      if (dinheiro.ativo) valores.dinheiro += venda.totalCaixaEmpresa;
      if (pix.ativo) valores.pix += venda.totalCaixaEmpresa;
      if (cartao.ativo) valores.cartao += venda.totalCaixaEmpresa;
    });

    return {
      dinheiro: formatCurrency(valores.dinheiro),
      pix: formatCurrency(valores.pix),
      cartao: formatCurrency(valores.cartao)
    };
  }, [vendas]);





  const calcularSubtotal = useMemo(() => {
    return vendas
      .reduce((total, venda) => {
        let totalVenda = 0;
        Object.values(venda.produtosPorFornecedor || {}).forEach(produtos => {
          produtos.forEach(produto => {
            totalVenda += parseFloat(produto.precoFinal) || 0;
          });
        });
        return total + totalVenda;
      }, 0)
      .toFixed(2);
  }, [vendas]);

  const dadosGrafico = useMemo(() => {
    const meses = Array.from({ length: 12 }, (_, index) => index + 1);
    const valoresMensais = meses.map(mes => {
      const totalMes = vendas
        .filter(
          venda =>
            new Date(venda.dataVenda.seconds * 1000).getMonth() + 1 === mes
        )
        .reduce((total, venda) => {
          let totalVenda = 0;
          Object.values(venda.produtosPorFornecedor || {}).forEach(produtos => {
            produtos.forEach(produto => {
              totalVenda += parseFloat(produto.precoFinal) || 0;
            });
          });
          return total + totalVenda;
        }, 0);
      return totalMes.toFixed(2);
    });

    return {
      labels: meses,
      datasets: [
        {
          label: "Evolução do Caixa",
          data: valoresMensais,
          fill: false,
          borderColor: theme.palette.primary.main,
          tension: 0.1
        }
      ]
    };
  }, [vendas, theme.palette.primary.main]);

  const calcularInvestimentoTotal = useMemo(() => {
    return vendas
      .reduce((total, venda) => {
        let totalInvestimento = 0;
        Object.values(venda.produtosPorFornecedor || {}).forEach(produtos => {
          produtos.forEach(produto => {
            if (produto.valorCompra) {
              totalInvestimento += parseFloat(produto.valorCompra) || 0;
            }
          });
        });
        return total + totalInvestimento;
      }, 0)
      .toFixed(2);
  }, [vendas]);

  const calcularRepasseTotal = useMemo(() => {
    return vendas
      .reduce((total, venda) => {
        let totalRepasse = 0;
        Object.values(venda.produtosPorFornecedor || {}).forEach(produtos => {
          produtos.forEach(produto => {
            if (produto.repasseFornecedor) {
              totalRepasse += parseFloat(produto.repasseFornecedor) || 0;
            }
          });
        });
        return total + totalRepasse;
      }, 0)
      .toFixed(2);
  }, [vendas]);

  const calcularTotalDescontos = useMemo(() => {
    return vendas
      .reduce((total, venda) => {
        let totalDescontos = 0;
        Object.values(venda.produtosPorFornecedor || {}).forEach(produtos => {
          produtos.forEach(produto => {
            if (produto.descontoMonetario) {
              totalDescontos += parseFloat(produto.descontoMonetario) || 0;
            } else if (produto.descontoPercentual) {
              totalDescontos +=
                (parseFloat(produto.valorVenda) *
                  parseFloat(produto.descontoPercentual)) /
                  100 || 0;
            }
          });
        });
        return total + totalDescontos;
      }, 0)
      .toFixed(2);
  }, [vendas]);

  const calcularTotalCustosEfetivos = useMemo(() => {
    return custosEfetivos
      .reduce((total, custo) => {
        const valor = parseFloat(
          custo.valor.replace(/\./g, "").replace(",", ".")
        );
        return total + (isNaN(valor) ? 0 : valor);
      }, 0)
      .toFixed(2);
  }, [custosEfetivos]);

  const calcularSaldoCaixaEmpresa = useMemo(() => {
    const totalEntradas = vendas.reduce((total, venda) => {
      let totalVenda = 0;
      Object.values(venda.produtosPorFornecedor || {}).forEach(produtos => {
        produtos.forEach(produto => {
          totalVenda += parseFloat(produto.precoFinal) || 0;
        });
      });
      return total + totalVenda;
    }, 0);

    const totalRepasse = vendas.reduce((total, venda) => {
      let totalRepasse = 0;
      Object.values(venda.produtosPorFornecedor || {}).forEach(produtos => {
        produtos.forEach(produto => {
          if (produto.repasseFornecedor) {
            totalRepasse += parseFloat(produto.repasseFornecedor) || 0;
          }
        });
      });
      return total + totalRepasse;
    }, 0);

    return (totalEntradas - totalRepasse - calcularTotalCustosEfetivos).toFixed(
      2
    );
  }, [vendas, calcularTotalCustosEfetivos]);

  const handleAnoChange = date => {
    setAnoSelecionado(date.getFullYear());
  };

  const handleMesChange = event => {
    setMesSelecionado(event.target.value);
    setDiaSelecionado(""); // Reseta o dia ao trocar de mês
  };

  const handleDiaChange = event => {
    setDiaSelecionado(event.target.value);
  };

  const handleModalidadeChange = event => {
    setModalidadeSelecionada(event.target.value);
  };

  const exportarPDF = () => {
    const doc = new jsPDF();
    doc.text("Relatório de Vendas", 14, 22);

    const vendaColumns = [
      "Data da Venda",
      "ID da Venda",
      "Método de Pagamento",
      "Valor de Venda",
      "Recebido pela Venda(descontado Pag fornecedores)"
    ];
    const vendaRows = vendas.map(venda => [
      new Date(venda.dataVenda.seconds * 1000).toLocaleDateString("pt-BR"),
      venda.idVenda,
      venda.metodoPagamento?.dinheiro
        ? "Dinheiro"
        : venda.metodoPagamento?.pix
        ? "Pix"
        : venda.metodoPagamento?.cartao
        ? "Cartão"
        : "Outro",
      formatCurrency(venda.totalCaixaEmpresa || 0)
    ]);


    doc.autoTable({
      head: [vendaColumns],
      body: vendaRows,
      startY: 30
    });

    let finalY = doc.autoTable.previous.finalY + 10;

    const custoColumns = ["Descrição do Custo", "Valor do Custo"];
    const custoRows = custosEfetivos.map(custo => [
      custo.descricao,
      formatCurrency(
        parseFloat(custo.valor.replace(/\./g, "").replace(",", "."))
      )
    ]);

    doc.text("Custos Efetivos", 14, finalY + 10);
    doc.autoTable({
      head: [custoColumns],
      body: custoRows,
      startY: finalY + 20
    });

    finalY = doc.autoTable.previous.finalY + 10;
    doc.text(
      `Total Custos Efetivos: ${formatCurrency(calcularTotalCustosEfetivos)}`,
      14,
      finalY + 10
    );

    doc.text(
      `Saldo Caixa Empresa: ${formatCurrency(calcularSaldoCaixaEmpresa)}`,
      14,
      finalY + 20
    );

    doc.save("relatorio_vendas.pdf");
  };

  

  return (
                    <Box
                      sx={{
                        backgroundColor: "#fffff",
                        minHeight: "100vh",
                        display: "flex", // Adicionado para permitir o crescimento do filho
                        flexDirection: "column", // Mantém a direção da coluna
                      }}
                    >
                          <Container
                            maxWidth="xl"
                            style={{
                              marginTop: "20px",
                              paddingLeft: isMdUp ? "220px" : "16px",
                              paddingRight: "16px",
                              maxWidth: isMdUp ? "calc(110% - 240px)" : "100%", // Ajustando o espaço restante da tela ao lado do sidebar
                            }}
                          >
                    <Card elevation={6} sx={{ p: 3, borderRadius: 3 }}>
                      <Typography
                        variant="h5"
                        sx={{
                          mb: 1,
                          color: theme.palette.primary.dark,
                          fontWeight: "bold",
                          textAlign: "center"
                        }}
                      >
                        Caixa da Empresa
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        sx={{
                          mb: 3,
                          color: theme.palette.primary.main,
                          textAlign: "center"
                        }}
                      >
                        Fluxo de caixa e controle de vendas
                      </Typography>

                      {loading ? (
                        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Grid container spacing={2}>
                          {[{
                            label: "Total Entradas",
                            value: calcularSubtotal,
                            color: "#0288d1",
                            icon: <AttachMoneyIcon sx={{ fontSize: 30, color: "#fff" }} />,
                            tooltip: "Entradas antes de repassar ao fornecedor"
                          }, {
                            label: "Total Investido",
                            value: calcularInvestimentoTotal,
                            color: "#388e3c",
                            icon: <TrendingUpIcon sx={{ fontSize: 30, color: "#fff" }} />,
                            tooltip: "Investimento em compras de produtos"
                          }, {
                            label: "Total Repasse",
                            value: calcularRepasseTotal,
                            color: "#f57c00",
                            icon: <MonetizationOnIcon sx={{ fontSize: 30, color: "#fff" }} />,
                            tooltip: "Total repassado ao fornecedor"
                          }, {
                            label: "Saldo Caixa",
                            value: calcularSaldoCaixaEmpresa,
                            color: "#1976d2",
                            icon: <AccountBalanceIcon sx={{ fontSize: 30, color: "#fff" }} />,
                            tooltip: "Saldo líquido da empresa"
                          }].map((item, index) => (
                            <Grid item xs={12} md={3} key={index}>
                              <Card
                                elevation={3}
                                sx={{
                                  p: 2,
                                  borderRadius: 2,
                                  backgroundColor: item.color,
                                  color: "#fff",
                                  textAlign: "center",
                                  position: "relative"
                                }}
                              >
                                <Tooltip title={item.tooltip}>
                                  <IconButton sx={{ position: "absolute", top: 6, right: 6, color: "#fff" }}>
                                    <InfoIcon />
                                  </IconButton>
                                </Tooltip>
                                {item.icon}
                                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                                  {item.label}
                                </Typography>
                                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                  {formatCurrency(item.value)}
                                </Typography>
                              </Card>
                            </Grid>
                          ))}

                          <Grid item xs={12} md={3}>
                            <Card elevation={3} sx={{ p: 2, borderRadius: 2, backgroundColor: "#5e35b1", color: "#fff", textAlign: "center" }}>
                              <Tooltip title="Total de descontos aplicados">
                                <IconButton sx={{ position: "absolute", top: 6, right: 6, color: "#fff" }}>
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                              <BarChartIcon sx={{ fontSize: 30 }} />
                              <Typography variant="subtitle2" sx={{ fontWeight: "bold", mt: 1 }}>
                                Total Descontos
                              </Typography>
                              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                {formatCurrency(calcularTotalDescontos)}
                              </Typography>
                            </Card>
                          </Grid>

                          <Grid item xs={12} md={3}>
                            <Card elevation={3} sx={{ p: 2, borderRadius: 2, backgroundColor: "#8e24aa", color: "#fff", textAlign: "center" }}>
                              <Tooltip title="Total de custos fixos e variáveis da empresa">
                                <IconButton sx={{ position: "absolute", top: 6, right: 6, color: "#fff" }}>
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                              <MonetizationOnIcon sx={{ fontSize: 30 }} />
                              <Typography variant="subtitle2" sx={{ fontWeight: "bold", mt: 1 }}>
                                Custos Efetivos
                              </Typography>
                              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                {formatCurrency(calcularTotalCustosEfetivos)}
                              </Typography>
                            </Card>
                          </Grid>

                

                          <Grid container spacing={2} sx={{ mb: 3 }}>
                            {[
                              {
                                label: "Total Recebido - Dinheiro",
                                value: calcularValoresPorModalidade.dinheiro,
                                color: theme.palette.success.light,
                                textColor: theme.palette.success.contrastText
                              },
                              {
                                label: "Total Recebido - Pix",
                                value: calcularValoresPorModalidade.pix,
                                color: theme.palette.info.light,
                                textColor: theme.palette.info.contrastText
                              },
                              {
                                label: "Total Recebido - Cartão",
                                value: calcularValoresPorModalidade.cartao,
                                color: theme.palette.warning.light,
                                textColor: theme.palette.warning.contrastText
                              }
                            ].map((item, index) => (
                              <Grid item xs={12} md={4} key={index}>
                                <Card
                                  elevation={3}
                                  sx={{
                                    backgroundColor: item.color,
                                    color: item.textColor,
                                    p: 2,
                                    borderRadius: 2,
                                    textAlign: "center",
                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                                  }}
                                >
                                  <CardContent>
                                    <Typography variant="subtitle2" sx={{ fontWeight: "bold", mb: 1 }}>
                                      {item.label}
                                    </Typography>
                                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                                      {item.value}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Grid>
                            ))}
                          </Grid>

                            <Grid container spacing={2} sx={{ mb: 3 }}>
                              {[
                                {
                                  label: "Ano",
                                  component: (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                      <DatePicker
                                        views={["year"]}
                                        label="Selecionar Ano"
                                        value={new Date(`${anoSelecionado}-01-01`)}
                                        onChange={handleAnoChange}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            fullWidth
                                            variant="outlined"
                                            sx={{
                                              backgroundColor: "#ffffff",
                                              borderRadius: "8px",
                                              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                              "& .MuiOutlinedInput-root": {
                                                "& fieldset": {
                                                  borderColor: theme.palette.primary.main
                                                },
                                                "&:hover fieldset": {
                                                  borderColor: theme.palette.primary.dark
                                                }
                                              }
                                            }}
                                          />
                                        )}
                                      />
                                    </LocalizationProvider>
                                  )
                                },
                                {
                                  label: "Mês",
                                  component: (
                                    <FormControl fullWidth variant="outlined">
                                      <InputLabel>Mês</InputLabel>
                                      <Select value={mesSelecionado} onChange={handleMesChange} label="Mês">
                                        <MenuItem value="">Todos</MenuItem>
                                        {[...Array(12)].map((_, index) => (
                                          <MenuItem key={index + 1} value={index + 1}>
                                            {index + 1}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  )
                                },
                                {
                                  label: "Dia",
                                  component: (
                                    <FormControl fullWidth>
                                      <InputLabel>Dia</InputLabel>
                                      <Select value={diaSelecionado} onChange={handleDiaChange} label="Dia">
                                        <MenuItem value="">Todos</MenuItem>
                                        {[...Array(31)].map((_, index) => (
                                          <MenuItem key={index + 1} value={index + 1}>
                                            {index + 1}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  )
                                },
                                {
                                  label: "Modalidade",
                                  component: (
                                    <FormControl fullWidth variant="outlined">
                                      <InputLabel>Modalidade</InputLabel>
                                      <Select
                                        value={modalidadeSelecionada}
                                        onChange={handleModalidadeChange}
                                        label="Modalidade"
                                      >
                                        <MenuItem value="">Todas</MenuItem>
                                        <MenuItem value="dinheiro">Dinheiro</MenuItem>
                                        <MenuItem value="pix">Pix</MenuItem>
                                        <MenuItem value="cartao">Cartão</MenuItem>
                                      </Select>
                                    </FormControl>
                                  )
                                }
                              ].map((field, index) => (
                                <Grid item xs={12} sm={6} md={3} key={index}>
                                  {field.component}
                                </Grid>
                              ))}

                              <Grid item xs={12} sm={6} md={3}>
                                <Button
                                  fullWidth
                                  variant="outlined"
                                  color="primary"
                                  startIcon={<RefreshIcon />}
                                  onClick={carregarVendas}
                                  sx={{
                                    borderRadius: "8px",
                                    fontWeight: "bold",
                                    textTransform: "none",
                                    height: "100%"
                                  }}
                                >
                                  Atualizar
                                </Button>
                              </Grid>

                              <Grid item xs={12} sm={6} md={3}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="secondary"
                                  startIcon={<PrintIcon />}
                                  onClick={exportarPDF}
                                  sx={{
                                    borderRadius: "8px",
                                    fontWeight: "bold",
                                    textTransform: "none",
                                    height: "100%"
                                  }}
                                >
                                  Exportar PDF
                                </Button>
                              </Grid>
                           

                <Card
                  elevation={3}
                  sx={{
                    padding: "20px",
                    borderRadius: "12px",
                    backgroundColor: "#ffffff",
                    boxShadow:
                      "0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1)",
                    width: "100%"
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: theme.palette.primary.main,
                      marginBottom: "20px",
                      textAlign: "center"
                    }}
                  >
                    Detalhes das Vendas
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow
                        sx={{ backgroundColor: theme.palette.primary.main }}
                      >
                        <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                          Data da Venda
                        </TableCell>
                        <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                          ID da Venda
                        </TableCell>
                        <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                          Método de Pagamento
                        </TableCell>
                        <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                          Total recebido pela Venda
                        </TableCell>
                        <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                          Expandir
                        </TableCell>
                        <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                          Reimprimir
                        </TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {vendas.map(venda => (
                        <React.Fragment key={venda.idVenda}>
                          <TableRow
                            hover
                            sx={{
                              "&:hover": { backgroundColor: "#e0f2f1" },
                              transition: "background-color 0.3s"
                            }}
                          >
                            <TableCell>
                              {new Date(
                                venda.dataVenda.seconds * 1000
                              ).toLocaleDateString("pt-BR")}
                            </TableCell>
                            <TableCell>{venda.idVenda}</TableCell>
                            <TableCell>
                              {(() => {
                                const tipo = venda.tipoPagamento;
                                const pagamento = venda.pagamento;
                                let metodo = "";

                                if (tipo?.dinheiro?.ativo) metodo = "Dinheiro";
                                else if (tipo?.pix?.ativo) metodo = "Pix";
                                else if (tipo?.cartao?.ativo) {
                                  metodo = tipo.cartao.credito
                                    ? "Cartão Crédito"
                                    : tipo.cartao.debito
                                    ? "Cartão Débito"
                                    : "Cartão";
                                } else {
                                  metodo = "Outro";
                                }

                                if (pagamento?.fornecedorCredito) {
                                  const valorCredito = parseFloat(pagamento.pagoporcredito || 0);
                                  const valorRestante = parseFloat(pagamento.pagamentoRestante || 0);
                                  const fornecedor = pagamento.fornecedorCredito;
                                  metodo += ` (Crédito: ${formatCurrency(valorCredito)} - Restante: ${formatCurrency(valorRestante)} - Fornecedor: ${fornecedor})`;
                                }

                                return metodo;
                              })()}
                            </TableCell>


                            <TableCell>
                              {
                                formatCurrency(
                                  Object.values(venda.produtosPorFornecedor || {})
                                    .flat()
                                    .reduce((total, item) => total + parseFloat(item.precoFinal || 0), 0)
                                )
                              }

                            </TableCell>
                            <TableCell>
                              <Tooltip title="Expandir/Colapsar detalhes">
                                <IconButton onClick={() => handleToggleRow(venda.idVenda)}>
                                  {expandedRowId === venda.idVenda ? (
                                    <ExpandLessIcon />
                                  ) : (
                                    <ExpandMoreIcon />
                                  )}
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              <Tooltip title="Reimprimir cupom fiscal">
                                <IconButton onClick={() => imprimirCupomFiscal(venda.idVenda)}>
                                  <ReceiptIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>

                          </TableRow>
                          {expandedRowId === venda.idVenda && (
                            <TableRow>
                              <TableCell
                                colSpan={5}
                                sx={{
                                  backgroundColor: "#f9f9f9",
                                  padding: "20px"
                                }}
                              >
                                <Collapse
                                  in={expandedRowId === venda.idVenda}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Box margin={1}>
                                    <Typography
                                      variant="h6"
                                      gutterBottom
                                      component="div"
                                      sx={{
                                        color: theme.palette.secondary.main
                                      }}
                                    >
                                      Detalhes dos Produtos
                                    </Typography>
                                    <Paper
                                      elevation={2}
                                      sx={{
                                        padding: "10px",
                                        backgroundColor: "#fafafa",
                                        borderRadius: "8px",
                                        animation: "fadeIn 0.5s"
                                      }}
                                    >
                                      <Table
                                        size="small"
                                        aria-label="purchases"
                                      >
                                        <TableHead>
                                          <TableRow
                                            sx={{
                                              backgroundColor:
                                                theme.palette.primary.light
                                            }}
                                          >
                                            {[
                                              "Produto",
                                              "Preço Unitário",
                                              "Quantidade",
                                              "Desconto",
                                              "Preço Final de Venda",
                                              "Repasse ao Fornecedor"
                                            ].map(text => (
                                              <TableCell
                                                key={text}
                                                sx={{
                                                  color: "#fff",
                                                  fontWeight: "bold"
                                                }}
                                              >
                                                {text}
                                              </TableCell>
                                            ))}
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {Object.entries(
                                            venda.produtosPorFornecedor
                                          ).flatMap(([fornecedor, produtos]) =>
                                            produtos.map((produto, index) => (
                                              <TableRow key={index}>
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                >
                                                  {produto.descricao}
                                                </TableCell>
                                                <TableCell>
                                                  {formatCurrency(
                                                    parseFloat(
                                                      produto.valorVenda
                                                    )
                                                  )}
                                                </TableCell>
                                                <TableCell>
                                                  {produto.quantidade}
                                                </TableCell>
                                                <TableCell>
                                                  {produto.descontoPercentual >
                                                  0
                                                    ? `${produto.descontoPercentual}%`
                                                    : produto.descontoMonetario >
                                                      0
                                                    ? formatCurrency(
                                                        parseFloat(
                                                          produto.descontoMonetario
                                                        )
                                                      )
                                                    : "0"}
                                                </TableCell>
                                                <TableCell>
                                                  {formatCurrency(
                                                    parseFloat(
                                                      produto.precoFinal
                                                    )
                                                  )}
                                                </TableCell>
                                                <TableCell>
                                                  {formatCurrency(
                                                    parseFloat(
                                                      produto.repasseFornecedor
                                                    )
                                                  )}
                                                </TableCell>
                                              </TableRow>
                                            ))
                                          )}
                                        </TableBody>
                                      </Table>
                                    </Paper>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </Card>
              </Grid>
            </Grid>
          )}
        </Card>
                
      </Container>
    </Box>
  );
}

export default CaixaEmpresa;
