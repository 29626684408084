import React, { useContext, useState, useEffect, useMemo } from "react";
import { AuthContext } from "./AuthContext";
import { db } from "./firebase";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  writeBatch
} from "firebase/firestore";
import { query, where } from "firebase/firestore";
import gerarRelatorioGeralFornecedores from "./RelatorioGeralFornecedores";

import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { format } from "date-fns";
import {
  Container,
  Typography,
  CircularProgress,
  Modal,
  TextField,
  Box,
  DialogTitle,
  DialogContent,
  List,
  Grid,
  DialogActions,
  ListItem,
  ListItemText,
  Checkbox,
  MenuItem,
  FormControl,
  FormControlLabel,
  Card,
  Switch,
  Dialog,
  Avatar,
  ListItemAvatar,
  useMediaQuery,
  CardContent,
  Table,
  Select,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  Tooltip,
  TableRow,
  Button
} from "@mui/material";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PrintIcon from "@mui/icons-material/Print";
import ReciboCreditoLoja from "./ReciboCreditoLoja";
import PaymentIcon from "@mui/icons-material/Payment";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EmailIcon from "@mui/icons-material/Email";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ReceiptIcon from "@mui/icons-material/Receipt";
import CloseIcon from "@mui/icons-material/Close";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ModalCreditoLoja from "./ModalCreditoLoja"; // Caminho correto do arquivo
import InfoIcon from "@mui/icons-material/Info";
import { ThemeProvider, createTheme, useTheme } from "@mui/material/styles";

import { jsPDF } from "jspdf";
import "jspdf-autotable";




const estiloCabecalho = {
  color: "#fff",
  fontWeight: "bold",
  fontFamily: "Poppins, sans-serif",
  backgroundColor: "#1976d2",
  textAlign: "left",
  padding: "10px"
};
const estiloBotao = {
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  fontFamily: "Poppins, sans-serif",
  padding: "8px 16px",
  fontSize: "14px",
  fontWeight: "bold",
  textTransform: "none",
};


// Função para imprimir o recibo
const imprimirRecibo = (registro, fornecedorNome, empresaInfo) => {
  if (!fornecedorNome || !empresaInfo) {
    console.error("Erro: Informações do fornecedor estão ausentes!");
    return;
  }

  const recibo = new ReciboCreditoLoja({
    fornecedorNome,
    empresaInfo,
    valorTransferido: registro.valor,
    historicoCredito: [registro] // Passamos apenas o registro atual
  });

  recibo.imprimirRecibo(); // Chama a função do recibo
};


const EstiloModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2
};
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto", // Ajustado para 'auto' para acomodar o conteúdo
  maxWidth: "90%", // Limita a largura máxima para evitar a saída da tela
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowX: "auto" // Permite a rolagem horizontal se necessário
};
// Mova esta função para o início, antes de qualquer referência a ela
const verificarValor = valor => {
  // Verifica se o valor está definido e não é nulo
  if (valor !== undefined && valor !== null && valor !== "") {
    // Se for um número, formata como valor monetário
    if (typeof valor === "number") {
      return `R$ ${valor.toFixed(2)}`;
    }
    // Para outros tipos de dados, retorna o valor como está
    return valor;
  }
  // Se o valor estiver ausente, retorna "N/A"
  return "N/A";
};
const pdfBlobToBase64 = blob => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result.split(",")[1]);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

const enviarEmailComPDF = async (
  fornecedor,
  pdfBlob,
  empresaId,
  setSnackbarMessage,
  setOpenSnackbar,
  setSendingEmail
) => {
  const fornecedorRef = doc(
    db,
    "EMPRESAS",
    empresaId,
    "Cadastro de fornecedores",
    fornecedor.id
  );
  const fornecedorDoc = await getDoc(fornecedorRef);

  if (!fornecedorDoc.exists()) {
    setSnackbarMessage("Erro: Fornecedor não encontrado.");
    setOpenSnackbar(true);
    setSendingEmail(false);
    return;
  }

  const fornecedorData = fornecedorDoc.data();
  const fornecedorEmail = fornecedorData.email;

  if (!fornecedorEmail) {
    setSnackbarMessage("Erro: Fornecedor não possui email cadastrado.");
    setOpenSnackbar(true);
    setSendingEmail(false);
    return;
  }

  const attachmentBase64 = await pdfBlobToBase64(pdfBlob);

  const mailData = {
    to: fornecedorEmail,
    subject: `Recibo de Pagamento ${empresaId} para o fornecedor ${fornecedor.nome}`,
    text: "Segue em anexo o recibo de pagamento.",
    attachment: attachmentBase64,
    filename: `Recibo_${empresaId}_${fornecedor.nome}.pdf`
  };

  try {
    const response = await fetch(
      "https://us-central1-dona-cora.cloudfunctions.net/sendEmailWithPDF",
      {
        method: "POST",
        body: JSON.stringify(mailData),
        headers: {
          "Content-Type": "application/json"
        }
      }
    );

    if (!response.ok) {
      const responseText = await response.text();
      console.error("Response text:", responseText);
      throw new Error("Erro ao enviar email.");
    } else {
      setSnackbarMessage("Email enviado com sucesso!");
      setOpenSnackbar(true);
    }
  } catch (error) {
    console.error("Error:", error);
    setSnackbarMessage("Erro ao enviar email: " + error.message);
    setOpenSnackbar(true);
  } finally {
    setSendingEmail(false);
  }
};


const FinanceiroFornecedores = () => {
  const theme = useTheme();
  const { userDetails } = useContext(AuthContext);
  const empresaId = userDetails.empresaId;
  const [fornecedores, setFornecedores] = useState([]);
  
  const [fornecedorSelecionado, setFornecedorSelecionado] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedVenda, setSelectedVenda] = useState(null); // Estado para a venda selecionada
  const [openModal, setOpenModal] = useState(false); // Estado para controlar a abertura do modal
  const [mostrarModalRecibo, setMostrarModalRecibo] = useState(false);
  const [filtrarVendas20Dias, setFiltrarVendas20Dias] = useState(false);
  const [mostrarRecibo, setMostrarRecibo] = useState(false);
  const [usarFiltradas, setUsarFiltradas] = useState(false);
  const [mostrarModalEscolhaFormato, setMostrarModalEscolhaFormato] = useState(
    false
  );
  const [dadosRecibo, setDadosRecibo] = useState([]);
  const [fornecedorIdSelecionado, setFornecedorIdSelecionado] = useState("");
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [etapaAtual, setEtapaAtual] = useState("pagamento");
  const [dataInicio, setDataInicio] = useState("");
  const [observacao, setObservacao] = useState("");
  const [formaPagamento, setFormaPagamento] = useState("");
  const [formatoRelatorio, setFormatoRelatorio] = useState("");
  const [mostrarOpcoesRecibo, setMostrarOpcoesRecibo] = useState(false);
  const [dataFim, setDataFim] = useState("");
  const [selecionados, setSelecionados] = useState([]);
  const [openPagamentoModal, setOpenPagamentoModal] = useState(false);
  const [deveGerarRecibo, setDeveGerarRecibo] = useState(false);
  const [arquivoAnexo, setArquivoAnexo] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState("");
  const [sendingEmail, setSendingEmail] = useState(false);
  const [mostrarAviso, setMostrarAviso] = useState(false);
  const [openHistoricoDialog, setOpenHistoricoDialog] = useState(false);
  const [historicoSelecionado, setHistoricoSelecionado] = useState([]);
  const [gerandoRelatorio, setGerandoRelatorio] = useState(false);


  // Função para abrir o modal com o histórico do crédito
  const abrirHistorico = (historico) => {
    setHistoricoSelecionado(historico);
    setOpenHistoricoDialog(true);
  };
  
  // Limite de resultados por carregamento (ex: 50 fornecedores por vez)
  const limiteFornecedores = 50;
  // 🔹 Certifique-se de que a função está declarada antes de ser usada
  const converterTimestampParaDate = (dataVenda) => {
    if (!dataVenda) return null;

    // Se for um objeto Timestamp do Firestore
    if (dataVenda.seconds && dataVenda.nanoseconds) {
      return new Date(dataVenda.seconds * 1000);
    }

    // Se já for um objeto Date
    if (dataVenda instanceof Date) {
      return dataVenda;
    }

    // Se for uma string de data válida
    if (typeof dataVenda === "string") {
      const parsedDate = new Date(dataVenda);
      return isNaN(parsedDate.getTime()) ? null : parsedDate;
    }

    console.error("Formato de data não reconhecido:", dataVenda);
    return null;
  };

  // 🔹 Declaração do useState antes de qualquer uso
  const [vendasFornecedor, setVendasFornecedor] = useState([]);

  // 🔹 Verifica se a venda tem mais de 20 dias
  const vendaMaisDe20Dias = (dataVenda) => {
    const dataVendaFormatada = converterTimestampParaDate(dataVenda);
    if (!dataVendaFormatada) return false;

    const hoje = new Date();
    hoje.setHours(0, 0, 0, 0);
    dataVendaFormatada.setHours(0, 0, 0, 0);

    const diferencaDias = Math.floor((hoje - dataVendaFormatada) / (1000 * 3600 * 24));
    return diferencaDias > 20;
  };

  // 🔹 UseMemo corrigido para evitar erros de referência
  const vendasFiltradas = useMemo(() => {
    if (!Array.isArray(vendasFornecedor)) return [];

    return vendasFornecedor.filter(venda => {
      const dataVendaDate = converterTimestampParaDate(venda.dataVenda);
      return !filtrarVendas20Dias || vendaMaisDe20Dias(dataVendaDate);
    });
  }, [vendasFornecedor, filtrarVendas20Dias]);

  const [openCreditoModal, setOpenCreditoModal] = useState(false);
  const [documentosSelecionados, setDocumentosSelecionados] = useState([]);

  const abrirModalCreditoLoja = () => {
      if (selecionados.length === 0) {
          alert("Selecione pelo menos um documento.");
          return;
      }

      const docsSelecionados = vendasFornecedor.filter(venda => selecionados.includes(venda.id));
      setDocumentosSelecionados(docsSelecionados);
      setOpenCreditoModal(true);
  };

  useEffect(() => {
    const carregarFornecedores = async () => {
      setLoading(true);
      try {
        // 🔹 Referência da coleção de fornecedores
        const fornecedoresRef = collection(db, `EMPRESAS/${empresaId}/Cadastro de fornecedores`);
        const querySnapshot = await getDocs(fornecedoresRef);

        const fornecedoresList = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const fornecedorData = { id: doc.id, ...doc.data() };

            const contasRef = collection(db, `EMPRESAS/${empresaId}/Cadastro de fornecedores/${doc.id}/Conta`);
            const contasSnapshot = await getDocs(contasRef);

            const contasEmAberto = contasSnapshot.docs.filter(contaDoc => contaDoc.data().statusPagamento === 'Aguardando Pagamento');
            const contasParcialmentePagas = contasSnapshot.docs.filter(contaDoc => contaDoc.data().statusPagamento === 'Parcialmente Pago');

            // 🔹 Aqui está a nova lógica para somar valores em aberto
            const totalEmAberto = contasEmAberto.reduce((soma, contaDoc) => {
              return soma + (parseFloat(contaDoc.data().totalCaixaFornecedor) || 0);
            }, 0);

            fornecedorData.contasEmAberto = contasEmAberto.length;
            fornecedorData.contasParcialmentePagas = contasParcialmentePagas.length;
            fornecedorData.totalEmAberto = totalEmAberto;

            return fornecedorData;
          })
        );


        // 🔹 Verifica se há fornecedores com contas em aberto ou parcialmente pagas
        const fornecedoresComPendencias = fornecedoresList.some(fornecedor => fornecedor.contasEmAberto > 0 || fornecedor.contasParcialmentePagas > 0);
        setMostrarAviso(fornecedoresComPendencias); // Mostra o aviso se houver documentos pendentes
        setFornecedores(fornecedoresList);

      } catch (error) {
        console.error("Erro ao carregar fornecedores:", error);
      } finally {
        setLoading(false);
      }
    };

    carregarFornecedores();
  }, [empresaId]);


  // Otimiza a renderização dos fornecedores com useMemo
  const fornecedoresMemo = useMemo(() => {
    return fornecedores.map(fornecedor => ({
      ...fornecedor,
      temContasEmAberto: fornecedor.contasEmAberto > 0
    }));
  }, [fornecedores]);

  // Se estiver carregando, mostre o indicador de carregamento
  if (loading) {
    return <CircularProgress />;
  }


  const carregarVendasFornecedor = async (
    fornecedorId,
    dataInicio,
    dataFim,
    incluirDataFim = true
  ) => {
    setLoading(true);

    // Converter string para objeto Date e definir a hora para o início do dia (00:00:00)
    const dataInicioDate = dataInicio ? new Date(dataInicio) : new Date(0);
    dataInicioDate.setHours(0, 0, 0, 0);

    // Converter string para objeto Date e definir a hora para o fim do dia (23:59:59) se incluirDataFim for true
    const dataFimDate = dataFim ? new Date(dataFim) : new Date();
    if (incluirDataFim) {
      dataFimDate.setHours(23, 59, 59, 999);
    }

    const vendasRef = collection(
      db,
      `EMPRESAS/${empresaId}/Cadastro de fornecedores/${fornecedorId}/Conta`
    );
    const vendasSnapshot = await getDocs(vendasRef);
    const vendasList = vendasSnapshot.docs
      .map(doc => {
        const vendaData = doc.data();
        vendaData.dataVenda = vendaData.dataVenda
          ? new Date(vendaData.dataVenda.seconds * 1000)
          : null;
        // Verificação para garantir que `produtos` seja um array
        vendaData.produtos = Array.isArray(vendaData.produtos) 
          ? vendaData.produtos.map(produto => ({
              ...produto,
              valorVenda: produto.valorVenda ? produto.valorVenda.toString() : "0"
            }))
          : [];
        return { id: doc.id, ...vendaData };
      })
      .filter(
        venda =>
          venda.dataVenda &&
          venda.dataVenda >= dataInicioDate &&
          venda.dataVenda <= dataFimDate
      );

    setVendasFornecedor(vendasList);
    setLoading(false);
  };

  const handleChangeFornecedor = (event) => {
    const fornecedorId = event.target.value;
    const fornecedor = fornecedores.find(f => f.id === fornecedorId);

    if (fornecedor) {
      setFornecedorSelecionado(fornecedor); // Atualiza o fornecedor selecionado corretamente
      setFornecedorIdSelecionado(fornecedorId); // Garante que o campo select reflita essa escolha
      carregarVendasFornecedor(fornecedorId); // Carrega os dados relacionados ao fornecedor
    }
  };

  const handleGerarReciboClick = async () => {
      setSendingEmail(true);
      const doc = new jsPDF();
      doc.setFont("helvetica");
      doc.setFontSize(10);

      let currentY = 20;

      doc.setFontSize(16).setFont(undefined, "bold");
      doc.text(`Empresa: ${empresaId}`, 105, currentY, null, null, "center");
      currentY += 10;

      doc.setFontSize(14);
      doc.text("Recibo de Pagamento", 105, currentY, null, null, "center");
      currentY += 10;

      doc.setFontSize(10);
      doc.text(`ID do Pagamento: ${dadosRecibo[0]?.idPagamento || "N/A"}`, 20, currentY);
      currentY += 5;
      doc.text(`Data de Pagamento: ${new Date().toLocaleDateString()}`, 20, currentY);
      currentY += 5;
      doc.text(`Fornecedor: ${fornecedorSelecionado?.nome || "N/A"}`, 20, currentY);
      currentY += 5;
      doc.text(`Método de Pagamento: ${formaPagamento || "N/A"}`, 20, currentY);
      currentY += 5;

      if (observacao) {
        doc.text(`Observação: ${observacao}`, 20, currentY);
        currentY += 5;
      }

      let totalRepassadoGeral = 0;
      let totalCreditoUtilizado = 0;
      let totalValorOriginal = 0;
      let totalValorDevido = 0;

      dadosRecibo.forEach(venda => {
        doc.setFont(undefined, "bold");
        doc.text(`ID Venda: ${venda.vendaId || "N/A"}`, 20, currentY);
        currentY += 5;

        if (Array.isArray(venda.produtos) && venda.produtos.length > 0) {
          const titles = ["Produto", "Valor", "Qtd", "Desconto", "Total", "Pago"];
          const columnStarts = [25, 55, 80, 120, 150, 180];
          doc.setFontSize(10).setFont(undefined, "normal");

          titles.forEach((title, index) => {
            doc.text(title, columnStarts[index], currentY, { align: "left" });
          });
          currentY += 5;

          venda.produtos.forEach(produto => {
            const produtoDetalhes = [
              produto.descricao || "N/A",
              formatCurrency(parseFloat(produto.valorVenda)),
              produto.quantidade.toString() || "0",
              `${produto.desconto}` || "0",
              formatCurrency(parseFloat(produto.precoFinal)),
              formatCurrency(parseFloat(produto.repasseFornecedor))
            ];

            produtoDetalhes.forEach((detail, i) => {
              doc.text(detail, columnStarts[i], currentY, { align: "left" });
            });
            currentY += 5;

            totalRepassadoGeral += parseFloat(produto.repasseFornecedor) || 0;
          });

          totalValorOriginal += venda.valorOriginal;
          totalCreditoUtilizado += venda.valorOriginal - venda.valorDevido;
          totalValorDevido += venda.valorDevido;
        } else {
          console.error("Venda sem produtos ou 'produtos' não é um array.");
        }
      });

      doc.setFont(undefined, "bold");
      currentY += 10;
      doc.text(`Valor Original: ${formatCurrency(totalValorOriginal)}`, 20, currentY);
      currentY += 5;
      doc.text(`Crédito Utilizado: ${formatCurrency(totalCreditoUtilizado)}`, 20, currentY);
      currentY += 5;
      doc.text(`Total Pago: ${formatCurrency(totalRepassadoGeral)}`, 20, currentY);
      currentY += 5;
      doc.text(`Valor Ainda Devido: ${formatCurrency(totalValorDevido)}`, 20, currentY);

      const pdfBlob = doc.output("blob");

      await enviarEmailComPDF(
        fornecedorSelecionado,
        pdfBlob,
        empresaId,
        setSnackbarMessage,
        setOpenSnackbar,
        setSendingEmail
      );
  };


  const gerarReciboPDF = () => {
      if (!Array.isArray(dadosRecibo) || dadosRecibo.length === 0) {
          console.error("dadosRecibo está vazio ou não é um array.");
          return;
      }

      const doc = new jsPDF();
      doc.setFont("helvetica");

      const dataPagamentoFormatada = new Date().toLocaleDateString("pt-BR");
      const nomeFornecedor = fornecedorSelecionado?.nome || "Desconhecido";
      let currentY = 15;

      doc.setFontSize(18).setFont(undefined, "bold");
      doc.text("RECIBO DE PAGAMENTO", 105, currentY, { align: "center" });
      currentY += 10;

      doc.setFontSize(12).setFont(undefined, "normal");
      doc.text(`Empresa: ${empresaId}`, 20, currentY);
      doc.text(`Fornecedor: ${nomeFornecedor}`, 20, currentY + 7);
      doc.text(`Data de Emissão: ${dataPagamentoFormatada}`, 20, currentY + 14);
      doc.text(`Método de Pagamento: ${formaPagamento || "N/A"}`, 20, currentY + 21);
      currentY += 30;

      if (observacao) {
          doc.setFont(undefined, "italic");
          doc.text(`Observação: ${observacao}`, 20, currentY);
          currentY += 10;
      }

      let totalPago = 0;
      let totalCreditoUtilizado = 0;
      let totalOriginal = 0;

      // 🔹 Criando Tabela de Produtos Vendidos
      dadosRecibo.forEach(venda => {
          doc.setFontSize(12).setFont(undefined, "bold");
          doc.text(`ID Venda: ${venda.idVenda || "N/A"}`, 20, currentY);
          currentY += 7;

          if (Array.isArray(venda.produtos) && venda.produtos.length > 0) {
              const colunas = [
                  { title: "Produto", dataKey: "descricao", width: 50 },
                  { title: "Qtd", dataKey: "quantidade", width: 15 },
                  { title: "Valor", dataKey: "valorVenda", width: 25, halign: "right" },
                  { title: "Total", dataKey: "precoFinal", width: 30, halign: "right" },
                  { title: "Repasse", dataKey: "repasseFornecedor", width: 35, halign: "right" }
              ];

              const dadosTabela = venda.produtos.map(produto => {
                  totalOriginal += parseFloat(produto.repasseFornecedor) || 0;
                  return {
                      descricao: produto.descricao || "N/A",
                      quantidade: produto.quantidade.toString() || "0",
                      valorVenda: formatCurrency(parseFloat(produto.valorVenda)),
                      precoFinal: formatCurrency(parseFloat(produto.precoFinal)),
                      repasseFornecedor: formatCurrency(parseFloat(produto.repasseFornecedor))
                  };
              });

              doc.autoTable({
                  startY: currentY,
                  head: [colunas.map(col => col.title)],
                  body: dadosTabela.map(d => Object.values(d)),
                  theme: "striped",
                  styles: { fontSize: 10, cellPadding: 3 },
                  columnStyles: {
                      0: { cellWidth: 50 },
                      1: { halign: "center", cellWidth: 15 },
                      2: { halign: "right", cellWidth: 25 },
                      3: { halign: "right", cellWidth: 30 },
                      4: { halign: "right", cellWidth: 35 }
                  },
                  headStyles: { fillColor: [44, 62, 80], textColor: [255, 255, 255], fontStyle: "bold" }
              });

              currentY = doc.lastAutoTable.finalY + 10;
          }

          // 🔹 Calculando Crédito Utilizado
          if (Array.isArray(venda.historicoCredito)) {
              venda.historicoCredito.forEach(credito => {
                  totalCreditoUtilizado += parseFloat(credito.valor) || 0;
              });
          }

          // 🔹 Somando corretamente o totalPago de cada documento
        totalPago += parseFloat(venda.totalPago) || 0;

      });

      // 🔹 Rodapé com totais ajustados
      doc.setFontSize(12).setFont(undefined, "bold");
      doc.text(`Valor Original (Total Repasse): ${formatCurrency(totalOriginal)}`, 20, currentY);
      currentY += 7;
      doc.text(`Crédito Utilizado: ${formatCurrency(totalCreditoUtilizado)}`, 20, currentY);
      currentY += 7;
      doc.text(`Total Pago: ${formatCurrency(totalPago)}`, 20, currentY);
      currentY += 10;

      doc.setFontSize(10).setFont(undefined, "normal");
      doc.text(`Este documento é gerado automaticamente pelo sistema e não necessita de assinatura.`, 20, currentY);

      // 🔹 Salvando o PDF
      const nomeArquivo = `Recibo_${nomeFornecedor}_${dataPagamentoFormatada.replace(/\//g, "-")}.pdf`;
      console.log(`Salvando PDF como ${nomeArquivo}...`);
      doc.save(nomeArquivo);
  };




  // Função para garantir que a string pode ter .replace() chamado sobre ela
  const safeReplace = (str, pattern, replacement) => {
    if (str === undefined || str === null) {
      return ""; // ou algum valor padrão que faça sentido para seu contexto
    }
    return str.replace(pattern, replacement);
  };
  const gerarRelatorioFornecedor = (formato = "PDF") => {
      console.log("Gerando relatório no formato:", formato);

      if (selecionados.length === 0) {
          alert("Selecione pelo menos um documento para gerar o relatório.");
          return;
      }

      const vendasParaRelatorio = vendasFornecedor.filter(venda => selecionados.includes(venda.id));

      if (vendasParaRelatorio.length === 0) {
          alert("Nenhum documento válido foi encontrado para gerar o relatório.");
          return;
      }

      if (formato === "PDF") {
          const doc = new jsPDF();
          doc.setFont("helvetica");

          const dataAtual = new Date().toLocaleDateString("pt-BR");
          const nomeFornecedor = fornecedorSelecionado ? fornecedorSelecionado.nome : "Desconhecido";

          doc.setFontSize(14).setFont(undefined, "bold");
          doc.text("RELATÓRIO DE VENDAS DO FORNECEDOR", 105, 15, { align: "center" });

          doc.setFontSize(11).setFont(undefined, "normal");
          doc.text(`Empresa: ${empresaId}`, 20, 25);
          doc.text(`Fornecedor: ${nomeFornecedor}`, 20, 32);
          doc.text(`Data de Emissão: ${dataAtual}`, 20, 39);

          let currentY = 50;
          let totalCreditoUtilizado = 0;
          let totalValorOriginal = 0;
          let totalValorDevido = 0;

          vendasParaRelatorio.forEach(venda => {
              doc.setFontSize(11).setFont(undefined, "bold");
              doc.text(`ID Venda: ${venda.idVenda || "N/A"}`, 20, currentY);
              doc.text(`Data: ${format(new Date(venda.dataVenda), "dd/MM/yyyy")}`, 160, currentY);
              currentY += 6;

              // 🔹 Somar crédito utilizado corretamente da array historicoCredito
              const historicoCredito = venda.historicoCredito || [];
              const creditoUsado = historicoCredito.reduce((total, item) => total + (item.valor || 0), 0);

              // 🔹 Somar valor original corretamente (Soma dos `repasseFornecedor` de todos os produtos)
              const valorOriginal = venda.produtos
                  ? venda.produtos.reduce((total, produto) => total + (produto.repasseFornecedor || 0), 0)
                  : 0;

              if (Array.isArray(venda.produtos) && venda.produtos.length > 0) {
                  const colunas = [
                      { title: "Produto", dataKey: "descricao" },
                      { title: "Qtd", dataKey: "quantidade" },
                      { title: "Valor", dataKey: "valorVenda" },
                      { title: "Desc", dataKey: "desconto" },
                      { title: "Total", dataKey: "precoFinal" },
                      { title: "Repasse", dataKey: "repasseFornecedor" }
                  ];

                  const dadosTabela = venda.produtos.map(produto => ({
                      descricao: produto.descricao || "N/A",
                      quantidade: produto.quantidade.toString() || "0",
                      valorVenda: formatCurrency(parseFloat(produto.valorVenda)),
                      desconto: produto.desconto ? `${produto.desconto}%` : "0%",
                      precoFinal: formatCurrency(parseFloat(produto.precoFinal)),
                      repasseFornecedor: formatCurrency(parseFloat(produto.repasseFornecedor))
                  }));

                doc.autoTable({
                    startY: currentY,
                    head: [colunas.map(col => col.title)],
                    body: dadosTabela.map(d => Object.values(d)),
                    theme: "striped",
                    styles: { fontSize: 7, cellPadding: 3 }, // Aumentando padding interno
                    columnStyles: {
                        1: { halign: "center", cellWidth: 20 }, // Quantidade centralizada
                        2: { halign: "right", cellWidth: 30 }, // Valor
                        3: { halign: "center", cellWidth: 20 }, // Desconto
                        4: { halign: "left", cellWidth: 35 }, // 🔹 Aumentando largura da coluna "Total"
                        5: { halign: "left", cellWidth: 40 }, // Repasse mais espaçado
                    },
                    headStyles: { fillColor: [44, 62, 80], textColor: [255, 255, 255], fontStyle: "bold" }
                });


                  currentY = doc.lastAutoTable.finalY + 10;

                  totalValorOriginal += valorOriginal; // ✅ Soma corretamente os valores de `repasseFornecedor`
                  totalCreditoUtilizado += creditoUsado; // ✅ Soma corretamente os valores de `historicoCredito`
                  totalValorDevido += venda.totalCaixaFornecedor || 0; // ✅ Soma corretamente `totalCaixaFornecedor`
              }
          });

          doc.setFont(undefined, "bold");
          currentY += 10; // 🔹 Ajuste fino para evitar quebras de linha
          doc.text(`Valor Original:`, 20, currentY);
          doc.text(formatCurrency(totalValorOriginal), 160, currentY, { align: "right" });

          currentY += 6;
          doc.text(`Crédito Utilizado:`, 20, currentY);
          doc.text(formatCurrency(totalCreditoUtilizado), 160, currentY, { align: "right" });

          currentY += 6;
          doc.text(`Total Devido (Total a Pagar):`, 20, currentY);
          doc.text(formatCurrency(totalValorDevido), 160, currentY, { align: "right" });

          const nomeArquivo = `Relatorio_Vendas_${nomeFornecedor}_${dataAtual.replace(/\//g, "-")}.pdf`;
          doc.save(nomeArquivo);
      }
  };






  const formatarLinhaCSV = dados => {
    // Assegurar que todos os números grandes sejam tratados como strings.
    return dados
      .map(dado => {
        if (typeof dado === "number" && !Number.isInteger(dado)) {
          return `"${dado.toFixed(2)}"`;
        }
        if (typeof dado === "number" && dado > 1e9) {
          return `"${dado.toString()}"`;
        }
        return `"${String(dado).replace(/"/g, '""')}"`;
      })
      .join(";");
  };

  const exportarDadosParaCSV = (vendasParaRelatorio) => {
    let linhas = [
      ["ID Venda", "ID Pagamento", "Nome do Fornecedor", "Descrição do Produto", "Data da Venda", "Status de Pagamento", "Valor Repassado"]
    ];

    // Adicionando dados apenas dos documentos selecionados
    vendasParaRelatorio.forEach(venda => {
      venda.produtos.forEach(produto => {
        const totalCaixaFornecedorFormatado =
          produto.repasseFornecedor !== undefined
            ? produto.repasseFornecedor
                .toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
                .replace("R$", "R$ ")
            : "N/A";

        const linha = [
          `"${venda.idVenda}"`,
          `"${venda.idPagamento}"`,
          `"${fornecedorSelecionado.nome}"`,
          produto.descricao,
          format(new Date(venda.dataVenda), "dd/MM/yyyy"),
          venda.statusPagamento,
          totalCaixaFornecedorFormatado
        ];
        linhas.push(linha);
      });
    });

    const csvContent = "\uFEFF" + linhas.map(e => e.join(";")).join("\n");
    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `Relatorio_Vendas_${empresaId}_${format(new Date(), "dd-MM-yyyy")}.csv`);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const formatCurrency = value => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL"
    }).format(value);
  };
  // Função auxiliar para garantir que o valor possa ser formatado
  const formatarValor = valor => {
    const num = parseFloat(valor);
    return !isNaN(num) ? num.toFixed(2) : "0.00";
  };
  const selecionarFornecedor = fornecedor => {
    setFornecedorSelecionado(fornecedor.nome);
    carregarVendasFornecedor(fornecedor.id);
  };
  const handleSelect = id => {
    const isSelected = selecionados.includes(id);
    setSelecionados(
      isSelected
        ? selecionados.filter(item => item !== id)
        : [...selecionados, id]
    );
  };
  const abrirDetalhesVenda = venda => {
    console.log("Venda Selecionada para Detalhes: ", venda);
    setSelectedVenda({
      ...venda,
      produtos: venda.produtos.map(produto => ({
        ...produto,
        valorVenda: produto.valorVenda ? produto.valorVenda.toString() : "0"
      }))
    });
    setOpenModal(true);
  };
  

  const realizarUploadAnexo = async arquivo => {
    const storage = getStorage();
    const storageRef = ref(
      storage,
      `${empresaId}/anexos de pagamento/${arquivo.name}`
    );

    await uploadBytes(storageRef, arquivo);
    console.log("Arquivo enviado com sucesso");

    const url = await getDownloadURL(storageRef);
    return url; // Retorna a URL do arquivo no storage para ser salva junto ao pagamento
  };
  // Calcula o total selecionado para pagamento
  const calcularTotalSelecionado = () => {
    return selecionados
      .reduce((total, vendaId) => {
        const venda = vendasFornecedor.find(venda => venda.id === vendaId);
        return total + (venda ? Number(venda.totalCaixaFornecedor) || 0 : 0);
      }, 0)
      .toFixed(2);
  };
  const totalSelecionado = calcularTotalSelecionado();
  const realizarPagamento = async () => {
      if (!fornecedorSelecionado || !fornecedorSelecionado.id) {
          console.error("ID do fornecedor não definido.");
          return;
      }

      let urlAnexo = "";
      if (arquivoAnexo) {
          urlAnexo = await realizarUploadAnexo(arquivoAnexo);
      }

      const batch = writeBatch(db);
      const pagamentoId = Date.now().toString(); // Gerando um ID único de pagamento

      const fornecedorPagamentosRef = collection(
          db,
          `EMPRESAS/${empresaId}/Cadastro de fornecedores/${fornecedorSelecionado.id}/Pagamentos`
      );

      let totalPago = 0;
      const vendasPagasData = [];

      for (const vendaId of selecionados) {
          const contaRef = doc(
              db,
              `EMPRESAS/${empresaId}/Cadastro de fornecedores/${fornecedorSelecionado.id}/Conta`,
              vendaId
          );
          const docSnap = await getDoc(contaRef);

          if (docSnap.exists()) {
              const vendaData = docSnap.data();

              // 🔹 Obtendo valores corretos para pagamento
              const valorOriginal = vendaData.valorOriginalFornecedor || vendaData.totalCaixaFornecedor;
              const valorDevido = vendaData.totalCaixaFornecedor;

              // 🔹 Mantendo o histórico de crédito corretamente
              const historicoCredito = Array.isArray(vendaData.historicoCredito) ? vendaData.historicoCredito : [];

              // 🔹 Mantemos `repasseFornecedor` apenas para exibição
              const produtosAtualizados = vendaData.produtos.map(produto => ({
                  ...produto,
                  repasseFornecedor: produto.repasseFornecedor
              }));

              // 🔹 Salvando a venda no array de pagamentos
              vendasPagasData.push({
                  vendaId: vendaId,
                  dataVenda: vendaData.dataVenda,
                  produtos: produtosAtualizados,
                  valorOriginal: valorOriginal,
                  valorDevido: valorDevido,
                  historicoCredito: historicoCredito,
                  statusPagamento: "PAGO",
                  observacao: observacao,
                  metodoPagamento: formaPagamento,
                  totalPago: vendaData.totalPago || valorDevido  
              });

              // 🔹 Criando um novo documento na coleção Pagamentos
              const pagamentoRef = doc(fornecedorPagamentosRef, vendaId);
              batch.set(pagamentoRef, {
                  ...vendaData,
                  statusPagamento: "PAGO",
                  idPagamento: pagamentoId,
                  dataPagamento: new Date(),
                  metodoPagamento: formaPagamento,
                  observacao: observacao,
                  urlAnexo: urlAnexo,
                  totalPago: vendaData.totalPago || valorDevido
              });

              // 🔹 Atualiza a venda na coleção principal
              const dataVenda = vendaData.dataVenda.toDate();
              const vendaRef = doc(
                  db,
                  `EMPRESAS/${empresaId}/Vendas/${dataVenda.getFullYear()}/${dataVenda.getMonth() + 1}/${dataVenda.getDate()}/Vendas/${vendaId}`
              );

              const vendaDocSnap = await getDoc(vendaRef);
              if (vendaDocSnap.exists()) {
                  const vendaDocData = vendaDocSnap.data();
                  const produtosPorFornecedorAtualizados = vendaDocData.produtosPorFornecedor?.[
                      fornecedorSelecionado.id
                  ]?.map(produto => ({
                      ...produto,
                      repasseFornecedor: produto.repasseFornecedor
                  }));

                  batch.update(vendaRef, {
                      [`produtosPorFornecedor.${fornecedorSelecionado.id}`]: produtosPorFornecedorAtualizados
                  });
              }

              // 🔹 Agora podemos deletar a conta do fornecedor, pois já movemos para Pagamentos
              batch.delete(contaRef);

              // 🔹 Soma o valor devido ao total pago
              totalPago += valorDevido;
          } else {
              console.error("Venda não encontrada:", vendaId);
          }
      }

      await batch.commit();

      // 🔹 Atualiza o estado para exibir o recibo
      setDadosRecibo(vendasPagasData.map(venda => ({
          ...venda,
          totalPago: venda.totalPago || venda.totalCaixaFornecedor || 0, 
      })));

      setEtapaAtual("opcoesRecibo");
      setOpenPagamentoModal(true);

      // 🔹 Recarrega os dados após o pagamento
      carregarVendasFornecedor(fornecedorSelecionado.id);
  };




  const atualizarStatusPagamento = async (
    fornecedorId,
    vendaId,
    novoStatus
  ) => {
    const vendaRef = doc(
      db,
      `EMPRESAS/${empresaId}/Cadastro de fornecedores/${fornecedorId}/Conta`,
      vendaId
    );
    await updateDoc(vendaRef, { statusPagamento: novoStatus });
    carregarVendasFornecedor(fornecedorId);
  };

  const abrirModalEscolhaFormato = () => {
    if (selecionados.length === 0) {
      alert("Selecione ao menos um documento para gerar o relatório.");
      return;
    }
    setMostrarModalEscolhaFormato(true);
  };


  const handleOpenModal = venda => {
    setSelectedVenda(venda);
    setOpenModal(true); // Certifique-se de que este estado controla a visibilidade do modal de detalhes da venda
  };
  // Função para fechar o modal de detalhes da venda
  const handleCloseDetalhesModal = () => {
    setOpenModal(false); // Atualiza o estado para fechar o modal de detalhes da venda
    setSelectedVenda(null); // Opcional: limpar os detalhes da venda selecionada
  };

  const handleOpenModalPagamento = () => {
    setOpenPagamentoModal(true);
  };
  const handleCloseModal = () => {
    setOpenPagamentoModal(false);
    setMostrarModalRecibo(false);
    setEtapaAtual("pagamento"); // Redefinir para o estado inicial
  };

  const handleCloseReciboModal = () => {
    setMostrarModalRecibo(false);
    setEtapaAtual("pagamento");
    // Outros estados que precisam ser resetados podem ser incluídos aqui
  };
  const resetarEstadoPagamento = () => {
    setOpenPagamentoModal(false); // Fecha o modal
    setEtapaAtual("pagamento"); // Prepara para um novo pagamento
    setDadosRecibo([]); // Limpa os dados do recibo
    setSelecionados([]);
    setObservacao("");
    setFormaPagamento("transferencia"); // Ou outra valor padrão que você deseja
    setArquivoAnexo(null);
    // Outros estados que precisam ser resetados podem ser incluídos aqui
  };

  const iniciarNovoPagamento = () => {
    // Resetar todos os estados relevantes ao processo de pagamento
    setSelecionados([]);
    setObservacao("");
    setFormaPagamento("");
    setArquivoAnexo(null);
    setEtapaAtual("pagamento"); // Garantir que estamos na etapa inicial do pagamento
    setOpenPagamentoModal(true); // Abrir o modal de pagamento
  };
  const fecharModalRecibo = () => {
    setOpenPagamentoModal(false); // Fecha o modal de pagamento/recibo
    setEtapaAtual("pagamento"); // Resetar para o estado inicial de pagamento
    setDadosRecibo({}); // Limpar os dados de recibo para o próximo pagamento
    // Resetar outros estados relevantes, se necessário
    setSelecionados([]);
    setObservacao("");
    setFormaPagamento("");
    setArquivoAnexo(null);
  };

  const proximoPagamento = new Date();
  proximoPagamento.setDate(6); // Ajusta para o dia 6 do próximo mês
  if (proximoPagamento.getDate() <= new Date().getDate()) {
    proximoPagamento.setMonth(proximoPagamento.getMonth() + 1);
  }

  if (loading) {
    return <CircularProgress />;
  }
  // Calculando Valores em Aberto
  const valoresEmAberto = vendasFornecedor
    .filter(venda => venda.statusPagamento === "Aguardando Pagamento")
    .reduce((acc, cur) => acc + parseFloat(cur.totalCaixaFornecedor), 0);

  // Encontrando a Última Venda
  const ultimaVenda =
    vendasFornecedor.sort((a, b) => b.dataVenda - a.dataVenda)[0]
      ?.totalCaixaFornecedor || 0;
  // Função para carregar pagamentos efetuados
  const carregarPagamentosEfetuados = async fornecedorId => {
    setLoading(true);
    const pagamentosRef = collection(
      db,
      `EMPRESAS/${empresaId}/Cadastro de fornecedores/${fornecedorId}/Pagamentos`
    );
    const q = query(
      pagamentosRef,
      where("dataPagamento", ">=", new Date(dataInicio)),
      where("dataPagamento", "<=", new Date(dataFim))
    );

    const pagamentosSnapshot = await getDocs(q);
    const pagamentos = pagamentosSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    setLoading(false);
  };

  const Recibo = ({ dadosRecibo }) => (
    <Box sx={{ padding: 2, backgroundColor: "#fff", borderRadius: 2 }}>
      <Typography variant="h6">Recibo de Pagamento</Typography>
      <pre>{JSON.stringify(dadosRecibo, null, 2)}</pre>
    </Box>
  );


            return (
              <Box
                sx={{
                  backgroundColor: "#f5f5f5",
                  minHeight: "100vh",
                  paddingBottom: "20px"
                }}
              >
                    <Container
                      maxWidth="xl"
                      style={{
                        marginTop: "20px",
                        paddingLeft: isMdUp ? "220px" : "16px",
                        paddingRight: "16px",
                        maxWidth: isMdUp ? "calc(110% - 240px)" : "100%", // Ajustando o espaço restante da tela ao lado do sidebar
                      }}
                    >
                    {mostrarAviso && (
                      <Card
                        sx={{
                          backgroundColor: '#ffeb3b',
                          color: '#000',
                          padding: '8px', // Diminuindo padding
                          marginBottom: '8px', // Diminuindo marginBottom
                          display: 'flex',
                          minHeight: '50px',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Typography variant="body1"> {/* Diminuindo de h6 para body1 */}
                          Existem documentos de pagamento em aberto para um ou mais fornecedores.
                        </Typography>
                        <IconButton onClick={() => setMostrarAviso(false)}>
                          <CloseIcon />
                        </IconButton>
                      </Card>
                    )}

                    <Card
                      sx={{
                        padding: "8px", // Reduzindo o padding
                        borderRadius: "8px",
                        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)"
                      }}
                    >
                      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}> {/* De h4 para h5 */}
                        Contas dos Fornecedores
                      </Typography>

                      {fornecedores.length > 0 ? (
                                    <FormControl fullWidth sx={{ marginBottom: "16px" }}>
                                      <Select
                                        value={fornecedorIdSelecionado} // Agora sempre reflete o fornecedor selecionado corretamente
                                        onChange={handleChangeFornecedor}
                                        displayEmpty
                                        inputProps={{ "aria-label": "Sem rótulo" }}
                                        sx={{
                                          backgroundColor: "#fff",
                                          borderRadius: "8px",
                                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                                        }}
                                      >
                                        <MenuItem value="" disabled>
                                          Selecione um Fornecedor
                                        </MenuItem>
                                        {fornecedores
                                          .filter(f => f.contasEmAberto > 0 || f.contasParcialmentePagas > 0)
                                          .map(fornecedor => (
                                            <MenuItem key={fornecedor.id} value={fornecedor.id}>
                                              {fornecedor.nome}
                                              <Box
                                                sx={{
                                                  backgroundColor: "#f44336",
                                                  borderRadius: "12px",
                                                  color: "#fff",
                                                  padding: "0 6px",
                                                  marginLeft: "6px",
                                                  display: "inline-block",
                                                  fontSize: "0.8rem"
                                                }}
                                              >
                                                {fornecedor.contasEmAberto + fornecedor.contasParcialmentePagas}
                                              </Box>
                                            </MenuItem>
                                        ))}

                          </Select>
                        </FormControl>
                      ) : (
                        <Typography>Nenhum fornecedor encontrado.</Typography>
                      )}
                      <Card
                        variant="outlined"
                        sx={{
                          flex: 1,
                          backgroundColor: "#ede7f6",
                          boxShadow: 2,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 1,
                          borderRadius: "6px",
                          transition: "transform 0.2s",
                          "&:hover": {
                            transform: "scale(1.03)"
                          }
                        }}
                      >
                        <CardContent sx={{ textAlign: "center", padding: 0.5 }}>
                          <AttachMoneyIcon sx={{ fontSize: 22, color: "#673ab7" }} />
                          <Typography
                            variant="subtitle2"
                            component="div"
                            sx={{ fontFamily: "Poppins, sans-serif", mt: 0.5 }}
                          >
                            Total Geral em Aberto
                          </Typography>
                          <Typography variant="body1">
                            {formatCurrency(
                              fornecedores.reduce((total, f) => total + (f.totalEmAberto || 0), 0)
                            )}

                          </Typography>
                        </CardContent>
                      </Card>

                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                          gap: "8px", // Reduzindo o espaço entre os cards
                          marginBottom: "10px", // Reduzindo marginBottom
                          padding: 1 // Adicionando padding ao container
                        }}
                      >
                        <Card
                          variant="outlined"
                          sx={{
                            flex: 1,
                            backgroundColor: "#e3f2fd",
                            boxShadow: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 1, // Reduzindo o padding dos cards
                            borderRadius: "6px",
                            transition: "transform 0.2s",
                            "&:hover": {
                              transform: "scale(1.03)"
                            }
                          }}
                        >
                          <CardContent sx={{ textAlign: "center", padding: 0.5 }}> {/* Diminuindo padding do conteúdo */}
                            <PaymentIcon sx={{ fontSize: 22, color: "#1976d2" }} /> {/* Diminuindo ícone */}
                            <Typography variant="subtitle2" component="div" sx={{ fontFamily: "Poppins, sans-serif", mt: 0.5 }}> {/* De subtitle1 para subtitle2, marginTop reduzida */}
                              Selecionado para Pagamento
                            </Typography>
                            <Typography variant="body1"> {/* De h6 para body1 */}
                              {formatCurrency(totalSelecionado)}
                            </Typography>
                          </CardContent>
                        </Card>

                        {/* Reduzindo os demais cards da mesma forma */}
                        <Card
                          variant="outlined"
                          sx={{
                            flex: 1,
                            backgroundColor: "#ffebee",
                            boxShadow: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 1, // Reduzindo padding
                            borderRadius: "6px",
                            transition: "transform 0.2s",
                            "&:hover": {
                              transform: "scale(1.03)"
                            }
                          }}
                        >
                          <CardContent sx={{ textAlign: "center", padding: 0.5 }}> {/* Diminuindo padding */}
                            <AccountBalanceWalletIcon
                              sx={{ fontSize: 22, color: "#d32f2f" }} /> {/* Diminuindo ícone */}
                            <Typography variant="subtitle2" component="div" sx={{ fontFamily: "Poppins, sans-serif", mt: 0.5 }}>
                              Em Aberto
                            </Typography>
                            <Typography variant="body1">
                              {formatCurrency(formatarValor(valoresEmAberto))}
                            </Typography>
                          </CardContent>
                        </Card>

                        <Card
                          variant="outlined"
                          sx={{
                            flex: 1,
                            backgroundColor: "#e8f5e9",
                            boxShadow: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 1, // Reduzindo padding
                            borderRadius: "6px",
                            transition: "transform 0.2s",
                            "&:hover": {
                              transform: "scale(1.03)"
                            }
                          }}
                        >
                          <CardContent sx={{ textAlign: "center", padding: 0.5 }}>
                            <EventNoteIcon sx={{ fontSize: 22, color: "#388e3c" }} /> {/* Diminuindo ícone */}
                            <Typography variant="subtitle2" component="div" sx={{ fontFamily: "Poppins, sans-serif", mt: 0.5 }}>
                              Última Venda
                            </Typography>
                            <Typography variant="body1">
                              {formatCurrency(formatarValor(ultimaVenda))}
                            </Typography>
                          </CardContent>
                        </Card>

                        <Card
                          variant="outlined"
                          sx={{
                            flex: 1,
                            backgroundColor: "#fffde7",
                            boxShadow: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 1, // Reduzindo padding
                            borderRadius: "6px",
                            transition: "transform 0.2s",
                            "&:hover": {
                              transform: "scale(1.03)"
                            }
                          }}
                        >
                          <CardContent sx={{ textAlign: "center", padding: 0.5 }}>
                            <AccessTimeIcon sx={{ fontSize: 22, color: "#fbc02d" }} /> {/* Diminuindo ícone */}
                            <Typography variant="subtitle2" component="div" sx={{ fontFamily: "Poppins, sans-serif", mt: 0.5 }}>
                              Data de Próximo Pagamento
                            </Typography>
                            <Typography variant="body1">
                              {format(proximoPagamento, "dd/MM/yyyy")}
                            </Typography>
                          </CardContent>
                        </Card>
          </Box>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Data Início"
                type="date"
                value={dataInicio}
                onChange={e => setDataInicio(e.target.value)}
                InputLabelProps={{ shrink: true }}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  carregarVendasFornecedor(
                    fornecedorSelecionado.id,
                    dataInicio,
                    dataFim
                  )
                }
                sx={{
                  height: "100%",
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                }}
              >
                Buscar
              </Button>
            </Grid>
            

            <Grid item xs={12} md={4}>
              <FormControlLabel
                control={
                  <Switch
                    checked={filtrarVendas20Dias}
                    onChange={e => setFiltrarVendas20Dias(e.target.checked)}
                    sx={{ "& .MuiSwitch-thumb": { color: "primary.main" } }}
                  />
                }
                label="Filtrar vendas com mais de 20 dias"
                sx={{ fontWeight: "bold", fontFamily: "Poppins, sans-serif" }}
              />
            </Grid>

            <Grid container spacing={2} alignItems="center" sx={{ mt: 2 }}>
              {/* Botão de relatório do fornecedor */}
              <Grid item xs={12} md={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={abrirModalEscolhaFormato}
                  sx={{
                    borderRadius: "8px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "bold"
                  }}
                >
                  Relatório do Fornecedor
                </Button>
              </Grid>

              {/* Botão de relatório geral */}
              <Grid item xs={12} md={6}>
                <Button
                  fullWidth
                  variant="outlined"
                  startIcon={<PictureAsPdfIcon />}
                  onClick={async () => {
                    setGerandoRelatorio(true);
                    await gerarRelatorioGeralFornecedores(fornecedores, empresaId);
                    setGerandoRelatorio(false);
                  }}
                  sx={{
                    borderRadius: "8px",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "bold",
                    borderColor: "#1976d2",
                    color: "#1976d2",
                    "&:hover": {
                      backgroundColor: "#e3f2fd",
                      borderColor: "#1976d2"
                    }
                  }}
                >
                  Relatório Geral
                </Button>
              </Grid>
            </Grid>

            {/* Loader abaixo dos botões */}
            {gerandoRelatorio && (
              <Grid container justifyContent="center" sx={{ mt: 2 }}>
                <CircularProgress size={26} color="primary" />
                <Typography sx={{ ml: 2, fontSize: "0.9rem" }}>
                  Gerando relatório...
                </Typography>
              </Grid>
            )}




           


            <Grid item xs={12} md={6}>
              {selecionados.length > 0 && (
                <Button
                  fullWidth
                  variant="contained"
                  style={{ backgroundColor: "#4CAF50", color: "#FFF" }}
                  onClick={handleOpenModalPagamento}
                  sx={{
                    borderRadius: "8px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    fontFamily: "Poppins, sans-serif"
                  }}
                >
                  Gerar Pagamento
                </Button>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
                {selecionados.length > 0 && (
                    <Button
                        fullWidth
                        variant="contained"
                        style={{ backgroundColor: "#FFA726", color: "#FFF" }}
                        onClick={abrirModalCreditoLoja}
                        sx={{
                            borderRadius: "8px",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            fontFamily: "Poppins, sans-serif"
                        }}
                    >
                        Crédito em Loja
                    </Button>
                )}
            </Grid>
            <ModalCreditoLoja
                open={openCreditoModal}
                onClose={() => setOpenCreditoModal(false)}
                fornecedorId={fornecedorSelecionado?.id}
                documentosSelecionados={documentosSelecionados}
                empresaId={empresaId}
                recarregarDados={() => carregarVendasFornecedor(fornecedorSelecionado?.id)}
            />

          </Grid>
                      <>
                        {fornecedorSelecionado && (
                          <>
                            <Typography variant="h5" sx={{ mt: 3, mb: 2, fontWeight: "bold" }}>
                              Produtos vendidos de {fornecedorSelecionado.nome}
                            </Typography>

                            <Table sx={{ backgroundColor: "#fff", borderRadius: "8px", boxShadow: 3, overflow: "hidden" }}>
                              {/* Cabeçalho da Tabela */}
                              <TableHead sx={{ backgroundColor: "#1976d2" }}>
                                <TableRow>
                                  <TableCell sx={estiloCabecalho}>Selecionar</TableCell>
                                  <TableCell sx={estiloCabecalho}>Documento da Venda</TableCell>
                                  <TableCell sx={estiloCabecalho}>Data da Venda</TableCell>
                                  <TableCell sx={estiloCabecalho}>Valor Original</TableCell>
                                  <TableCell sx={estiloCabecalho}>Crédito Utilizado</TableCell>
                                  <TableCell sx={estiloCabecalho}>Valor Atualizado</TableCell>
                                  <TableCell sx={estiloCabecalho}>Status do Pagamento</TableCell>
                                  <TableCell sx={estiloCabecalho}>Detalhes</TableCell>
                                </TableRow>
                              </TableHead>

                              {/* Corpo da Tabela */}
                              <TableBody>
                                {vendasFiltradas.map((venda) => {
                                  const historicoCredito = venda.historicoCredito || [];
                                  const totalCreditoUsado = historicoCredito.reduce((acc, item) => acc + item.valor, 0);

                                  return (
                                    <TableRow key={venda.id}>
                                      {/* Checkbox para Seleção */}
                                      <TableCell padding="checkbox">
                                        <Checkbox
                                          checked={selecionados.includes(venda.id)}
                                          onChange={() => handleSelect(venda.id)}
                                        />
                                      </TableCell>

                                      {/* Documento da Venda */}
                                      <TableCell>{venda.idVenda}</TableCell>

                                      {/* Data da Venda */}
                                      <TableCell>{format(new Date(venda.dataVenda), "dd/MM/yyyy")}</TableCell>

                                      {/* Valor Original (do campo correto) */}
                                      <TableCell>{formatCurrency(venda.valorOriginalFornecedor || venda.totalCaixaFornecedor)}</TableCell>

                                      {/* Crédito Utilizado */}
                                      <TableCell sx={{ minWidth: "150px" }}>
                                        {totalCreditoUsado > 0 ? (
                                          <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                            <Tooltip title="Clique para ver o histórico" arrow>
                                              <span
                                                onClick={() => abrirHistorico(historicoCredito)}
                                                style={{ textDecoration: "underline", cursor: "pointer", color: "red" }}
                                              >
                                                - {formatCurrency(totalCreditoUsado)}
                                              </span>
                                            </Tooltip>
                                            <IconButton size="small" onClick={() => abrirHistorico(historicoCredito)}>
                                              <InfoIcon fontSize="small" color="primary" />
                                            </IconButton>
                                          </Box>
                                        ) : (
                                          "-"
                                        )}
                                      </TableCell>

                                      {/* Valor Atualizado (deve ser o totalCaixaFornecedor atual) */}
                                      <TableCell>{formatCurrency(venda.totalCaixaFornecedor)}</TableCell>

                                      {/* Status do Pagamento */}
                                      <TableCell>{venda.statusPagamento}</TableCell>

                                      {/* Botão de Detalhes */}
                                      <TableCell>
                                        <Button variant="outlined" color="primary" onClick={() => handleOpenModal(venda)} sx={estiloBotao}>
                                          Ver Produtos
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>

                            </Table>
                          </>
                        )}

                        {/* Modal de Histórico de Crédito */}
                        <Dialog open={openHistoricoDialog} onClose={() => setOpenHistoricoDialog(false)} maxWidth="sm" fullWidth>
                          {/* Cabeçalho do Modal */}
                          <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", bgcolor: "#1976d2", color: "#fff" }}>
                            <Typography variant="h6">Histórico de Uso do Crédito</Typography>
                            <IconButton onClick={() => setOpenHistoricoDialog(false)} sx={{ color: "#fff" }}>
                              <CloseIcon />
                            </IconButton>
                          </DialogTitle>

                          <DialogContent dividers sx={{ bgcolor: "#f9f9f9" }}>
                            {historicoSelecionado.length > 0 ? (
                              <>
                                {/* Busca o Valor Original do Documento (Se Existir) */}
                                <Box sx={{ p: 2, bgcolor: "#e3f2fd", borderRadius: 2, textAlign: "center", mb: 2 }}>
                                  <Typography variant="subtitle1" fontWeight="bold"></Typography>
                                  <Typography variant="h5" color="primary" fontWeight="bold">
                                    {historicoSelecionado[0]?.valorOriginal ? historicoSelecionado[0].valorOriginal.toFixed(2) : ""}
                                  </Typography>
                                </Box>

                                {/* Lista de Histórico de Créditos */}
                                <List>
                                  {historicoSelecionado.map((registro, index) => (
                                    <ListItem
                                      key={index}
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        borderBottom: "1px solid #ddd",
                                        py: 2,
                                        bgcolor: index % 2 === 0 ? "#fff" : "#f1f1f1",
                                        borderRadius: 1,
                                      }}
                                    >
                                      {/* Ícone de crédito */}
                                      <ListItemAvatar>
                                        <Avatar sx={{ bgcolor: "primary.main", color: "#fff" }}>
                                          <AttachMoneyIcon />
                                        </Avatar>
                                      </ListItemAvatar>

                                      {/* Informações do registro */}
                                      <ListItemText
                                        primary={
                                          <Typography variant="subtitle1" fontWeight="bold">
                                            Crédito Utilizado: <span style={{ color: "red" }}>- R$ {registro.valor.toFixed(2)}</span>
                                          </Typography>
                                        }
                                        secondary={
                                          <Typography variant="body2" sx={{ color: "gray" }}>
                                            Data: {new Date(registro.data).toLocaleString()}
                                          </Typography>
                                        }
                                      />

                                      {/* Botão para Reimprimir Recibo */}
                                      
                                    </ListItem>
                                  ))}
                                </List>
                              </>
                            ) : (
                              <Typography variant="body2" sx={{ textAlign: "center", color: "gray", my: 2 }}>
                                Nenhum histórico encontrado.
                              </Typography>
                            )}
                          </DialogContent>

                          {/* Rodapé do Modal */}
                          <DialogActions sx={{ bgcolor: "#f1f1f1", padding: "10px" }}>
                            <Button onClick={() => setOpenHistoricoDialog(false)} variant="contained" color="primary">
                              Fechar
                            </Button>
                          </DialogActions>
                        </Dialog>


                      </>
                  
          <Modal open={openPagamentoModal} onClose={resetarEstadoPagamento}>
            <Box sx={EstiloModal}>
              {etapaAtual === "pagamento" && (
                <>
                  <Typography
                    id="modal-pagamento-titulo"
                    variant="h6"
                    component="h2"
                    sx={{
                      mb: 2,
                      color: "primary.main",
                      fontWeight: "bold",
                      fontFamily: "Poppins, sans-serif"
                    }}
                  >
                    Pagamento do Fornecedor
                  </Typography>
                  <Box sx={{ mb: 2 }}>
                    {selecionados.map(id => (
                      <Typography
                        key={id}
                        sx={{
                          wordBreak: "break-all",
                          fontFamily: "Poppins, sans-serif"
                        }}
                      >
                        Documento: {id}
                      </Typography>
                    ))}
                  </Box>
                  <TextField
                    label="Forma de Pagamento"
                    fullWidth
                    select
                    value={formaPagamento}
                    onChange={e => setFormaPagamento(e.target.value)}
                    SelectProps={{ native: true }}
                    sx={{
                      mb: 2,
                      backgroundColor: "#fff",
                      borderRadius: "8px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      fontFamily: "Poppins, sans-serif"
                    }}
                  >
                    <option value=" "> </option>
                    <option value="pix">Pix</option>
                    <option value="transferencia">
                      Transferência Bancária
                    </option>
                    <option value="cheque">Cheque</option>
                    <option value="dinheiro">Dinheiro</option>
                  
                    
                  </TextField>

                  <TextField
                    label="Observação"
                    fullWidth
                    multiline
                    rows={4}
                    value={observacao}
                    onChange={e => setObservacao(e.target.value)}
                    sx={{
                      mb: 2,
                      backgroundColor: "#fff",
                      borderRadius: "8px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      fontFamily: "Poppins, sans-serif"
                    }}
                  />
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <input
                      accept="image/*,.pdf"
                      style={{ display: "none" }}
                      id="raised-button-file"
                      multiple
                      type="file"
                      onChange={e => setArquivoAnexo(e.target.files[0])}
                    />
                    <label htmlFor="raised-button-file">
                      <IconButton
                        component="span"
                        sx={{ color: "secondary.main" }}
                      >
                        <AttachFileIcon />
                      </IconButton>
                    </label>
                    <Typography
                      sx={{ ml: 1, fontFamily: "Poppins, sans-serif" }}
                    >
                      {arquivoAnexo
                        ? arquivoAnexo.name
                        : "Anexar Recibo Bancário"}
                    </Typography>
                  </Box>

                  <Button
                    variant="contained"
                    color="success"
                    startIcon={<PaymentIcon />}
                    onClick={realizarPagamento}
                    sx={{
                      width: "100%",
                      mt: 2,
                      borderRadius: "8px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      fontFamily: "Poppins, sans-serif"
                    }}
                  >
                    Pagar
                  </Button>
                </>
              )}
              {etapaAtual === "opcoesRecibo" && (
                <>
                  <Typography
                    variant="h6"
                    sx={{
                      mb: 2,
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      fontWeight: "bold",
                      fontFamily: "Poppins, sans-serif"
                    }}
                  >
                    <ReceiptIcon /> Ações do Recibo
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      mb: 1,
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      borderRadius: "8px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      fontFamily: "Poppins, sans-serif"
                    }}
                    startIcon={<EmailIcon />}
                    onClick={async () => {
                      await handleGerarReciboClick(); // Gera o PDF e envia por email
                      resetarEstadoPagamento(); // Reseta o estado após enviar
                    }}
                  >
                    Enviar Recibo por Email
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      borderRadius: "8px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      fontFamily: "Poppins, sans-serif"
                    }}
                    startIcon={<PictureAsPdfIcon />}
                    onClick={() => {
                      gerarReciboPDF();
                      resetarEstadoPagamento();
                    }}
                    
                  >
                    Gerar Recibo em PDF
                  </Button>

                  {mostrarRecibo && <Recibo {...dadosRecibo} />}
                </>
              )}
            </Box>
          </Modal>
          <Modal
            open={mostrarModalEscolhaFormato}
            onClose={() => setMostrarModalEscolhaFormato(false)}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                borderRadius: 2,
                boxShadow: "24px 24px 48px -12px rgba(0,0,0,0.2)",
                p: 4,
                outline: "none"
              }}
            >
              <Typography
                id="modal-title"
                variant="h6"
                component="h2"
                sx={{ fontWeight: "bold", mb: 2, textAlign: "center" }}
              >
                Formato do Relatório
              </Typography>
              <Box
                sx={{ mt: 2, display: "flex", justifyContent: "space-evenly" }}
              >
                <Button
                  variant="outlined"
                  startIcon={<PictureAsPdfIcon />}
                  onClick={() => {
                    console.log("Botão PDF clicado!"); // Depuração
                    gerarRelatorioFornecedor("PDF"); // 🔴 Removido `vendasFiltradas`, pois não é necessário
                    setMostrarModalEscolhaFormato(false);
                  }}
                  sx={{
                    borderColor: "primary.main",
                    color: "primary.main",
                    "&:hover": {
                      backgroundColor: "primary.light",
                      borderColor: "primary.main"
                    },
                    borderRadius: "8px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    fontFamily: "Poppins, sans-serif"
                  }}
                >
                  PDF
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<InsertDriveFileIcon />}
                  onClick={() => {
                    gerarRelatorioFornecedor(vendasFiltradas, "CSV");
                    setMostrarModalEscolhaFormato(false);
                  }}
                  sx={{
                    borderColor: "secondary.main",
                    color: "secondary.main",
                    "&:hover": {
                      backgroundColor: "secondary.light",
                      borderColor: "secondary.main"
                    },
                    borderRadius: "8px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    fontFamily: "Poppins, sans-serif"
                  }}
                >
                  CSV
                </Button>
              </Box>
            </Box>
          </Modal>

          <Modal
            open={openModal}
            onClose={handleCloseDetalhesModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box sx={modalStyle}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Typography
                  id="modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                >
                  <AssignmentIcon color="primary" />
                  Detalhes da Venda
                </Typography>
                <IconButton
                  onClick={handleCloseDetalhesModal}
                  size="small"
                  sx={{ marginLeft: "auto" }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              {selectedVenda ? (
                <>
                  <Typography sx={{ mt: 2 }}>
                    <strong>Documento da Venda:</strong> {selectedVenda.idVenda}
                  </Typography>
                  <Typography
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  >
                    <CalendarTodayIcon color="action" />
                    <strong>Data da Venda:</strong>{" "}
                    {selectedVenda.dataVenda.toLocaleDateString()}
                  </Typography>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mb: 2
                    }}
                  >
                    <PaymentIcon color="success" />
                    <strong>Status do Pagamento:</strong>{" "}
                    {selectedVenda.statusPagamento}
                  </Typography>

                  <Table size="small">
                    <TableHead sx={{ backgroundColor: "#1976d2" }}>
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#fff",
                            fontWeight: "bold",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          Descrição
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: "#fff",
                            fontWeight: "bold",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          Quantidade
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: "#fff",
                            fontWeight: "bold",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          Valor Venda
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: "#fff",
                            fontWeight: "bold",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          Desconto
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: "#fff",
                            fontWeight: "bold",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          Preço Final
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: "#fff",
                            fontWeight: "bold",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          Comissão (%)
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: "#fff",
                            fontWeight: "bold",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          Valor de comissão
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedVenda.produtos.map((produto, index) => (
                        <TableRow key={index}>
                          <TableCell>{produto.descricao}</TableCell>
                          <TableCell align="right">{produto.quantidade}</TableCell>
                          <TableCell align="right">
                            {formatCurrency(formatarValor(produto.valorVenda))}
                          </TableCell>

                          <TableCell align="right">
                            {produto.descontoPercentual > 0
                              ? `${produto.descontoPercentual}%`
                              : produto.descontoMonetario > 0
                              ? `R$ ${produto.descontoMonetario}`
                              : "0"}
                          </TableCell>
                          <TableCell align="right">
                            {formatCurrency(formatarValor(produto.precoFinal))}
                          </TableCell>
                          <TableCell align="right">
                            {produto.porcentagemComissao
                              ? `${produto.porcentagemComissao}%`
                              : "N/A"}
                          </TableCell>
                          <TableCell align="right">
                            {formatCurrency(formatarValor(produto.repasseFornecedor))}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                </>
              ) : (
                <Typography>Nenhum detalhe disponível.</Typography>
              )}
            </Box>
          </Modal>
        </Card>
      </Container>
    </Box>
  );
};

export default FinanceiroFornecedores;
