import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase";
import { format } from "date-fns";

const formatCurrency = value =>
  new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  }).format(value);

const gerarRelatorioGeralFornecedores = async (fornecedores, empresaId) => {
  try {
    const doc = new jsPDF("p", "mm", "a4");
    doc.setFont("helvetica");

    // Título e Data
    doc.setFontSize(14).setFont(undefined, "bold");
    doc.text("RELATÓRIO GERAL DE PENDÊNCIAS", 105, 15, { align: "center" });

    const dataHoraAtual = format(new Date(), "dd/MM/yyyy HH:mm:ss");
    doc.setFontSize(10).setFont(undefined, "normal");
    doc.text(`Data da geração: ${dataHoraAtual}`, 20, 22);

    let currentY = 30;
    let totalGeral = 0;

    for (const fornecedor of fornecedores) {
      if (
        fornecedor.contasEmAberto === 0 &&
        fornecedor.contasParcialmentePagas === 0
      ) continue;

      const contasRef = collection(
        db,
        `EMPRESAS/${empresaId}/Cadastro de fornecedores/${fornecedor.id}/Conta`
      );
      const snapshot = await getDocs(contasRef);

      const contasPendentes = snapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(
          conta =>
            conta.statusPagamento === "Aguardando Pagamento" ||
            conta.statusPagamento === "Parcialmente Pago"
        );

      if (contasPendentes.length === 0) continue;

      if (currentY > 260) {
        doc.addPage();
        currentY = 20;
      }

      doc.setFontSize(12).setFont(undefined, "bold");
      doc.text(`Fornecedor: ${fornecedor.nome}`, 20, currentY);
      currentY += 6;

        for (const conta of contasPendentes) {
          const dataVenda = conta.dataVenda?.seconds
            ? new Date(conta.dataVenda.seconds * 1000)
            : new Date();
          const valor = parseFloat(conta.totalCaixaFornecedor || 0);
          totalGeral += valor;

          // 🔒 Quebra de página segura antes da tabela da venda
          if (currentY + 30 > 270) {
            doc.addPage();
            currentY = 20;
          }

          const vendaTable = doc.autoTable({
            startY: currentY,
            head: [["Documento", "Data da Venda", "Valor Devido", "Status"]],
            body: [[
              conta.idVenda || conta.id || "N/A",
              format(dataVenda, "dd/MM/yyyy"),
              formatCurrency(valor),
              conta.statusPagamento || "N/A"
            ]],
            theme: "grid",
            margin: { left: 20, right: 20 },
            styles: { fontSize: 10, cellPadding: 2 },
            columnStyles: {
              2: { halign: "right", cellWidth: 40 },
              3: { halign: "center", cellWidth: 35 }
            },
            headStyles: {
              fillColor: [25, 118, 210],
              textColor: [255, 255, 255],
              fontStyle: "bold"
            }
          });

          currentY = doc.lastAutoTable.finalY + 4;

          if (Array.isArray(conta.produtos) && conta.produtos.length > 0) {
            const produtosTabela = conta.produtos.map(prod => [
              prod.descricao || "Produto",
              prod.quantidade || "0",
              formatCurrency(parseFloat(prod.valorVenda) || 0),
              formatCurrency(parseFloat(prod.precoFinal) || 0)
            ]);

            // 🔒 Verifica novamente o espaço antes da tabela de produtos
            if (currentY + (produtosTabela.length * 6) > 270) {
              doc.addPage();
              currentY = 20;
            }

            const produtosTable = doc.autoTable({
              startY: currentY,
              head: [["Produto", "Qtd", "Valor Unitário", "Total"]],
              body: produtosTabela,
              theme: "striped",
              margin: { left: 30, right: 20 },
              styles: { fontSize: 9, cellPadding: 2 },
              columnStyles: {
                1: { halign: "center", cellWidth: 15 },
                2: { halign: "right", cellWidth: 30 },
                3: { halign: "right", cellWidth: 30 }
              },
              headStyles: {
                fillColor: [200, 200, 200],
                textColor: [0, 0, 0],
                fontStyle: "bold"
              }
            });

            currentY = doc.lastAutoTable.finalY + 8;
          }
        

      }
    }

    if (currentY > 270) {
      doc.addPage();
      currentY = 20;
    }

    doc.setFontSize(12).setFont(undefined, "bold");
    doc.text(`Total Geral a Pagar: ${formatCurrency(totalGeral)}`, 20, currentY);

    const nomeArquivo = `Relatorio_Geral_Pendencias_${format(new Date(), "dd-MM-yyyy")}.pdf`;
    doc.save(nomeArquivo);
  } catch (error) {
    console.error("Erro ao gerar relatório geral:", error);
    alert("Erro ao gerar relatório. Verifique o console.");
  }
};

export default gerarRelatorioGeralFornecedores;
