import React, { Component } from "react";
import JsBarcode from "jsbarcode";
import QRCode from "qrcode";

  class ImpressaoEtiqueta extends Component {
    componentDidMount() {
      this.gerarEtiqueta();
    }

    gerarEtiqueta = async () => {
      const { codigo, descricao, tamanho, marca, valorVenda, quantidade } = this.props;
      console.log(`Gerando etiquetas para o código: ${codigo}`);

      let etiquetasHTML = "";

      for (let i = 0; i < quantidade; i++) {
        let canvas = document.createElement("canvas");

        // Gerando código de barras
        JsBarcode(canvas, codigo, {
          format: "CODE128", // 🔥 Mudança para CODE128 (mais eficiente)
          width: 3,
          height: 50,
          displayValue: false,
        });

        let codigoBarras = canvas.toDataURL("image/png");

        // Gerando QR Code
        const qrCode = await QRCode.toDataURL(codigo, { width: 50, height: 50 });

        // Criando a etiqueta no formato correto
        etiquetasHTML += `
            <div style="
                width: 5cm;
                height: 7.5cm;
                display: flex;
                flex-direction: column;
                align-items: center;
                font-family: Arial, sans-serif;
                font-size: 12px; /* 🔥 Fonte maior */
                padding: 0.13cm;
                box-sizing: border-box;
                page-break-after: always;
            ">
                <!-- Parte superior da etiqueta -->
                <div style="
                    width: 100%;
                    height: 5cm;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    justify-content: space-around;
                ">
                    <p style="font-weight: bold; font-size: 12px; margin: 2px;">Descrição: ${descricao}</p>
                    <p style="margin: 2px 0; font-size: 12px;">Marca: ${marca}</p>
                    <p style="margin: 2px 0; font-size: 12px;">Tamanho: ${tamanho}</p>

                    <img src="${codigoBarras}" alt="Código de Barras" style="width: 100px; height: 50px; margin: 5px 0;" />
                    <img src="${qrCode}" alt="QR Code" style="width: 50px; height: 50px;" />
                </div>

                <!-- Linha tracejada para indicar a separação -->
                <div style="
                    width: 100%;
                    border-top: 2px dashed black;
                    margin: 3px 0;
                "></div>

                <!-- Parte destacável -->
                <div style="
                    width: 100%;
                    height: 2.5cm;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 15px; /* 🔥 Preço destacado */
                    font-weight: bold;
                ">
                    R$ ${valorVenda.toFixed(2).replace(".", ",")}
                </div>
            </div>
        `;
      }

      // Estilos para a impressão
      let style = `
          <style>
              @page {
                  size: 5cm 7.5cm;
                  margin: 0;
              }
              body {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  margin: 0;
                  padding: 0;
              }
          </style>
      `;

      // Abrir a nova janela para impressão
      const janelaImpressao = window.open("", "_blank");
      if (janelaImpressao) {
        janelaImpressao.document.write("<html><head>" + style + "</head><body>" + etiquetasHTML + "</body></html>");
        janelaImpressao.document.close();

        setTimeout(() => {
          janelaImpressao.focus();
          janelaImpressao.print();
          janelaImpressao.close();
          console.log(`Etiqueta impressa para o código: ${codigo}`);
        }, 500);
      } else {
        console.error("A janela de impressão não foi criada. Popup bloqueado?");
      }
    };


  render() {
    return null; // Este componente não renderiza nada visualmente
  }
}

export default ImpressaoEtiqueta;
