import { useContext, useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
} from "firebase/firestore";
import { db } from "./firebase";
import {
  TextField, Button, Box, useMediaQuery, Container, Typography,
  Grid, Paper, CircularProgress, Alert, Snackbar, Dialog,
  DialogActions, DialogContent, DialogTitle, List, ListItem,
  ListItemText, InputLabel
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
const CadastrodeClientes = () => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const { userDetails } = useContext(AuthContext);
  const empresaId = userDetails.empresaId;

  const [cliente, setCliente] = useState({
    id: "",
    nome: "",
    contato: "",
    cpfCnpj: "",
    email: "",
    credito: "",
    cep: "",
    rua: "",
    numero: "",
    complemento: "",
    bairro: "",
    cidade: "",
    estado: "",
    foto: null,
    fotoURL: "", // usado para exibição
  });


  const [senha, setSenha] = useState("");
  const [clientesEncontrados, setClientesEncontrados] = useState([]);
  const [historicoCompras, setHistoricoCompras] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedValue = name === "nome" || name === "contato" ? value.toUpperCase() : value;
    setCliente((prev) => ({ ...prev, [name]: updatedValue }));
  };

  const buscarCep = async (cep) => {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      const data = await response.json();
      if (!data.erro) {
        setCliente((prev) => ({
          ...prev,
          rua: data.logradouro || "",
          bairro: data.bairro || "",
          cidade: data.localidade || "",
          estado: data.uf || ""
        }));
      }
    } catch (err) {
      console.error("Erro ao buscar CEP:", err);
    }
  };
  const uploadImagemCliente = async (file, clienteId) => {
    const storage = getStorage();
    const storageRef = ref(storage, `clientes/${clienteId}/foto.jpg`);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;
  };
  const handleChangeCPF = (e) => {
    const valor = e.target.value.replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
    setCliente((prev) => ({ ...prev, cpfCnpj: valor }));
  };

  const handleChangeTelefone = (e) => {
    let valor = e.target.value.replace(/\D/g, "");
    if (valor.length > 11) valor = valor.substr(0, 11);
    valor = "+55 " + valor.replace(/(\d{2})(\d)/, "($1) $2").replace(/(\d{5})(\d)/, "$1-$2");
    setCliente((prev) => ({ ...prev, contato: valor }));
  };

  const formatarComoMoeda = (valor) => {
    const numero = Number(valor.replace(/\D/g, "")) / 100;
    return numero.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
  };

  const handleChangeCredito = (e) => {
    const valorNumerico = e.target.value.replace(/[^\d]/g, "");
    setCliente((prev) => ({ ...prev, credito: valorNumerico }));
  };

  const handleBlurCredito = () => {
    setCliente((prev) => ({
      ...prev,
      credito: formatarComoMoeda(prev.credito || "0"),
    }));
  };

  const debounce = (fn, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => fn(...args), delay);
    };
  };

  useEffect(() => {
    if (cliente.nome.trim().length > 0) {
      const busca = async () => {
        setLoading(true);
        try {
          const startAtNome = cliente.nome.toUpperCase();
          const endAtNome = startAtNome + "\uf8ff";
          const q = query(
            collection(db, "EMPRESAS", empresaId, "Clientes"),
            where("nome", ">=", startAtNome),
            where("nome", "<=", endAtNome)
          );
          const querySnapshot = await getDocs(q);
          const resultados = [];
          querySnapshot.forEach(doc => resultados.push({ id: doc.id, ...doc.data() }));
          setClientesEncontrados(resultados);
        } catch (error) {
          setError("Erro ao buscar clientes: " + error.message);
        } finally {
          setLoading(false);
        }
      };
      debounce(busca, 500)();
    } else {
      setClientesEncontrados([]);
    }
  }, [cliente.nome]);

  const selecionarParaEdicao = async (clienteSelecionado) => {
    setCliente(clienteSelecionado);
    setIsEditing(true);
    try {
      const vendasRef = collection(db, "EMPRESAS", empresaId, "Vendas");
      const q = query(vendasRef, where("clienteId", "==", clienteSelecionado.id));
      const snapshot = await getDocs(q);
      const vendas = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setHistoricoCompras(vendas);
    } catch (error) {
      console.error("Erro ao buscar histórico:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!cliente.nome || !cliente.cpfCnpj || !cliente.email) {
      setError("Por favor, preencha todos os campos obrigatórios.");
      return;
    }

    setLoading(true);
    try {
      let clienteRef;
      if (isEditing && cliente.id) {
        clienteRef = doc(db, "EMPRESAS", empresaId, "Clientes", cliente.id);
      } else {
        const newId = cliente.nome.trim().replace(/\s+/g, "-").toLowerCase();
        clienteRef = doc(db, "EMPRESAS", empresaId, "Clientes", newId);
        cliente.id = newId;
      }
      await setDoc(clienteRef, cliente, { merge: true });
      setSnackbarMessage(isEditing ? "Cliente atualizado!" : "Cliente cadastrado!");
      setOpenSnackbar(true);
      limparFormulario();
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const limparFormulario = () => {
    setCliente({
      id: "",
      nome: "",
      contato: "",
      cpfCnpj: "",
      email: "",
      credito: "",
      cep: "",
      rua: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      estado: "",
      foto: null,
      fotoURL: "",
    });
    setHistoricoCompras([]);
    setIsEditing(false);
    setError("");
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCliente((prev) => ({ ...prev, foto: file }));
    }
  };

  return (
        <Container
          maxWidth="xl"
          sx={{
            mt: 3,
            ml: isMdUp ? "220px" : 0,  // margem lateral ao invés de sobrescrever largura
            pr: 2,
            pl: 2,
          }}
        >

      <Paper elevation={6} sx={{ p: 4 }}>
        <Typography variant="h5" align="center" gutterBottom>
          Cadastro de Clientes
        </Typography>

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
          {error && <Alert severity="error">{error}</Alert>}

          <Grid container spacing={2}>
            {/* COLUNA ESQUERDA - FOTO */}
            <Grid item xs={12} md={3}>
              <Paper
                elevation={3}
                sx={{
                  width: "80%",
                  maxWidth: 200,
                  height: "auto",
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  mx: "auto",
                }}
              >
                {cliente.fotoURL ? (
                  <img
                    src={cliente.fotoURL}
                    alt="Foto do Cliente"
                    style={{ width: "100%", maxHeight: 200, objectFit: "cover", borderRadius: 8 }}
                  />
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      height: 200,
                      border: "1px dashed grey",
                      borderRadius: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "gray",
                      fontSize: 14,
                      textAlign: "center",
                    }}
                  >
                    Foto do cliente
                  </Box>
                )}
              </Paper>

            </Grid>

            {/* COLUNA DIREITA - FORMULÁRIO */}
            <Grid item xs={12} md={9}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField required fullWidth label="Nome" name="nome" value={cliente.nome} onChange={handleChange} />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Contato" name="contato" value={cliente.contato} onChange={handleChangeTelefone} />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="CPF/CNPJ" name="cpfCnpj" value={cliente.cpfCnpj} onChange={handleChangeCPF} />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField required fullWidth type="email" label="E-mail" name="email" value={cliente.email} onChange={handleChange} />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField label="CEP" fullWidth name="cep" value={cliente.cep} onChange={handleChange} onBlur={() => buscarCep(cliente.cep)} />
                </Grid>

                <Grid item xs={12} sm={8}>
                  <TextField label="Rua" fullWidth name="rua" value={cliente.rua} onChange={handleChange} />
                </Grid>

                <Grid item xs={6} sm={3}>
                  <TextField label="Número" fullWidth name="numero" value={cliente.numero} onChange={handleChange} />
                </Grid>

                <Grid item xs={6} sm={3}>
                  <TextField label="Complemento" fullWidth name="complemento" value={cliente.complemento} onChange={handleChange} />
                </Grid>

                <Grid item xs={6} sm={3}>
                  <TextField label="Bairro" fullWidth name="bairro" value={cliente.bairro} onChange={handleChange} />
                </Grid>

                <Grid item xs={6} sm={3}>
                  <TextField label="Cidade" fullWidth name="cidade" value={cliente.cidade} onChange={handleChange} />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <TextField label="Estado" fullWidth name="estado" value={cliente.estado} onChange={handleChange} />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Crédito (R$)"
                    name="credito"
                    value={cliente.credito}
                    onChange={handleChangeCredito}
                    onBlur={handleBlurCredito}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <InputLabel>Foto do Cliente</InputLabel>
                  <input type="file" accept="image/*" onChange={handleFileChange} />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant={isEditing ? "outlined" : "contained"}
                    disabled={loading}
                  >
                    {loading ? <CircularProgress size={24} /> : isEditing ? "Editar Cliente" : "Cadastrar Cliente"}
                  </Button>

                  <Button fullWidth variant="text" sx={{ mt: 1 }} onClick={limparFormulario}>
                    Limpar Formulário
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        {/* HISTÓRICO DE COMPRAS */}
        {historicoCompras.length > 0 && (
          <Box mt={5}>
            <Typography variant="h6" gutterBottom>
              Histórico de Compras
            </Typography>
            <List>
              {historicoCompras.map((venda, idx) => (
                <ListItem key={idx}>
                  <ListItemText
                    primary={`Venda: ${venda.id}`}
                    secondary={`Data: ${venda.data || "Não informado"}`}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        {/* CONSULTA DE MELHOR ENVIO */}
        <Box mt={5}>
          <Typography variant="h6" gutterBottom>
            Consulta de Frete (Melhor Envio)
          </Typography>
          <Alert severity="info">
            Esta área está preparada para integração com a API do Melhor Envio. Você poderá calcular o frete automaticamente usando os dados do cliente.
          </Alert>
        </Box>

        {/* SNACKBAR */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={5000}
          onClose={() => setOpenSnackbar(false)}
          message={snackbarMessage}
        />
      </Paper>
    </Container>
  );

};

export default CadastrodeClientes;
