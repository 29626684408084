import React, { useEffect, useState } from "react";
import { AuthProvider } from "./AuthContext";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams
} from "react-router-dom";
import LoginPage from "./LoginPage";
import DashboardPage from "./Dashboard";
import Cadastrodefornecedor from "./Cadastrodefornecedor";
import Cadastrodepecas from "./Cadastrodepecas";
import Gerenciarprodutos from "./Gerenciarprodutos";
import Orcamentos from "./Orcamentos";
import Etiquetas from "./Etiquetas";
import Relatorio from "./Relatorio";
import Caixa from "./Caixa";
import CadastrodeClientes from "./CadastrodeClientes";
import Extrato from "./Historicodepagamentos";
import Paginaorcamento from "./Paginaorcamento";
import Vender from "./Vender";
import Listadeorcamentos from "./Listadeorcamentos";
import Pagamentofornecedores from "./Pagamentofornecedores";
import Layout from "./Layout";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import AprovacaoOrcamento from './AprovacaoOrcamento';
import { doc, getDoc, getFirestore } from "firebase/firestore";


function EmpresaLoginPage() {
  const { empresaId } = useParams();
  const [empresaData, setEmpresaData] = useState(null);
  const [error, setError] = useState(null);
  const db = getFirestore();


  useEffect(() => {
    let isMounted = true;
  
    async function fetchEmpresaData() {
      try {
        const docRef = doc(db, "EMPRESAS", empresaId);
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists() && isMounted) {
          setEmpresaData(docSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        if (isMounted) {
          setError("Erro ao buscar dados da empresa.");
        }
      }
    }
  
    fetchEmpresaData(); // ✅ Agora está dentro do useEffect corretamente
  
    return () => {
      isMounted = false;
    };
  }, [empresaId, db]); // ✅ db foi adicionado para evitar warnings
  
  

  if (error) {
    return <div>{error}</div>;
  }

  return <LoginPage empresaData={empresaData} />;
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/:empresaId/login" element={<EmpresaLoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          {isAuthenticated ? (
            <Route element={<Layout />}>
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/cadastro-fornecedor" element={<Cadastrodefornecedor />} />
              <Route path="/cadastro-roupas" element={<Cadastrodepecas />} />
              <Route path="/gerenciar-produtos" element={<Gerenciarprodutos />} />
              <Route path="/cadastro-clientes" element={<CadastrodeClientes />} />
              <Route path="/vender" element={<Vender />} />
              <Route path="/orcamentos" element={<Orcamentos />} />
              <Route path="/relatorio" element={<Relatorio />} />
              <Route path="/orcamento/:empresaId/:idOrcamento" element={<Paginaorcamento />} />
              <Route path="/etiquetas" element={<Etiquetas />} />
              <Route path="/historicodepagamentos" element={<Extrato />} />
              <Route path="/caixa" element={<Caixa />} />
              <Route path="/lista-orcamentos" element={<Listadeorcamentos />} />
              <Route path="/pagamento-fornecedores" element={<Pagamentofornecedores />} />
              <Route path="/aprovacao-orcamento/:orcamentoId" component={AprovacaoOrcamento} />
            </Route>
          ) : (
            <Route path="*" element={<Navigate to="/login" />} />
          )}
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
