import React, { useState, useEffect } from "react";
import { db } from "./firebase";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  writeBatch
} from "firebase/firestore";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PrintIcon from "@mui/icons-material/Print";
import EmailIcon from "@mui/icons-material/Email";
import ReciboCreditoLoja from "./ReciboCreditoLoja";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { CircularProgress, Snackbar, Alert } from "@mui/material";

const ModalCreditoLoja = ({ open, onClose, fornecedorId, documentosSelecionados, empresaId }) => {
  const [valorCredito, setValorCredito] = useState(0);
  const [totalDisponivel, setTotalDisponivel] = useState(0);
  const [fornecedorNome, setFornecedorNome] = useState("");
  const [loading, setLoading] = useState(false);
  const [empresaInfo, setEmpresaInfo] = useState(null);
  const [showReciboDialog, setShowReciboDialog] = useState(false);
  const [reciboData, setReciboData] = useState(null);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // "success" ou "error"
  useEffect(() => {
    if (!open || !fornecedorId) return;

    const fetchFornecedor = async () => {
      const fornecedorRef = doc(db, `EMPRESAS/${empresaId}/Cadastro de fornecedores/${fornecedorId}`);
      const fornecedorSnap = await getDoc(fornecedorRef);
      if (fornecedorSnap.exists()) {
        setFornecedorNome(fornecedorSnap.data().nome || "Fornecedor Desconhecido");
      }
    };

    const fetchEmpresa = async () => {
      const empresaRef = doc(db, `EMPRESAS/${empresaId}`);
      const empresaSnap = await getDoc(empresaRef);
      if (empresaSnap.exists()) {
        setEmpresaInfo(empresaSnap.data());
      }
    };

    const total = documentosSelecionados.reduce(
      (acc, doc) => acc + (doc.totalCaixaFornecedor || 0),
      0
    );
    setTotalDisponivel(total);

    fetchFornecedor();
    fetchEmpresa();
  }, [open, documentosSelecionados, fornecedorId, empresaId]);

  const processarCreditoLoja = async () => {
      if (valorCredito <= 0 || isNaN(valorCredito)) {
        alert("Digite um valor válido para crédito.");
        return;
      }

      if (valorCredito > totalDisponivel) {
        alert("O valor solicitado excede o total disponível.");
        return;
      }

      setLoading(true);
      const batch = writeBatch(db);
      let valorRestante = valorCredito;

      for (const venda of documentosSelecionados) {
        if (valorRestante <= 0) break;

        const contaRef = doc(db, `EMPRESAS/${empresaId}/Cadastro de fornecedores/${fornecedorId}/Conta`, venda.id);
        const docSnap = await getDoc(contaRef);

        if (docSnap.exists()) {
          const vendaData = docSnap.data();
          const totalCaixa = parseFloat(vendaData.totalCaixaFornecedor) || 0;
          const valorUsado = Math.min(valorRestante, totalCaixa);
          valorRestante -= valorUsado;

          const valorOriginalFornecedor = vendaData.valorOriginalFornecedor ?? totalCaixa;

          const registroCredito = {
            data: new Date().toISOString(),
            valor: valorUsado,
            descricao: "Transferência para Crédito em Loja",
          };

          // 🔹 Atualiza os dados da venda
          const novaVendaData = {
            ...vendaData,
            totalCaixaFornecedor: totalCaixa - valorUsado,
            statusPagamento: totalCaixa - valorUsado === 0 ? "PAGO" : "Parcialmente Pago",
            historicoCredito: [...(vendaData.historicoCredito || []), registroCredito],
            valorOriginalFornecedor: valorOriginalFornecedor, 
            metodoPagamento: "Crédito em Loja", // Adiciona o método de pagamento
            dataPagamento: new Date().toISOString() // Adiciona a data do pagamento
          };

          if (totalCaixa - valorUsado === 0) {
            // 🔹 Mover documento para `Pagamentos` em vez de deletá-lo
            const pagamentoRef = doc(db, `EMPRESAS/${empresaId}/Cadastro de fornecedores/${fornecedorId}/Pagamentos`, venda.id);
            batch.set(pagamentoRef, novaVendaData);
            batch.delete(contaRef); // 🔹 Agora só deletamos depois de mover
          } else {
            // 🔹 Atualiza a conta normalmente se ainda houver saldo
            batch.update(contaRef, novaVendaData);
          }

          // 🔹 Criar um novo documento em `CréditosUtilizados`
          const creditoRef = collection(db, `EMPRESAS/${empresaId}/Cadastro de fornecedores/${fornecedorId}/CréditosUtilizados`);
          const novoCreditoDoc = doc(creditoRef);
          batch.set(novoCreditoDoc, {
            data: new Date().toISOString(),
            valor: valorUsado,
            descricao: "Uso de crédito em loja",
            documentoOriginal: venda.id
          });
        }
      }

      // 🔹 Atualiza o saldo de crédito do fornecedor
      const fornecedorRef = doc(db, `EMPRESAS/${empresaId}/Cadastro de fornecedores/${fornecedorId}`);
      const fornecedorSnap = await getDoc(fornecedorRef);

      if (fornecedorSnap.exists()) {
        const fornecedorData = fornecedorSnap.data();
        const creditoAtual = parseFloat(fornecedorData.creditoEmLoja) || 0;
        batch.update(fornecedorRef, { creditoEmLoja: creditoAtual + valorCredito });
      }

      await batch.commit();
      setLoading(false);

      // 🔹 Exibir recibo
      setReciboData({
        fornecedorNome,
        empresaInfo,
        valorTransferido: valorCredito,
        historicoCredito: [{ data: new Date().toISOString(), valor: valorCredito }]
      });

      setShowReciboDialog(true);
  };

  const imprimirRecibo = () => {
    if (!reciboData) {
      console.error("Erro: Nenhum dado disponível para gerar o recibo.");
      return;
    }

    // 🏷️ Formata o histórico de créditos utilizados corretamente
    const historicoFormatado = Array.isArray(reciboData.historicoCredito)
      ? reciboData.historicoCredito.map(
          (registro, index) =>
            `${(index + 1).toString().padEnd(4, " ")} ${new Date(registro.data).toLocaleDateString()}   R$ ${registro.valor.toFixed(2)}\n`
        ).join("")
      : "Nenhum histórico disponível\n";

    // 🔹 Estrutura do recibo em formato de cupom fiscal
    let conteudoRecibo = `
  ========================================
          *** RECIBO DE CRÉDITO ***
  ========================================

  Fornecedor: ${fornecedorNome}
  Empresa: ${empresaInfo?.nome || "EMPRESA DESCONHECIDA"}
  ----------------------------------------
  Data: ${new Date().toLocaleString()}
  Valor Transferido: R$ ${valorCredito.toFixed(2)}
  ----------------------------------------
  Histórico de Créditos Utilizados:
  No  Data        Valor
  ----------------------------------------
  ${historicoFormatado}
  ----------------------------------------
          Obrigado pela preferência!       
  ========================================
    `;

    // 🔹 Abre a janela para impressão no formato correto
    const win = window.open("", "", "width=300,height=600");
    if (!win) {
      alert("Permita pop-ups para imprimir o recibo.");
      return;
    }

    win.document.write(`<pre style="font-family: monospace; font-size: 12px;">${conteudoRecibo}</pre>`);
    win.document.close();
    win.focus();
    win.print();
    win.close();
  };



  const pdfBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(",")[1]);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };
  const [alertMessage, setAlertMessage] = useState("");
  const [alertModalOpen, setAlertModalOpen] = useState(false);

  const enviarEmailComPDF = async () => {
    setLoadingEmail(true); // 🔹 Inicia o loader
    setSnackbarOpen(false);

    if (!fornecedorId || !empresaId || !reciboData) {
      setSnackbarMessage("Erro: Informações insuficientes para envio.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setLoadingEmail(false);
      return;
    }

    const fornecedorRef = doc(db, "EMPRESAS", empresaId, "Cadastro de fornecedores", fornecedorId);
    const fornecedorDoc = await getDoc(fornecedorRef);

    if (!fornecedorDoc.exists()) {
      setSnackbarMessage("Erro: Fornecedor não encontrado.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setLoadingEmail(false);
      return;
    }

    const fornecedorData = fornecedorDoc.data();
    const fornecedorEmail = fornecedorData.email;

    if (!fornecedorEmail) {
      setSnackbarMessage("Erro: Fornecedor sem email cadastrado.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setLoadingEmail(false);
      return;
    }

    // 🔹 Criar o PDF do recibo
    const docPDF = new jsPDF();
    docPDF.setFont("helvetica");

    docPDF.setFontSize(18).setFont(undefined, "bold");
    docPDF.text("RECIBO DE CRÉDITO EM LOJA", 105, 15, { align: "center" });

    docPDF.setFontSize(12).setFont(undefined, "normal");
    docPDF.text(`Fornecedor: ${fornecedorNome}`, 20, 30);
    docPDF.text(`Empresa: ${empresaInfo?.nome || "Desconhecida"}`, 20, 40);
    docPDF.text(`Data: ${new Date().toLocaleDateString()}`, 20, 50);
    docPDF.text(`Valor Transferido: R$ ${valorCredito.toFixed(2)}`, 20, 60);

    docPDF.autoTable({
      startY: 70,
      head: [["Data", "Valor"]],
      body: reciboData.historicoCredito.map((reg) => [
        new Date(reg.data).toLocaleDateString(),
        `R$ ${reg.valor.toFixed(2)}`
      ]),
      theme: "striped",
      styles: { fontSize: 10 },
      headStyles: { fillColor: [44, 62, 80], textColor: [255, 255, 255], fontStyle: "bold" }
    });

    const pdfBlob = docPDF.output("blob");
    const attachmentBase64 = await pdfBlobToBase64(pdfBlob);

    // 🔹 Enviar email
    const mailData = {
      to: fornecedorEmail,
      subject: `Recibo de Crédito - ${fornecedorNome}`,
      text: "Segue em anexo o recibo de crédito.",
      attachment: attachmentBase64,
      filename: `Recibo_Credito_${fornecedorNome}.pdf`
    };

    try {
      const response = await fetch(
        "https://us-central1-dona-cora.cloudfunctions.net/sendEmailWithPDF",
        {
          method: "POST",
          body: JSON.stringify(mailData),
          headers: { "Content-Type": "application/json" }
        }
      );

      if (!response.ok) {
        throw new Error(await response.text());
      }

      setSnackbarMessage("Email enviado com sucesso!");
      setSnackbarSeverity("success");
    } catch (error) {
      console.error("Erro ao enviar email:", error);
      setSnackbarMessage("Erro ao enviar email: " + error.message);
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
      setLoadingEmail(false); // 🔹 Para o loader
    }
  };


  return (
    <>
      <Modal open={open} onClose={onClose} aria-labelledby="titulo-modal">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 3,
            width: 400,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
            <Typography variant="h6" id="titulo-modal" fontWeight="bold">
              Crédito em Loja - {fornecedorNome}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
            Valor disponível: <strong>R$ {totalDisponivel.toFixed(2)}</strong>
          </Typography>

          <TextField
            fullWidth
            label="Valor de Credito R$"
            type="number"
            value={valorCredito}
            onChange={(e) => setValorCredito(parseFloat(e.target.value) || 0)}
            sx={{ mb: 2, "& input": { fontWeight: "bold", fontSize: "1.1rem", textAlign: "center" } }}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={processarCreditoLoja}
            disabled={loading}
            fullWidth
            sx={{ borderRadius: "8px", fontSize: "1rem", fontWeight: "bold", height: 45 }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Confirmar Transferência"}
          </Button>
        </Box>
      </Modal>

      {/* Modal de Recibo Melhorado */}
      <Dialog 
        open={showReciboDialog} 
        onClose={() => setShowReciboDialog(false)} 
        maxWidth="sm" 
        fullWidth
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", bgcolor: "#1976d2", color: "#fff", fontWeight: "bold" }}>
          Recibo da Transferência - {fornecedorNome}
          <IconButton onClick={() => setShowReciboDialog(false)} sx={{ color: "#fff" }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers sx={{ padding: "20px", bgcolor: "#f9f9f9" }}>
          <Box sx={{ 
            bgcolor: "#fff", 
            p: 3, 
            borderRadius: "8px", 
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" 
          }}>
            <Typography variant="h6" sx={{ fontWeight: "bold", textAlign: "center", mb: 2 }}>
              Recibo de Crédito em Loja
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
              <Typography variant="body1"><strong>Fornecedor:</strong> {fornecedorNome}</Typography>
              <Typography variant="body1"><strong>Data:</strong> {new Date().toLocaleDateString()}</Typography>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
              <Typography variant="body1"><strong>Valor Transferido:</strong> R$ {valorCredito.toFixed(2)}</Typography>
              <Typography variant="body1"><strong>Empresa:</strong> {empresaInfo?.nome || "Empresa Desconhecida"}</Typography>
            </Box>

            <Box sx={{ p: 2, bgcolor: "#e3f2fd", borderRadius: "6px", mt: 2 }}>
              <Typography variant="body2"><strong>Histórico de Crédito:</strong></Typography>
              {reciboData?.historicoCredito?.map((registro, index) => (
                <Typography key={index} variant="body2" sx={{ mt: 1 }}>
                  - {new Date(registro.data).toLocaleDateString()} | R$ {registro.valor.toFixed(2)}
                </Typography>
              ))}
            </Box>
          </Box>
        </DialogContent>
        <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)}>
          <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <DialogActions sx={{ justifyContent: "space-between", padding: "15px 20px" }}>
          {/* Botão para Imprimir Recibo */}
          <Button 
            variant="contained" 
            color="primary" 
            startIcon={<PrintIcon />} 
            onClick={() => imprimirRecibo()} // 🔹 Chama função específica para imprimir só o recibo
            sx={{ borderRadius: "8px", fontSize: "0.9rem" }}
          >
            Imprimir Recibo
          </Button>

          {/* Botão para Enviar por Email */}
          <Button 
            variant="contained" 
            color="secondary" 
            startIcon={loadingEmail ? <CircularProgress size={20} color="inherit" /> : <EmailIcon />} 
            onClick={enviarEmailComPDF} 
            disabled={loadingEmail} // 🔹 Desabilita enquanto envia
            sx={{ borderRadius: "8px", fontSize: "0.9rem" }}
          >
            {loadingEmail ? "Enviando..." : "Enviar por Email"}
          </Button>
        </DialogActions>

      </Dialog>

    </>
  );
};

export default ModalCreditoLoja;
